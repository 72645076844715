import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { DownOutlined, LeftOutlined } from '@ant-design/icons';
import { Radio, Divider, Layout, Collapse, Select, Spin } from 'antd';
import { isEmpty, debounce } from 'lodash';
import Countdown from 'react-countdown';
import PreviewWindow from './previewWindow';
import NotificationBox from './notificationBox';
import { POSITIONS } from './notificationBox/notificationBox';
import ScheduleModal from './scheduleModal';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import SettingsText from 'Apps/VenueBuilder/Components/Common/SettingsText';
import SwitchComponent from 'Apps/VenueBuilder/Components/Common/SwitchComponent';
// import LivePagesList from 'Apps/VenueBuilder/Components/Common/LivePagesList';
import * as ls from 'local-storage';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import DraggableInputList from 'components/app/common/draggableInputList';
import {
    PositionBottomLeftIcon,
    PositionTopLeftIcon,
    PositionTopRightIcon,
    PositionBottomRightIcon,
    PositionTopCenteredIcon,
    PositionBottomCenteredIcon,
    PositionCenteredIcon,
} from 'Apps/VenueBuilder/Icons/PositionIcon';

import { getLivePages } from 'apis/rest/livestream/GetLivePages';
import { CreateNewPoll } from 'apis/rest/polls/CreateNewPoll';
import { UpdatePoll } from 'apis/rest/polls/UpdatePoll';
import { GetPoll } from 'apis/rest/polls/GetPoll';
import moment from 'moment';
import SendSettings from '../../helpers/SendSettings';
import { useSelector } from 'react-redux';

import './createPoll.scss';

const { Header, Sider, Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

function CreatePoll() {
    const { control, errors, setValue, trigger } = useForm();
    const { projectId, notificationId, companyId } = useParams();
    const location = useLocation();
    const history = useHistory();
    const isSponsor = !!ls.get('sponsorAuth');

    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const previewMode = location?.state?.previewMode || false;
    const sentTableType = location?.state?.sentTableType || false;

    const [question, setQuestion] = useState('Ask a question');
    const [options, setOptions] = useState([]);
    const [displayModePopup, setDisplayModePopup] = useState(true);

    const [ctaText, setctaText] = useState('Vote');
    const [ctaColor, setctaColor] = useState('#e2e9f3');
    const [ctaTextColor, setctaTextColor] = useState('#000000');
    const [position, setPosition] = useState(POSITIONS.BOTTOM_LEFT);
    const [jobRunTime, setJobRunTime] = useState('');

    const [showResultAfterVoting, setShowResultAfterVoting] = useState(true);
    const [livePagesList, setLivePagesList] = useState([]);
    const [livePagesSource, setLivePagesSource] = useState(
        isSponsor ? 'livepage' : 'project'
    );
    const [selectedLivePages, setSelectedLivePages] = useState([]);
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
    const [notificationSendNow, setNotificationSendNow] = useState(false);
    const [isLivePageSelectRequired, setLivePageSelectRequired] =
        useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorOption, setErrorOption] = useState('');

    const [sendMode, updateSendMode] = useState('sendNow');
    const [scheduleDate, setScheduleDate] = useState(
        moment().tz(timezone).add(5, 'minutes')
    );
    const [timeUnit, setTimeUnit] = useState('mins');

    const [triggerTime, setTriggerTime] = useState(2);

    const { triggers: pageTriggers } = useSelector((state) => state?.settings);

    const [selectedTrigger, updateTrigger] = useState(pageTriggers[0]);

    useEffect(() => {
        setScheduleDate(moment().tz(timezone).add(5, 'minutes'));
    }, [timezone, sendMode]);

    useEffect(() => {
        handleGetLivePages();
        if (notificationId) {
            handleGetNotification();
        } else {
            setIsLoading(false);
        }
    }, []);

    const handleGetNotification = async () => {
        try {
            const response = await GetPoll(projectId, notificationId);
            if (response.status) {
                const {
                    question,
                    showResultAfterVoting,
                    options,
                    ctaText,
                    ctaColor,
                    ctaTextColor,
                    position,
                    targetType,
                    target,
                    action,
                } = response.poll;
                setQuestion(question ? question : '');
                setShowResultAfterVoting(
                    showResultAfterVoting ? showResultAfterVoting : false
                );
                setOptions(options ? options?.map((value) => value.label) : []);
                setctaText(ctaText ? ctaText : '');
                setctaColor(ctaColor ? ctaColor : '#e2e9f3');
                setctaTextColor(ctaTextColor ? ctaTextColor : '#000000');
                setPosition(position ? position : POSITIONS.BOTTOM_LEFT);
                // setJobRunTime(response['job.runTime']);
                setLivePagesSource(targetType ? targetType : 'project');
                setSelectedLivePages(targetType === 'livepage' ? target : []);

                if (action?.trigger) {
                    updateSendMode('trigger');
                    if (action?.triggerConfig?.timeUnit) {
                        let triggerTime = action?.triggerConfig?.timeInSecs;
                        setTimeUnit(action.triggerConfig.timeUnit);

                        if (action.triggerConfig.timeUnit === 'mins')
                            triggerTime = triggerTime / 60;
                        setTriggerTime(triggerTime);
                    }

                    const triggerEle = pageTriggers.find(
                        ({ code }) => code === action?.trigger?.code
                    );
                    if (triggerEle) updateTrigger(triggerEle);
                }
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR handleGetNotification', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetLivePages = async () => {
        try {
            const { showcases } = await getLivePages(
                projectId,
                'title',
                'asc',
                20,
                0,
                '',
                [],
                '',
                1
            );
            if (!showcases || showcases.length === 0) throw 'failed';
            let showcaseList = [];
            showcases.map((item) => {
                showcaseList.push({
                    value: item.title,
                    title: item.title,
                    key: item.id,
                    slug: item.slug,
                });
            });
            setLivePagesList(showcaseList);
        } catch (error) {
            setLivePagesList([]);
        }
    };

    const handleCreateOrEditNotification = (notificationStatus) => {
        trigger().then((inputValidation) => {
            if (inputValidation) {
                if (
                    livePagesSource === 'livepage' &&
                    !selectedLivePages?.length
                ) {
                    setLivePageSelectRequired(true);
                    if (!options.length)
                        setErrorOption('Please add at-least two option.');
                    return;
                }
                if (options.length > 1) {
                    setErrorOption('');
                    if (options.includes('')) {
                        setErrorOption('There is an empty option');
                    }
                    if (!options.includes('')) {
                        if (notificationStatus === 'draft') {
                            //Edit Draft Notification
                            if (!isEmpty(notificationId))
                                handleUpdateNotification();

                            //Create Draft Notification
                            if (isEmpty(notificationId))
                                handleCreateNotification();
                        }
                    }
                } else {
                    setErrorOption('Please add at-least two option.');
                }
            }
        });
    };

    const handleCreateNotification = async () => {
        let inputs = {
            ...(livePagesSource === 'project' && {
                targetType: 'project',
            }),
            ...(livePagesSource === 'livepage' && {
                targetType: 'livepage',
                target: selectedLivePages.map((value) => value.key),
            }),
            question,
            options,
            position,
            ctaText,
            ctaColor,
            ctaTextColor,
            showResultAfterVoting,
            projectId,
        };
        if (sendMode === 'trigger') {
            let timeInSecs = null;

            inputs = {
                ...inputs,
                triggerCode: selectedTrigger.code,
                oncePerParticipant: true,
            };
            if (
                [
                    'LIVEPAGE_SPECIFIC_TIME',
                    'LIVEPAGE_USER_INACTIVE_TIME',
                ].includes(selectedTrigger.code)
            ) {
                timeInSecs =
                    timeUnit === 'secs' ? triggerTime : triggerTime * 60;
                inputs = {
                    ...inputs,
                    triggerConfig: { timeInSecs, timeUnit },
                };
            }
        }
        try {
            setIsLoading(true);
            const response = await CreateNewPoll(inputs);
            if (response.status) {
                openNotification('success', {
                    message: 'Poll created!',
                });
                history.push(
                    `/company/${companyId}/project/${projectId}/notification/polls/`
                );
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR CreateNotification', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateNotification = async () => {
        let inputs = {
            ...(livePagesSource === 'project' && {
                targetType: 'project',
            }),
            ...(livePagesSource === 'livepage' && {
                targetType: 'livepage',
                target: selectedLivePages.map((value) => value.key),
            }),
            question,
            options,
            position,
            ctaText,
            ctaColor,
            ctaTextColor,
            showResultAfterVoting,
        };
        if (sendMode === 'trigger') {
            let timeInSecs = null;

            inputs = {
                ...inputs,
                triggerCode: selectedTrigger.code,
                oncePerParticipant: true,
            };
            if (
                [
                    'LIVEPAGE_SPECIFIC_TIME',
                    'LIVEPAGE_USER_INACTIVE_TIME',
                ].includes(selectedTrigger.code)
            ) {
                timeInSecs =
                    timeUnit === 'secs' ? triggerTime : triggerTime * 60;
                inputs = {
                    ...inputs,
                    triggerConfig: { timeInSecs, timeUnit },
                };
            }
        }

        try {
            const response = await UpdatePoll(
                notificationId,
                inputs,
                projectId
            );
            if (response.status) {
                openNotification('success', {
                    message: 'Poll updated!',
                });
                setIsLoading(true);
                handleGetNotification();
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR UpdateNotification', error);
        }
    };

    const handleNotificationStatus = (notificationStatus) => {
        trigger().then((inputValidation) => {
            if (inputValidation) {
                if (
                    livePagesSource === 'livepage' &&
                    !selectedLivePages?.length
                ) {
                    setLivePageSelectRequired(true);
                    if (!options.length)
                        setErrorOption('Please add at-least two option.');
                    return;
                }
                if (options.length > 1) {
                    setErrorOption('');
                    if (options.includes('')) {
                        setErrorOption('There is an empty option');
                    }
                    if (!options.includes('')) {
                        setIsScheduleModalVisible(true);
                        if (notificationStatus === 'schedule') {
                            setNotificationSendNow(false);
                        } else {
                            setNotificationSendNow(true);
                        }
                    }
                } else {
                    setErrorOption('Please add at-least two option.');
                }
            }
        });
    };

    return (
        <Layout className={'VenueBuilder'}>
            <Header className="AppHeader p-2">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <button
                            className="btn"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/notification/polls`
                                )
                            }
                        >
                            <LeftOutlined />
                        </button>
                        <h1 className="mb-0">
                            {notificationId
                                ? previewMode
                                    ? 'View'
                                    : 'Edit'
                                : 'Create'}{' '}
                            poll
                        </h1>
                    </div>
                    <div
                        className={`d-flex align-items-center ${
                            previewMode ? 'flex-fill' : ''
                        }`}
                    >
                        {previewMode && jobRunTime && !sentTableType && (
                            <div
                                style={{ backgroundColor: '#F5F5F5' }}
                                className="d-flex justify-content-between align-items-center h-100 flex-fill px-3 mr-1 ml-3"
                            >
                                <Countdown
                                    date={jobRunTime}
                                    renderer={({
                                        days,
                                        hours,
                                        minutes,
                                        seconds,
                                    }) => {
                                        return (
                                            <span>{`Scheduled for ${days} Days ${hours} Hrs ${minutes} Mins ${seconds} Secs`}</span>
                                        );
                                    }}
                                />
                            </div>
                        )}
                        {!previewMode && (
                            <>
                                <button
                                    className="myxp btn btn-secondary"
                                    style={{ width: 'auto' }}
                                    onClick={() =>
                                        history.push(
                                            `/company/${companyId}/project/${projectId}/notification/polls`
                                        )
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() =>
                                        handleCreateOrEditNotification('draft')
                                    }
                                    className="myxp btn btn-primary"
                                >
                                    Save Draft
                                </button>
                                <button
                                    onClick={() =>
                                        handleNotificationStatus(sendMode)
                                    }
                                    className="myxp btn btn-sm btn-dark mx-2"
                                >
                                    {sendMode === 'schedule'
                                        ? 'Schedule'
                                        : sendMode === 'trigger'
                                        ? 'Set trigger'
                                        : 'Set live'}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </Header>

            <Layout>
                {isLoading && <GlobalLoading />}
                {!isLoading && (
                    <>
                        <Sider width={300} className={'AppSideBar'}>
                            <Collapse
                                expandIconPosition="right"
                                defaultActiveKey={['1', '2', '3', '4']}
                            >
                                <Panel
                                    header={<h2 className="mb-0">Content</h2>}
                                    key="1"
                                >
                                    <Controller
                                        as={
                                            <SettingsText
                                                headingTitle="Question"
                                                tooltipText="Poll Question"
                                                isVisible={!previewMode}
                                                error={errors?.question}
                                                setValue={(name, value) => {
                                                    setValue(name, value);
                                                    setQuestion(value);
                                                }}
                                                isRequired={true}
                                            />
                                        }
                                        name="question"
                                        control={control}
                                        defaultValue={question}
                                        rules={{ required: true }}
                                    />

                                    <span className="notification-input-label mr-1 mb-2">
                                        Options
                                        <span className="text-danger ml-2">
                                            *
                                        </span>
                                    </span>
                                    <DraggableInputList
                                        itemsData={options}
                                        setItemsData={(data) =>
                                            setOptions(data)
                                        }
                                        disabled={previewMode}
                                    />
                                    {!isEmpty(errorOption) && (
                                        <small class="text-danger mt-1">
                                            {errorOption}
                                        </small>
                                    )}
                                </Panel>
                                <Panel
                                    header={<h2 className="mb-0">Settings</h2>}
                                    key="2"
                                >
                                    <span className="notification-input-label mr-1 mb-1">
                                        CTA
                                    </span>
                                    <div className="border rounded px-3 mb-3">
                                        <div className="form-input-container">
                                            <Controller
                                                as={
                                                    <SettingsText
                                                        headingTitle="Text"
                                                        isVisible={!previewMode}
                                                        error={errors?.ctaText}
                                                        setValue={(
                                                            name,
                                                            value
                                                        ) => {
                                                            setValue(
                                                                name,
                                                                value
                                                            );
                                                            setctaText(value);
                                                        }}
                                                        isRequired={true}
                                                    />
                                                }
                                                name="ctaText"
                                                control={control}
                                                defaultValue={ctaText}
                                                rules={{
                                                    required:
                                                        showResultAfterVoting
                                                            ? true
                                                            : false,
                                                }}
                                            />
                                        </div>
                                        <div className="form-input-container mt-2">
                                            <span className="notification-input-label">
                                                Button Background Color
                                            </span>
                                            <div className="input-group mt-2">
                                                <input
                                                    type="color"
                                                    className="input--color"
                                                    value={ctaColor}
                                                    name="ctaColor"
                                                    onChange={(e) =>
                                                        setctaColor(
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={previewMode}
                                                />
                                                <input
                                                    type="text"
                                                    disabled
                                                    className="form-control input--color_content cta-color-input"
                                                    value={ctaColor}
                                                    name="ctaColor"
                                                    onChange={(e) =>
                                                        setctaColor(
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={previewMode}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-input-container mt-2 mb-3">
                                            <span className="notification-input-label">
                                                Button Text Color
                                            </span>
                                            <div className="input-group mt-2">
                                                <input
                                                    type="color"
                                                    className="input--color"
                                                    value={ctaTextColor}
                                                    name="ctaTextColor"
                                                    onChange={(e) =>
                                                        setctaTextColor(
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={previewMode}
                                                />
                                                <input
                                                    type="text"
                                                    disabled
                                                    className="form-control input--color_content cta-color-input"
                                                    value={ctaTextColor}
                                                    name="ctaTextColor"
                                                    onChange={(e) =>
                                                        setctaTextColor(
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={previewMode}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <SwitchComponent
                                        label="Show results after voting"
                                        disabled={previewMode}
                                        // tooltipText={'tooltipText'}
                                        switchStatus={showResultAfterVoting}
                                        onChange={(e) =>
                                            setShowResultAfterVoting(e)
                                        }
                                        padding={true}
                                        border={true}
                                        background={'#fff'}
                                    />
                                </Panel>
                                <Panel
                                    header={
                                        <h2 className="mb-0">Display mode</h2>
                                    }
                                    key="3"
                                >
                                    <Radio.Group
                                        className="d-flex flex-column"
                                        onChange={(e) =>
                                            setDisplayModePopup(e.target.value)
                                        }
                                        value={displayModePopup}
                                    >
                                        <div className="border p-3 mb-3">
                                            <Radio
                                                disabled={previewMode}
                                                value={true}
                                            >
                                                Display as a popup
                                            </Radio>
                                            {displayModePopup && (
                                                <>
                                                    <Divider className="my-3" />
                                                    <Radio.Group
                                                        disabled={previewMode}
                                                        className="d-flex justify-content-between"
                                                        onChange={(e) =>
                                                            setPosition(
                                                                e.target.value
                                                            )
                                                        }
                                                        defaultValue={position}
                                                    >
                                                        <Radio.Button
                                                            className="px-1 border-0 position-radio-button"
                                                            value={
                                                                POSITIONS.BOTTOM_LEFT
                                                            }
                                                        >
                                                            <PositionBottomLeftIcon
                                                                color={
                                                                    position ===
                                                                    POSITIONS.BOTTOM_LEFT
                                                                        ? '#0F67F9'
                                                                        : '#DADEEB'
                                                                }
                                                            />
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            className="px-1 border-0 position-radio-button"
                                                            value={
                                                                POSITIONS.TOP_LEFT
                                                            }
                                                        >
                                                            <PositionTopLeftIcon
                                                                color={
                                                                    position ===
                                                                    POSITIONS.TOP_LEFT
                                                                        ? '#0F67F9'
                                                                        : '#DADEEB'
                                                                }
                                                            />
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            className="px-1 border-0 position-radio-button"
                                                            value={
                                                                POSITIONS.TOP_RIGHT
                                                            }
                                                        >
                                                            <PositionTopRightIcon
                                                                color={
                                                                    position ===
                                                                    POSITIONS.TOP_RIGHT
                                                                        ? '#0F67F9'
                                                                        : '#DADEEB'
                                                                }
                                                            />
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            className="px-1 border-0 position-radio-button"
                                                            value={
                                                                POSITIONS.BOTTOM_RIGHT
                                                            }
                                                        >
                                                            <PositionBottomRightIcon
                                                                color={
                                                                    position ===
                                                                    POSITIONS.BOTTOM_RIGHT
                                                                        ? '#0F67F9'
                                                                        : '#DADEEB'
                                                                }
                                                            />
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            className="px-1 border-0 position-radio-button"
                                                            value={
                                                                POSITIONS.TOP_CENTERED
                                                            }
                                                        >
                                                            <PositionTopCenteredIcon
                                                                color={
                                                                    position ===
                                                                    POSITIONS.TOP_CENTERED
                                                                        ? '#0F67F9'
                                                                        : '#DADEEB'
                                                                }
                                                            />
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            className="px-1 border-0 position-radio-button"
                                                            value={
                                                                POSITIONS.BOTTOM_CENTERED
                                                            }
                                                        >
                                                            <PositionBottomCenteredIcon
                                                                color={
                                                                    position ===
                                                                    POSITIONS.BOTTOM_CENTERED
                                                                        ? '#0F67F9'
                                                                        : '#DADEEB'
                                                                }
                                                            />
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            className="px-1 border-0 position-radio-button"
                                                            value={
                                                                POSITIONS.CENTERED
                                                            }
                                                        >
                                                            <PositionCenteredIcon
                                                                color={
                                                                    position ===
                                                                    POSITIONS.CENTERED
                                                                        ? '#0F67F9'
                                                                        : '#DADEEB'
                                                                }
                                                            />
                                                        </Radio.Button>
                                                    </Radio.Group>
                                                </>
                                            )}
                                        </div>
                                        {/* <div className="border p-3">
                                            <Radio value={false}>Display as a block</Radio>
                                        </div> */}
                                    </Radio.Group>
                                    {/* <p className="text-muted mt-2">If you choose to display this poll as a block, make sure you have a Poll block on the livepage.</p> */}
                                </Panel>
                            </Collapse>
                        </Sider>
                        <Content className="AppContent m-4">
                            <PreviewWindow>
                                <NotificationBox
                                    question={question}
                                    options={options}
                                    position={position}
                                    buttonLabel={ctaText}
                                    buttonColor={ctaColor}
                                    buttonTextColor={ctaTextColor}
                                />
                            </PreviewWindow>
                        </Content>
                        <div className="tw-flex tw-flex-1 tw-w-[300px] tw-max-w-[300px]">
                            <SendSettings
                                {...{
                                    selectedLivePages,
                                    setSelectedLivePages,
                                    livePagesSource,
                                    setLivePagesSource,
                                    livePagesList,
                                    isSponsor,
                                    previewMode,
                                    sendMode,
                                    updateSendMode,
                                    scheduleDate,
                                    setScheduleDate,
                                    timezone,
                                    selectedTrigger,
                                    updateTrigger,
                                    triggerTime,
                                    setTriggerTime,
                                    timeUnit,
                                    setTimeUnit,
                                    isLivePageSelectRequired,
                                    setLivePageSelectRequired,
                                }}
                            />
                        </div>
                    </>
                )}
            </Layout>
            <ScheduleModal
                {...{
                    scheduleDate,
                    setScheduleDate,
                    sendMode,
                    selectedTrigger,
                    triggerTime,
                    timeUnit,
                    oncePerParticipant: true,
                }}
                notificationSendNow={notificationSendNow}
                visible={isScheduleModalVisible}
                setVisible={setIsScheduleModalVisible}
                onRefreshData={() =>
                    history.push(
                        `/company/${companyId}/project/${projectId}/notification/polls`
                    )
                }
                notificationEditMode={notificationId ? true : false}
                notificatonData={{
                    question,
                    options,
                    position,
                    ctaText,
                    ctaColor,
                    ctaTextColor,
                    showResultAfterVoting,
                    jobRunTime,
                    targetType: livePagesSource,
                    target: selectedLivePages,
                    id: notificationId,
                }}
            />
            <style global="false" jsx="true">{`
                .settingstext,
                .RichTextContainer {
                    padding: 10px 0px 16px;
                }
            `}</style>
        </Layout>
    );
}

CreatePoll.propTypes = {};

export default CreatePoll;
