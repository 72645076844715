import * as ls from 'local-storage';
import { store } from 'redux/store';
import { apiInterceptor } from 'utils/apiInterceptor';

export async function ImportUser(inputs, hasSponsorId) {
    const auth = ls.get('auth');
    const user = store?.getState()?.user;

    if (user) {
        const authBearer = `Bearer ${auth?.access_token}`;

        let formData = new FormData();

        for (const property in inputs) {
            formData.append(property, inputs[property]);
        }
        let endpoint = `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/exhibitors/import`;
        if (!hasSponsorId)
            endpoint = `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/exhibitors/import/all`;
        const { response } = await apiInterceptor(endpoint, {
            method: 'POST',
            headers: {
                Authorization: authBearer,
            },
            body: formData,
        });

        return await response.json();
    }
}
