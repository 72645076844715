import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Input, notification, DatePicker, TimePicker, List } from 'antd';
import { PublishNotification } from 'apis/rest/notifications/PublishNotification';
import { UpdateNotification } from 'apis/rest/notifications/UpdateNotification';
import { CreateNotification } from 'apis/rest/notifications/CreateNotification';
import isEmpty from 'lodash/isEmpty';
import sanitizeContent from 'utils/InputSanitization';
import { debounce } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import TimezoneSelector from 'components/app/common/timezoneSelector';

const ScheduleModalMobile = ({
    visible,
    onCancel,
    onConfirm,
    setVisible,
    notificationSendNow,
    notificatonData,
    onRefreshData,
    notificationEditMode,
    sendDirect = false,
    scheduleDate,
    setScheduleDate,
    sendMode,
    selectedTrigger,
    triggerTime,
    timeUnit,
}) => {
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const { projectId, companyId } = useParams();
    const history = useHistory();

    const handleCreateNotification = async () => {
        const {
            title,
            description,
            subTitle,
            image,
            position,
            autoCloseTime,
            targetType,
            targetId,
            ctaVisible,
            buttonLabel,
            buttonURL,
            buttonColor,
            buttonTextColor,
            openInNewWindow,
            senderVisible,
            autoCloseVisible,
            ctaId,
        } = notificatonData;

        const createInputs = {
            ...(targetType === 'project' && {
                targetType: 'project',
            }),
            ...(targetType === 'livepage' && {
                targetType: 'livepage',
                targetId: targetId.map((value) => value.key),
            }),
            data: {
                title,
                description: sanitizeContent({
                    str: description,
                    type: 'description',
                }),
                position,
                type: 'recommendation-session',
                ...(ctaVisible && {
                    cta: [
                        {
                            id: ctaId,
                            label: buttonLabel,
                            url: buttonURL,
                            color: buttonColor,
                            textColor: buttonTextColor,
                            openInNewWindow,
                        },
                    ],
                }),
                ...(senderVisible && {
                    subTitle,
                    image,
                }),
                ...(autoCloseVisible && {
                    autoCloseTime: Number(autoCloseTime),
                }),
            },
        };

        const response = await CreateNotification(createInputs, projectId);
        if (response.status) {
            return response.id;
        }
        if (!response.status) {
            openNotification('error', 'An error!', response.message);
        }
    };

    const handleSendNotification = debounce(async (notificationStatus) => {
        if (notificationStatus === 'sendNow') {
            //Create new notification
            if (!notificationEditMode) {
                const newNotificationId = await handleCreateNotification();

                const response = await PublishNotification(
                    projectId,
                    newNotificationId
                );
                if (response.status) {
                    openNotification(
                        'success',
                        'Successful!',
                        'Notification published!'
                    );
                    setVisible(false);
                    onRefreshData();
                }
                if (!response.status) {
                    openNotification('error', 'An error!', response.message);
                }
            }
            //Publish exist notification
            if (notificationEditMode) {
                const updateInputs = {
                    data: {
                        title: notificatonData?.title,
                        description: notificatonData?.description,
                        position: notificatonData?.position,
                        ...(notificatonData?.ctaVisible && {
                            cta: [
                                {
                                    id: notificatonData?.ctaId,
                                    label: notificatonData?.buttonLabel,
                                    url: notificatonData?.buttonURL,
                                    color: notificatonData?.buttonColor,
                                    textColor: notificatonData?.buttonTextColor,
                                    openInNewWindow:
                                        notificatonData?.openInNewWindow,
                                },
                            ],
                        }),
                        ...(!notificatonData?.ctaVisible && {
                            cta: [],
                        }),
                        ...((notificatonData?.senderVisible ||
                            (!notificatonData?.hasOwnProperty(
                                'senderVisible'
                            ) &&
                                (notificatonData?.subTitle ||
                                    notificatonData?.image))) && {
                            subTitle: notificatonData?.subTitle,
                            image: notificatonData?.image,
                        }),
                        ...(notificatonData?.hasOwnProperty('senderVisible') &&
                            !notificatonData?.senderVisible && {
                                subTitle: null,
                                image: null,
                            }),
                        ...(notificatonData?.autoCloseVisible && {
                            autoCloseTime: Number(
                                notificatonData?.autoCloseTime
                            ),
                        }),
                        ...(!notificatonData?.autoCloseVisible && {
                            autoCloseTime: null,
                        }),

                        ...(notificatonData?.targetType === 'project' && {
                            targetType: 'project',
                        }),
                        ...(notificatonData?.targetType === 'livepage' && {
                            targetType: 'livepage',
                            targetId: notificatonData?.targetId.map(
                                (value) => value.key
                            ),
                        }),
                    },
                };

                if (sendDirect) {
                    const response = await PublishNotification(
                        projectId,
                        notificatonData?.id || notificatonData?.notificationId
                    );
                    if (response.status) {
                        openNotification(
                            'success',
                            'Successful!',
                            'Notification Published!'
                        );
                        setVisible(false);
                        onRefreshData();
                    }
                    if (!response.status) {
                        openNotification(
                            'error',
                            'An error!',
                            response.message
                        );
                    }
                }

                if (!sendDirect) {
                    const response = await UpdateNotification(
                        updateInputs,
                        projectId,
                        notificatonData?.id || notificatonData?.notificationId
                    );
                    if (response.status) {
                        const publishResponse = await PublishNotification(
                            projectId,
                            notificatonData?.id ||
                                notificatonData?.notificationId
                        );
                        if (publishResponse.status) {
                            openNotification(
                                'success',
                                'Successful!',
                                'Notification Published!'
                            );
                            setVisible(false);
                            onRefreshData();
                        }
                    }
                    if (!response.status) {
                        openNotification(
                            'error',
                            'An error!',
                            response.message
                        );
                    }
                }
            }
        } else if (notificationStatus === 'schedule') {
            const notificationSchedule = scheduleDate.utc().format();

            if (
                moment()
                    .add(4, 'minutes')
                    .diff(moment(notificationSchedule), 'second') > 0
            ) {
                const userResponse = window.confirm(
                    'Scheduled Time should be atleast 5 minutes from now.Click on "OK" to reset the time.'
                );
                if (userResponse)
                    setScheduleDate(moment().tz(timezone).add(5, 'minutes'));
                return;
            }

            const updateInputs = {
                data: {
                    title: notificatonData?.title,
                    description: notificatonData?.description,
                    scheduledTime: notificationSchedule,
                    position: notificatonData?.position,
                    ...(notificatonData?.ctaVisible && {
                        cta: [
                            {
                                id: notificatonData?.ctaId,
                                label: notificatonData?.buttonLabel,
                                url: notificatonData?.buttonURL,
                                color: notificatonData?.buttonColor,
                                textColor: notificatonData?.buttonTextColor,
                                openInNewWindow:
                                    notificatonData?.openInNewWindow,
                            },
                        ],
                    }),
                    ...(!notificatonData?.ctaVisible && {
                        cta: [],
                    }),
                    ...(!isEmpty(notificatonData?.cta) && {
                        cta: notificatonData?.cta,
                    }),
                    ...((notificatonData?.senderVisible ||
                        (!notificatonData?.hasOwnProperty('senderVisible') &&
                            (notificatonData?.subTitle ||
                                notificatonData?.image))) && {
                        subTitle: notificatonData?.subTitle,
                        image: notificatonData?.image,
                    }),
                    ...(notificatonData?.hasOwnProperty('senderVisible') &&
                        !notificatonData?.senderVisible && {
                            subTitle: null,
                            image: null,
                        }),
                    ...(notificatonData?.autoCloseVisible && {
                        autoCloseTime: Number(notificatonData?.autoCloseTime),
                    }),
                    ...(!notificatonData?.autoCloseVisible && {
                        autoCloseTime: null,
                    }),

                    ...(notificatonData?.targetType === 'project' && {
                        targetType: 'project',
                    }),
                    ...(notificatonData?.targetType === 'livepage' && {
                        targetType: 'livepage',
                        targetId: notificatonData?.targetId.map(
                            (value) => value.key
                        ),
                    }),
                },
            };
            //Create new notification
            if (!notificationEditMode) {
                const newNotificationId = await handleCreateNotification();
                const response = await UpdateNotification(
                    updateInputs,
                    projectId,
                    newNotificationId
                );
                if (response.status) {
                    const publishResponse = await PublishNotification(
                        projectId,
                        newNotificationId
                    );
                    if (publishResponse.status) {
                        openNotification(
                            'success',
                            'Successful!',
                            'Notification scheduled!'
                        );
                        setVisible(false);
                        onRefreshData();
                    }
                    if (!response.status) {
                        openNotification(
                            'error',
                            'An error!',
                            response.message
                        );
                    }
                } else if (newNotificationId) {
                    openNotification(
                        'error',
                        'An error',
                        'Notification created, but an error was encountered while scheduled.'
                    );
                    history.push({
                        pathname: `/company/${companyId}/project/${projectId}/notification/announcements`,
                        state: { redirectTab: 'created' },
                    });
                } else {
                    openNotification(
                        'error',
                        'An error',
                        'An error was encountered please try again'
                    );
                }
            }
            //Schedule exist notification
            if (notificationEditMode) {
                const response = await UpdateNotification(
                    updateInputs,
                    projectId,
                    notificatonData?.id || notificatonData?.notificationId
                );
                if (response.status) {
                    const publishResponse = await PublishNotification(
                        projectId,
                        notificatonData?.id || notificatonData?.notificationId
                    );
                    if (publishResponse.status) {
                        openNotification(
                            'success',
                            'Successful!',
                            'Notification scheduled!'
                        );
                        setVisible(false);
                        onRefreshData();
                    }
                }
                if (!response.status) {
                    openNotification('error', 'An error!', response.message);
                }
            }
        }
    }, 500);

    const openNotification = (
        type = 'success',
        alertTitle = '',
        alertMessage = ''
    ) => {
        notification[type]({
            message: alertTitle,
            description: alertMessage,
            placement: 'bottomLeft',
        });
    };
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            footer={null}
            closable={true}
            width={396}
            centered
            bodyStyle={{ padding: '24px' }}
            className="tw-rounded-2xl tw-shadow-lg tw-overflow-hidden"
            modalRender={(modal) => (
                <div className="tw-rounded-2xl tw-overflow-hidden">{modal}</div>
            )}
        >
            <div className="tw-flex tw-flex-col">
                <div className="tw-bg-white tw-rounded-lg">
                    <div className="tw-flex tw-flex-col tw-items-center">
                        <span className="tw-text-[18px] tw-font-semibold tw-text-mobileTextBlack tw-mb-1">
                            Review your settings
                        </span>
                        <p className="tw-text-sm tw-text-mobileTextGray tw-font-normal tw-text-center tw-mb-4">
                            The notification will be sent based on your
                            settings.
                        </p>
                    </div>

                    <p className="tw-text-sm tw-text-mobileTextGray tw-mb-2 ">
                        It will be sent to the following{' '}
                        <span className="tw-font-semibold">
                            {
                                notificatonData?.targetId?.filter(function (e) {
                                    return e;
                                }).length
                            }{' '}
                            livepage(s):
                        </span>
                    </p>
                    <List
                        className="live-pages-list"
                        size="small"
                        bordered
                        dataSource={notificatonData?.targetId?.filter(function (
                            e
                        ) {
                            return e;
                        })}
                        renderItem={(item) => (
                            <List.Item>{item.value}</List.Item>
                        )}
                    />

                    <p className="tw-font-normal tw-text-sm tw-mb-2 tw-mt-4">
                        When
                    </p>
                    {sendMode !== 'sendNow' ? (
                        <>
                            <p className="tw-text-sm tw-mb-1 tw-textfont-semibold">
                                Date
                            </p>
                            <DatePicker
                                style={{
                                    width: '100%',
                                    fontSize: '13px',
                                    fontWeight: '600',
                                    height: '44px',
                                    borderRadius: '4px',
                                }}
                                className="tw-mb-3"
                                disabledDate={(current) =>
                                    current <
                                    moment().tz(timezone).startOf('day')
                                }
                                allowClear={false}
                                value={scheduleDate}
                                onChange={setScheduleDate}
                                showToday={false}
                                format="D MMM YYYY"
                            />

                            <p className="tw-text-sm tw-mb-1 tw-textfont-semibold">
                                Time
                            </p>

                            <TimePicker
                                allowClear={false}
                                style={{
                                    width: '100%',
                                    fontSize: '13px',
                                    fontWeight: '600',
                                    height: '44px',
                                    borderRadius: '4px',
                                }}
                                className="tw-mb-3"
                                disabledDate={(current) =>
                                    current <
                                    moment().tz(timezone).add(9, 'minutes')
                                }
                                value={scheduleDate}
                                onChange={setScheduleDate}
                                format="hh:mm a"
                                showNow={false}
                            />

                            <p className="tw-text-sm tw-mb-1 tw-textfont-semibold">
                                Time Zone
                            </p>
                            <TimezoneSelector
                                selectStyle={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    color: '#101828',
                                    fontFamily: 'Open Sans',
                                    width: '100%',
                                }}
                                selectClassnames="selectMobileView"
                                wrapperClass="wrapperMobileView"
                            />
                        </>
                    ) : (
                        <Input
                            value={'now'}
                            readOnly
                            className=" tw-p-2 tw-min-h-[44px] tw-text-base tw-text-[#747578] tw-border tw-rounded"
                        />
                    )}

                    <button
                        className=" tw-mt-4 tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer tw-mb-2"
                        onClick={() => handleSendNotification(sendMode)}
                    >
                        Send now
                    </button>
                    <button
                        className="tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-border-gray-light tw-rounded-md tw-bg-white tw-text-blue-gray  tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer"
                        onClick={() => setVisible(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ScheduleModalMobile;
