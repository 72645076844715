import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Modal, Tabs, Tooltip, notification } from 'antd';
import { useSelector } from 'react-redux';

import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import moment from 'moment';

import PromptWithInput from 'components/app/common/prompt/promptWithInput';
import Prompt from 'components/app/common/prompt';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';

import { GetNotifications } from 'apis/rest/notifications/GetNotifications';
import { CancelNotification } from 'apis/rest/notifications/CancelNotification';
import { DeleteNotification } from 'apis/rest/notifications/DeleteNotification';
import { DuplicateNotification } from 'apis/rest/notifications/DuplicateNotification';
import useIsMobile from 'hooks/useIsMobile';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import ScheduleModal from './createAnnouncement/scheduleModal';
import DraftTable from './tables/draftTable/draftTable';
import SentTable from './tables/sentTable/sentTable';
import ScheduledTable from './tables/scheduledTable';
import Layout from '../../../../common/layout';
import './announcements.scss';
import RadioGroupButtons from '../../../../../../ComponentsV2/CommonComponents/MobileView/radioGroup/RadioGroupButtons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CancelScheduleModal from '../mobileComponents/CancelScheduleModal';
import useScreenSize from 'components/app/utils/hooks/useScreenSize/useScreenSize';
import ScheduleModalMobile from '../mobileComponents/ScheduleModalMobile';

const { TabPane } = Tabs;
const LOAD_MORE = 'load-more';
const REFRESH_DATA = 'refresh-data';
const options = [
    {
        label: 'Scheduled',
        value: 'queued',
    },
    {
        label: 'Sent',
        value: 'published',
    },
    {
        label: 'Drafts',
        value: 'created',
    },
];
function Announcements() {
    const history = useHistory();
    const { projectId, companyId } = useParams();
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );
    const location = useLocation();
    const deviceType = useScreenSize();
    const currentActiveTab = location?.hash?.slice(1) || 'queued';
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState(null);
    const [notificationTableLastKey, setNotificationTableLastKey] =
        useState('');
    const [notificationTableSearchText, setNotificationTableSearchText] =
        useState('');

    const [notificationSendNow, setNotificationSendNow] = useState(true);
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
    const [selectedNotificationData, setSelectedNotificationData] =
        useState(null);
    const [currentTab, setCurrentTab] = useState(currentActiveTab);

    const [showDuplicatePrompt, setShowDuplicatePrompt] = useState(false);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [showCancelPrompt, setShowCancelPrompt] = useState(false);
    const [duplicateTitle, setDuplicateTitle] = useState('');
    const [duplicateId, setDuplicateId] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [cancelId, setCancelId] = useState('');
    const [scheduleDate, setScheduleDate] = useState(
        moment().tz(timezone).add(10, 'minutes')
    );

    useEffect(() => {
        setScheduleDate(moment().tz(timezone).add(10, 'minutes'));
    }, [timezone]);

    useEffect(() => {
        handleGetNotifications();
    }, [notificationTableSearchText, currentTab]);

    const handleGetNotifications = async (type) => {
        try {
            setIsLoading(true);
            const isTypeRefreshData = type === REFRESH_DATA;
            if (isTypeRefreshData) {
                setNotificationTableLastKey('');
            }
            const response = await GetNotifications(
                projectId,
                currentTab,
                '',
                isTypeRefreshData ? '' : notificationTableLastKey,
                notificationTableSearchText,
                timezone.toLowerCase()
            );
            if (response.status) {
                setNotificationTableLastKey(response?.lastEvaluatedKey);
                setNotifications((prev) => ({
                    ...prev,
                    [currentTab]: {
                        ...(prev?.[currentTab] || {}),
                        ...response,
                        notifications: [
                            ...(type === LOAD_MORE
                                ? prev?.[currentTab]?.notifications || []
                                : []),
                            ...(response?.notifications || []),
                        ],
                    },
                }));
            } else {
                openNotification(
                    'error',
                    'An error',
                    'An error was encountered please try again'
                );
            }
        } catch (error) {
            console.error('ERROR handleGetNotifications', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onClickCancelNotification = async (notificationId) => {
        try {
            const response = await CancelNotification(
                projectId,
                notificationId
            );
            if (response.status) {
                handleGetNotifications();
                openNotification(
                    'success',
                    'Successful!',
                    'Scheduled sending has been cancelled.'
                );
            } else {
                openNotification(
                    'error',
                    'An error',
                    'An error was encountered please try again'
                );
            }
        } catch (error) {
            console.error('ERROR onClickCancelNotification', error);
            openNotification(
                'error',
                'An error',
                'An error was encountered please try again'
            );
        } finally {
            setShowCancelPrompt(false);
        }
    };

    const onClickDeleteNotification = async (notificationId) => {
        try {
            const response = await DeleteNotification(
                projectId,
                notificationId
            );
            if (response.status) {
                handleGetNotifications();
                openNotification(
                    'success',
                    'Successful!',
                    'Notification Deleted!'
                );
            } else {
                openNotification('error', 'An error', response.message);
            }
        } catch (error) {
            console.error('ERROR onClickDeleteNotification', error);
            openNotification(
                'error',
                'An error',
                'An error was encountered please try again'
            );
        } finally {
            setShowDeletePrompt(false);
        }
    };

    const handlePreDuplicate = (notification) => {
        setNotificationTableLastKey('');
        setDuplicateTitle(`${notification.title} Copy`);
        setDuplicateId(notification?.notificationId);
        setShowDuplicatePrompt(true);
    };

    const handlePreDelete = (notificationId) => {
        setNotificationTableLastKey('');
        setDeleteId(notificationId);
        setShowDeletePrompt(true);
    };

    const handlePreCancel = (notificationId) => {
        setNotificationTableLastKey('');
        setCancelId(notificationId);
        setShowCancelPrompt(true);
    };

    const onClickDuplicateNotification = async (notificationId, newTitle) => {
        try {
            const response = await DuplicateNotification(
                newTitle,
                projectId,
                notificationId
            );
            if (response.status) {
                handleGetNotifications();
                openNotification(
                    'success',
                    'Successful!',
                    'Notification duplicated!'
                );
            }
        } catch (error) {
            console.error('ERROR onClickDuplicateNotification', error);
            openNotification(
                'error',
                'An error',
                'An error was encountered please try again'
            );
        } finally {
            setShowDuplicatePrompt(false);
        }
    };

    // Close prompt for delete and duplicate
    const handleClosePrompt = (type) => {
        if (type === 'delete') {
            setShowDeletePrompt(false);
        }
        if (type === 'duplicate') {
            setShowDuplicatePrompt(false);
        }
        if (type === 'cancel') {
            setShowCancelPrompt(false);
        }
    };

    const openNotification = (
        type = 'success',
        alertTitle = '',
        alertMessage = ''
    ) => {
        notification[type]({
            message: alertTitle,
            description: alertMessage,
            placement: 'bottomLeft',
        });
    };

    const tabsMobileView = () => {
        const {
            created = {},
            queued = {},
            published = {},
        } = notifications || {};
        const isDataAvailable =
            created?.notifications?.length ||
            queued?.notifications?.length ||
            published?.notifications?.length;

        const onChange3 = (e) => {
            const tabKey = e.target.value;
            if (tabKey === currentTab) return;
            setNotificationTableLastKey('');
            setNotifications((prev) => ({
                ...prev,
                [currentTab]: {},
            }));
            setCurrentTab(tabKey);
            history.push(`#${tabKey}`);
        };
        const renderTabPannel = (key) => {
            switch (key) {
                case 'created':
                    return (
                        <DraftTable
                            data={notifications?.created}
                            loading={isLoading}
                            search={(searchText) => {
                                setNotificationTableLastKey('');
                                setNotificationTableSearchText(searchText);
                            }}
                            onClickDuplicateNotification={handlePreDuplicate}
                            onClickDeleteNotification={handlePreDelete}
                            sendNotification={(type, item) => {
                                setIsScheduleModalVisible(true);
                                setNotificationSendNow(type === 'sendNow');
                                setSelectedNotificationData(item);
                            }}
                        />
                    );
                case 'queued':
                    return (
                        <ScheduledTable
                            data={notifications?.queued}
                            loading={isLoading}
                            search={(searchText) => {
                                setNotificationTableLastKey('');
                                setNotificationTableSearchText(searchText);
                            }}
                            onClickCancelNotification={handlePreCancel}
                            onClickDeleteNotification={handlePreDelete}
                        />
                    );

                case 'published':
                    return (
                        <SentTable
                            data={notifications?.published}
                            loading={isLoading}
                            search={(searchText) => {
                                setNotificationTableLastKey('');
                                setNotificationTableSearchText(searchText);
                            }}
                            onClickDuplicateNotification={handlePreDuplicate}
                            onClickDeleteNotification={handlePreDelete}
                        />
                    );
                default:
                    break;
            }
        };
        return (
            <>
                <div className="tw-bg-white tw-gap-4 tw-flex tw-mt-8 tw-flex-col tw-h-[85vh] tw-w-full md:tw-hidden">
                    {/* Radio  */}
                    <div className="tw-p-4 tw-pb-0 tw-w-full">
                        <RadioGroupButtons
                            changeHandler={onChange3}
                            options={options}
                            currentValue={currentTab}
                        />
                    </div>
                    {/* Timezone  */}
                    <div className="tw-w-full">
                        <TimezoneSelector
                            selectStyle={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '600',
                                color: '#101828',
                                fontFamily: 'Open Sans',
                            }}
                            selectClassnames="selectMobileView"
                            wrapperClass="wrapperMobileView tw-p-4 tw-py-0"
                        />
                    </div>
                    {/* tabs  */}
                    <div className="tw-rounded-sm tw-shadow-sm tw-overflow-auto">
                        {isLoading && <GlobalLoading />}
                        {renderTabPannel(currentTab)}
                    </div>
                </div>
            </>
        );
    };

    return (
        <Layout disableSubMenuWidth className="tw-p-0">
            <div className="container">
                {deviceType !== 'mobile' && (
                    <>
                        <PromptWithInput
                            show={showDuplicatePrompt}
                            message="Are you sure you want to duplicate this Notification?"
                            description="Name your duplicated Notification"
                            dataID={duplicateId}
                            inputField="title"
                            inputValue={duplicateTitle}
                            buttons={['Cancel', 'Proceed to Duplicate']}
                            onHide={() => handleClosePrompt('duplicate')}
                            callback={onClickDuplicateNotification}
                        />
                        <Prompt
                            show={showDeletePrompt}
                            message="Are you sure you want to delete this notification?"
                            buttons={['Cancel', 'Proceed to Delete']}
                            onHide={() => handleClosePrompt('delete')}
                            callback={() => onClickDeleteNotification(deleteId)}
                        />
                        <Prompt
                            show={showCancelPrompt}
                            message="Are you sure you want to cancel the schedule of this announcement?"
                            buttons={['Cancel', 'Proceed to Cancel']}
                            onHide={() => handleClosePrompt('cancel')}
                            callback={() => onClickCancelNotification(cancelId)}
                        />
                    </>
                )}
                {deviceType === 'mobile' && (
                    <>
                        <CancelScheduleModal
                            isVisible={showCancelPrompt}
                            onCancel={() => handleClosePrompt('cancel')}
                            onConfirm={() =>
                                onClickCancelNotification(cancelId)
                            }
                            confirmButtonText={'Cancel schedule'}
                            heading={'Cancel schedule'}
                            description={
                                'Are you sure you want to cancel the schedule of this announcement?'
                            }
                        />
                        <CancelScheduleModal
                            isVisible={showDeletePrompt}
                            onCancel={() => handleClosePrompt('delete')}
                            onConfirm={() =>
                                onClickDeleteNotification(deleteId)
                            }
                            confirmButtonText={'Proceed to Delete'}
                            heading={'Delete'}
                            description={
                                'Are you sure you want to delete this notification?'
                            }
                        />

                        <CancelScheduleModal
                            isVisible={showDuplicatePrompt}
                            onCancel={() => handleClosePrompt('duplicate')}
                            onConfirm={onClickDuplicateNotification}
                            confirmButtonText={'Proceed to Duplicate'}
                            heading={'Duplicate'}
                            dataID={duplicateId}
                            description={
                                'Are you sure you want to duplicate this Notification?'
                            }
                            withInput={true}
                            initialInput={duplicateTitle}
                            inputFieldLabel={
                                'Name your duplicated Notification'
                            }
                        />
                    </>
                )}

                <div className="row">
                    <div className="col-6">
                        <h1 className="my-3 tw-hidden md:tw-block">
                            Announcements
                        </h1>
                    </div>
                    <div className="col-6 md:tw-flex tw-hidden">
                        <button
                            className="myxp btn btn-primary ml-auto my-3"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/notification/create-announcement`
                                )
                            }
                        >
                            Create announcement
                        </button>
                    </div>

                    <div className="col-12 md:tw-block tw-hidden">
                        <TimezoneSelector
                            selectStyle={{ width: 150, zIndex: 10, right: 20 }}
                        />

                        <Tabs
                            className="bg-white py-3 border border-radius sponsors-tabs"
                            defaultActiveKey={currentTab}
                            activeKey={currentTab}
                            onChange={(tabKey) => {
                                if (tabKey === currentTab) return;
                                setNotificationTableLastKey('');
                                setNotifications((prev) => ({
                                    ...prev,
                                    [currentTab]: {},
                                }));
                                setCurrentTab(tabKey);
                            }}
                        >
                            <TabPane tab="Scheduled" key="queued">
                                <ScheduledTable
                                    data={notifications?.queued}
                                    loading={isLoading}
                                    search={(searchText) => {
                                        setNotificationTableLastKey('');
                                        setNotificationTableSearchText(
                                            searchText
                                        );
                                    }}
                                    onClickCancelNotification={handlePreCancel}
                                    onClickDeleteNotification={handlePreDelete}
                                />
                            </TabPane>
                            <TabPane tab="Sent" key="published">
                                <SentTable
                                    data={notifications?.published}
                                    loading={isLoading}
                                    search={(searchText) => {
                                        setNotificationTableLastKey('');
                                        setNotificationTableSearchText(
                                            searchText
                                        );
                                    }}
                                    onClickDuplicateNotification={
                                        handlePreDuplicate
                                    }
                                    onClickDeleteNotification={handlePreDelete}
                                />
                            </TabPane>
                            <TabPane tab="Drafts" key="created">
                                <DraftTable
                                    data={notifications?.created}
                                    loading={isLoading}
                                    search={(searchText) => {
                                        setNotificationTableLastKey('');
                                        setNotificationTableSearchText(
                                            searchText
                                        );
                                    }}
                                    onClickDuplicateNotification={
                                        handlePreDuplicate
                                    }
                                    onClickDeleteNotification={handlePreDelete}
                                    sendNotification={(type, item) => {
                                        setIsScheduleModalVisible(true);
                                        setNotificationSendNow(
                                            type === 'sendNow'
                                        );
                                        setSelectedNotificationData(item);
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                    {tabsMobileView()}
                </div>
                {notificationTableLastKey !== '' ? (
                    <div className="row my-2">
                        <div className="col-12">
                            <button
                                className="myxp btn btn-primary m-auto"
                                onClick={() =>
                                    handleGetNotifications(LOAD_MORE)
                                }
                            >
                                Load more
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
            {deviceType !== 'mobile' && (
                <ScheduleModal
                    {...{
                        scheduleDate,
                        setScheduleDate,
                        sendMode: notificationSendNow ? 'sendNow' : 'schedule',
                    }}
                    notificationSendNow={notificationSendNow}
                    visible={isScheduleModalVisible}
                    setVisible={setIsScheduleModalVisible}
                    notificatonData={selectedNotificationData}
                    onRefreshData={() => handleGetNotifications(REFRESH_DATA)}
                    notificationEditMode
                    sendDirect
                />
            )}
            {deviceType === 'mobile' && (
                <ScheduleModalMobile
                    {...{
                        scheduleDate,
                        setScheduleDate,
                        sendMode: notificationSendNow ? 'sendNow' : 'schedule',
                    }}
                    notificationSendNow={notificationSendNow}
                    visible={isScheduleModalVisible}
                    setVisible={setIsScheduleModalVisible}
                    notificatonData={selectedNotificationData}
                    onRefreshData={() => handleGetNotifications(REFRESH_DATA)}
                    notificationEditMode
                    sendDirect
                    onCancel={() => setIsScheduleModalVisible(false)}
                />
            )}

            <SidebarContainer>
                <MainMenu priActive="pushengagement" all />
            </SidebarContainer>
            <style>{`
            .sponsors-company-table .ant-table-container table > thead > tr:first-child th:first-child
            {
                padding-left: 0px;
            }
            `}</style>
        </Layout>
    );
}

Announcements.propTypes = {};

export default Announcements;
