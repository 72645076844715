import React, { useState, useEffect } from 'react';
import { withRouter, useLocation, useParams } from 'react-router-dom';

import * as ls from 'local-storage';
import { logout, sponsorLogout } from 'apis/rest/Authentication';
import { useSelector } from 'react-redux';
import Plans from 'ComponentsV2/CommonUtils/IdentifyPlan';
import { getNameInitials } from '../../utils/functions/common';
import DropdownPanel from '../dropdownPanel';

/** header manager */
import HeaderManager from './headerManager/headerMain';
import HeaderAppManager from './headerManager/headerApp';
import HeaderShowcase from './headerManager/showcase/headerShowcase';

/** header admin */
import HeaderAdmin from './headerAdmin';

import UserProfile, {
    UserProfileEdit,
    UserProfileVerifier,
} from './UserProfile';

import './header.scss';
import OutsideAlerter from '../outsideAlerter';

import ChatWithSales from './ChatWithSales';
import { HamburgerIcon } from './UserProfile/icons';
import SidebarDrawer from '../../../../ComponentsV2/CommonComponents/MobileView/drawer/SidebarDrawer';

function Header(props) {
    const {
        pagePayload,
        history,
        isProjListing,
        isCompListing,
        menus,
        setActiveMenu,
        activeMenu,
        projectOnly,
        header,
        sendRequestOtp,
        setSendRequestOtp,
    } = props;

    const { STARTER_PLAN } = Plans;

    const { companyId } = useParams();
    const companyDetails = useSelector((state) => state?.projects?.company);
    const user = useSelector((state) => state.user);
    const location = useLocation();
    const path = location.pathname;
    const isSponsorDashboard = !!ls.get('sponsorAuth');
    const isHomeDashboard = path?.endsWith('sponsor-home');

    const [showDropdown, setShowDropdown] = useState(false);
    const [showUserProfileEdit, setShowUserProfileEdit] = useState(false);
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };
    /**
     * render header content depending on product and app
     */
    const renderHeader = () => {
        const { location } = props;

        if (user.role !== 'null' && user?.role?.name === 'Admin') {
            return <HeaderAdmin />;
        }

        switch (true) {
            case location.pathname.includes('dashboard'):
                return <HeaderAppManager />;
            case location.pathname.includes('showcase') &&
                location.pathname.includes('/c/'):
                return <HeaderShowcase pagePayload={pagePayload} />;
            default:
                return (
                    <HeaderManager
                        {...{
                            isProjListing,
                            isCompListing,
                            menus,
                            setActiveMenu,
                            activeMenu,
                            projectOnly,
                            header,
                            handleLogout,
                        }}
                    />
                );
        }
    };

    const handleLogout = async () => {
        let userType = null;
        if (ls.get('sponsorAuth')) userType = 'sponsor';
        try {
            if (userType === 'sponsor') await sponsorLogout();
            else await logout({});
        } catch (error) {
            console.error(error);
        } finally {
            ls.clear();
            try {
                const cookies = document.cookie.split('; ');
                for (let c = 0; c < cookies.length; c++) {
                    const d = window.location.hostname.split('.');
                    while (d.length > 0) {
                        const cookieBase = `${encodeURIComponent(
                            cookies[c].split(';')[0].split('=')[0]
                        )}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${d.join(
                            '.'
                        )} ;path=`;
                        const p = window.location.pathname.split('/');
                        document.cookie = `${cookieBase}/`;
                        while (p.length > 0) {
                            document.cookie = cookieBase + p.join('/');
                            p.pop();
                        }
                        d.shift();
                    }
                }
            } catch (error) {
                console.error(error);
            }
            try {
                if (window.fcWidget) {
                    window.fcWidget.user.clear();
                    window.fcWidget.destroy();
                }
            } catch (error) {
                console.error(error);
            }
            window.location.href = '/login';
        }
    };

    useEffect(() => {
        if (!user?.require2FASetup) return;
        history.push({
            pathname: '/2fa-setup',
            email: user?.email,
        });
    }, [user?.require2FASetup]);

    /* TEMPORARY for switch event */
    const isSwitch2020 = () => user.roleId === 4;

    const renderMenu = (
        <div className="header__profile" onClick={() => setShowDropdown(true)}>
            {isSwitch2020() ? (
                <span className="company-name">{user.company.name}</span>
            ) : (
                ''
            )}

            {user && user !== 'undefined' && (
                <div className="header__profile-user-set">
                    <div className="header__profile_icon">
                        <span>{getNameInitials(user)}</span>
                    </div>
                </div>
            )}

            <OutsideAlerter
                payload={{
                    elementVisibility: showDropdown,
                    setElementVisibility: setShowDropdown,
                }}
            >
                <DropdownPanel panelVisibility={showDropdown}>
                    <UserProfile
                        user={user}
                        setShowUserProfileEdit={setShowUserProfileEdit}
                        handleLogout={handleLogout}
                    />
                </DropdownPanel>
            </OutsideAlerter>
        </div>
    );

    return (
        <>
            {showUserProfileEdit && (
                <UserProfileEdit
                    user={user}
                    showUserProfileEdit={showUserProfileEdit}
                    setShowUserProfileEdit={setShowUserProfileEdit}
                />
            )}

            {isSponsorDashboard ? null : (
                <UserProfileVerifier
                    user={user}
                    sendRequestOtp={sendRequestOtp}
                    setSendRequestOtp={setSendRequestOtp}
                />
            )}
            <div
                className={`header header--xpmanager shadow-sm fixed ${
                    isSponsorDashboard || user?.isVerified ? '' : 'tw-top-16'
                }`}
            >
                <div className="header__custom">{renderHeader()}</div>
                {/* Profile */}

                {companyDetails?.package?.id === STARTER_PLAN && (
                    <div className="Upgrade">
                        <p>
                            <span className="pr-1">
                                You are on the free tier of the <b>Starter</b>{' '}
                                plan{' '}
                            </span>
                        </p>
                        <button
                            onClick={() =>
                                history.push(`/company/${companyId}/upgrade`)
                            }
                        >
                            Upgrade
                        </button>
                        <ChatWithSales className="tw-ml-2.5" />
                    </div>
                )}
                {renderMenu}
                <SidebarDrawer
                    visible={visible}
                    setVisible={setVisible}
                    user={user}
                />
            </div>
        </>
    );
}

export default withRouter(Header);
