import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { useMutation } from "@apollo/react-hooks";

import { produce } from "immer";
import { isEmpty } from "lodash";
// import RichTextEditor from "react-rte";

import { uploadFile } from "apis/rest/strapi/UploadFile";

import UPDATE_SHOWCASE from "apis/graphql/UpdateShowcase";
import SHOWCASE from "apis/graphql/Showcase";

import useFormValidator from "components/app/utils/hooks/useFormValidator";
import showcaseInfoValidationCriteria from "components/app/utils/validators/showcaseInfoValidator";
import fontIcons from "components/app/utils/dataList/fontIcons";

import FileUploader from "components/app/common/fileUploader";

import "./showcaseEditor.scss";

function ShowcaseEditor(props) {
  const {
    setModalVisibility,
    reloadIframe,
    updatePayload: { showcase, type },
    showAlert,
    match: { params }
  } = props;
  const showcaseId = params.showcaseId;

  const [updateShowcase] = useMutation(UPDATE_SHOWCASE, {
    onCompleted: data => {
      showAlert("Profile updated!", "success");
    },
    onError: err => {
      showAlert(err.message, "error");
    }
  });

  const [input, setInput] = useState({
    locationCountry: "Singapore"
  });

  const [currUploads, setCurrUploads] = useState({});

  const [rteditorValue, setRtEditorValue] = useState(null);

  const { validateInput, renderFieldError, hasErrors } = useFormValidator({
    input,
    type,
    validationCriteria: showcaseInfoValidationCriteria
  });

  useEffect(() => {
    if (showcase) {
      setInput({
        callToActionText: showcase.callToActionText,
        callToActionValue: showcase.callToActionValue,
        callToActionAction: showcase.callToActionAction,
        callToActionIcon: showcase.callToActionIcon,
        overviewTitle: showcase.overviewTitle,
        overviewSummary: showcase.overviewSummary,
        overview: showcase.overview
      });

      setCurrUploads({
        banner: showcase.banner
      });

      setRtEditorValue(
        // RichTextEditor.createValueFromString(showcase.overview, "markdown")
        showcase.overview
      );
    }
  }, [showcase]);

  const onSubmit = e => {
    e.preventDefault();

    const formErrors = validateInput();

    if (!hasErrors(formErrors)) {
      updateShowcase({
        variables: {
          input: {
            data: input,
            where: {
              id: showcase.id
            }
          }
        },
        refetchQueries: [{ query: SHOWCASE, variables: { id: showcaseId } }]
      });

      reloadIframe();
      setModalVisibility(false);
    }
  };

  /**
   * handles change to the Rich Text Editor
   * @param {object} value value produced by the editor
   */
  const handleRichTextEditorChange = value => {
    let nextState;

    setRtEditorValue(value);

    nextState = produce(input, draftState => {
      draftState.overview = value.toString("markdown");
    });

    setInput(nextState);
  };

  /**
   * handles change input field
   * @param {object} e event object
   */
  const handleInputChange = e => {
    let value = e.target.value;
    let name = e.target.name;

    let nextState = produce(input, draftState => {
      draftState[name] = value;
    });

    setInput(nextState);
  };

  /**
   * sets `uploadId` coming from `uploadFile()` to input state
   * @param {object} e event object
   */
  const handleFileUpload = async (files, fileType) => {
    const uploadId = await uploadFile(files, "showcase", showcaseId);
    let nextState = produce(input, draftState => {
      draftState[fileType] = uploadId;
    });

    setInput(nextState);
  };

  /**
   * gets call to action "input" type and "icon" based on selected "action"
   */
  const getCallToActionType = () => {
    switch (input.callToActionAction) {
      case "phone":
        return {
          type: "tel",
          icon: "phone"
        };
      case "email":
        return {
          type: "email",
          icon: "envelope"
        };
      default:
        return {
          type: "text",
          icon: `${input.callToActionIcon}`
        };
    }
  };

  /**
   * select call to action icon
   * @param {string} icon icon selected
   */
  const selectCallToActionIcon = icon => {
    let nextState = produce(input, draftState => {
      draftState.callToActionIcon = icon;
    });

    setInput(nextState);
  };

  /**
   * renders the list of call to icon selections
   */
  const renderCallToActionIconSelection = () => {
    const fontIconsArr = [];

    /**
     * using `for` loop is a little faster than `map` with the
     * case of this large no. of array items
     */
    for (const [index, fontIcon] of fontIcons.entries()) {
      fontIconsArr.push(
        <li
          key={fontIcon}
          title={fontIcon}
          onClick={() => selectCallToActionIcon(fontIcon)}
          className={
            input.callToActionIcon === fontIcon
              ? "icon-selector__item--active"
              : ""
          }
        >
          <i className={`fas fa-${fontIcon}`}></i>
        </li>
      );
    }

    return fontIconsArr;
  };

  const { banner } = currUploads;

  if (showcase && !isEmpty(currUploads)) {
    return (
      <div className="showcase-editor">
        <img
          src="/assets/icon_close.svg"
          alt=""
          className="icon-close"
          onClick={() => setModalVisibility(false)}
        />
        <form onSubmit={onSubmit}>
          <div>
            <div>
              {type === "banner" && (
                <div className="form-group">
                  <h2 className="mb-4">Banner Image</h2>
                  <FileUploader
                    handleFileUpload={handleFileUpload}
                    fileType="banner"
                    size="large"
                    currentFile={banner}
                  ></FileUploader>
                </div>
              )}

              {type === "callToAction" && (
                <div className="call-to-action">
                  <h2 className="mb-3">Call to Action</h2>
                  <div className="call-to-action__preview">
                    <button className="btn btn-primary">
                      <i className={`fas fa-${getCallToActionType().icon}`}></i>
                      {input.callToActionText}
                    </button>
                  </div>

                  <div className="form-group">
                    <label>Action</label>
                    <select
                      className="form-control"
                      name="callToActionAction"
                      onChange={handleInputChange}
                      value={input.callToActionAction}
                    >
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>
                      <option value="link">Link</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label>
                      Text <span className="text-danger small">(required)</span>
                    </label>
                    <input
                      type="text"
                      value={input.callToActionText}
                      name="callToActionText"
                      className="form-control"
                      onChange={handleInputChange}
                      maxLength={40}
                    />
                    {renderFieldError("callToActionText")}
                  </div>

                  <div className="form-group">
                    <label>
                      Value{" "}
                      <span className="text-danger small">(required)</span>
                    </label>
                    <input
                      type={getCallToActionType().type}
                      value={input.callToActionValue}
                      name="callToActionValue"
                      className="form-control"
                      onChange={handleInputChange}
                      maxLength={500}
                    />
                    {renderFieldError("callToActionValue")}
                  </div>

                  {input.callToActionAction === "link" && (
                    <div>
                      <label>Icon</label>

                      <ul className="icon-selector mb-5">
                        {renderCallToActionIconSelection()}
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {type === "overview" && (
                <>
                  <h2 className="mb-4">Overview</h2>
                  <div className="form-group">
                    <label htmlFor="" className="control-label">
                      Overview Title
                    </label>
                    <input
                      type="text"
                      value={input.overviewTitle}
                      name="overviewTitle"
                      className="form-control"
                      onChange={handleInputChange}
                      maxLength={200}
                    />
                    {renderFieldError("overviewTitle")}
                  </div>

                  <div className="form-group">
                    <label htmlFor="" className="control-label">
                      Overview Summary
                    </label>
                    <textarea
                      name="overviewSummary"
                      value={input.overviewSummary}
                      className="form-control"
                      onChange={handleInputChange}
                      maxLength={500}
                    ></textarea>
                    {renderFieldError("overviewSummary")}
                  </div>

                  <div className="form-group">
                    <label htmlFor="" className="control-label">
                      Overview
                    </label>
                    <div className="text-rich__wrap">
                      {/* <RichTextEditor
                        value={rteditorValue}
                        onChange={handleRichTextEditorChange}
                        className="rich-text-editor"
                      /> */}
                    </div>
                    {renderFieldError("overview")}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="grouper grouper--right">
            <button
              className="btn btn-secondary"
              onClick={e => {
                e.preventDefault();
                setModalVisibility(false);
              }}
            >
              Cancel
            </button>
            <input className="btn btn-primary" type="submit" />
          </div>
        </form>
      </div>
    );
  } else {
    return <p>Loading form...</p>;
  }
}

export default withRouter(ShowcaseEditor);
