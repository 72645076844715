import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setUser } from 'redux/actions/common/userActions';
import { EllipsisOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import './InviteUserMobileForm.scss';
import { inviteUsers } from 'apis/rest/InviteUsers';
import { getUser } from 'apis/rest/User';
import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';

export default function InviteUserMobileForm(props) {
    const {
        setModalVisibility,
        showAlert,
        handleGetLeadsUsers,
        leadsQuota,
        totalLeadsQuota,
    } = props;
    const dispatch = useDispatch();
    const { projectId } = useParams();

    const [sendingInvites, setSendingInvites] = useState(false);
    const [isInviteEnable, setIsInviteEnable] = useState(false);
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phoneNo: '',
        invitationType: 'email',
    });
    const remainingInvites = totalLeadsQuota - leadsQuota - 1;

    const handleGetUser = async () => {
        try {
            const { status, user, message } = await getUser();
            if (!status) {
                throw new Error(message);
            }
            dispatch(setUser(user));
        } catch (err) {
            console.error(err);
            showAlert(err?.message || 'Something went wrong', 'error');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    async function sendInvitesHandler() {
        try {
            setSendingInvites(true);
            console.log({ formData });
            const response = await inviteUsers([formData], projectId);
            if (!response.status) {
                throw new Error(response?.message);
            }
            handleGetLeadsUsers({});
            await handleGetUser();
            setModalVisibility(false);
            showAlert('Invite(s) sent!', 'success');
        } catch (err) {
            console.error(err);
            showAlert(err?.message || 'Something went wrong', 'error');
        } finally {
            setSendingInvites(false);
        }
    }

    useEffect(() => {
        setIsInviteEnable(
            !isEmpty(formData?.firstname) &&
                !isEmpty(formData?.lastname) &&
                !isEmpty(formData?.email)
        );
    }, [formData]);

    return (
        <div className="tw-flex tw-flex-col tw-gap-6">
            <div className="tw-w-full tw-gap-2 tw-bg-white tw-rounded-sm tw-shadow-sm tw-border">
                <div id="inviteUserFormMobile">
                    <div className="form-group">
                        <label htmlFor="firstName">
                            First Name{' '}
                            <span className="font-weight-bold text-danger">
                                *
                            </span>
                        </label>
                        <input
                            className="form-control"
                            name="firstname"
                            id="firstName"
                            required
                            value={formData?.firstName}
                            onChange={handleChange}
                            placeholder="Enter First Name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastname">
                            Last Name{' '}
                            <span className="font-weight-bold text-danger">
                                *
                            </span>
                        </label>
                        <input
                            className="form-control"
                            name="lastname"
                            id="lastName"
                            required
                            value={formData?.lastName}
                            onChange={handleChange}
                            placeholder="Enter Last Name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">
                            Email{' '}
                            <span className="font-weight-bold text-danger">
                                *
                            </span>
                        </label>
                        <input
                            className="form-control"
                            type="email"
                            name="email"
                            required
                            id="email"
                            value={formData?.email}
                            onChange={handleChange}
                            placeholder="Enter Email"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNo">Phone Number</label>
                        <PhoneInput
                            country="sg"
                            enableSearch
                            inputStyle={{ width: '100%' }}
                            name="phoneNo"
                            countryCodeEditable
                            value={formData?.phoneNo}
                            onChange={(phone) =>
                                handleChange({
                                    target: {
                                        name: 'phoneNo',
                                        value: `+${phone}`,
                                    },
                                })
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-gap-3 tw-justify-center ">
                {remainingInvites > 0 ? (
                    <button
                        className={`tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-sm tw-font-medium tw-text-center tw-cursor-pointer tw-ml-2 tw-font-semibold ${
                            !isInviteEnable || sendingInvites
                                ? 'tw-opacity-50 '
                                : ''
                        }`}
                        onClick={sendInvitesHandler}
                        disabled={!isInviteEnable || sendingInvites}
                    >
                        Send Invite(s)
                    </button>
                ) : (
                    <p className="tw-m-0 tw-p-0 tw-w-fit tw-text-center tw-text-sm tw-text-gray-600">
                        You don&apos;t have invites left.
                    </p>
                )}
            </div>
        </div>
    );
}
