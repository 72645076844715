import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import Prompt from 'components/app/common/prompt';
import {
    getAgendaSessions,
    getAgendaSpeakers,
    getAgendaSessionTags,
    deleteSession,
    getAgendaSessionDates,
    duplicateSession,
    bulkDeleteSession,
} from 'apis/rest/AgendaSessions';
import { getLivePages } from 'apis/rest/livestream/GetLivePages';
import { Dropdown } from 'react-bootstrap';
import * as ls from 'local-storage';
import BoxPreloader from 'components/app/common/preloader/boxPreloader';
import {
    Menu,
    Dropdown as AntdDropdown,
    Checkbox,
    Tag,
    Pagination,
    Popover,
    Select,
} from 'antd';
import useAlertBar from 'components/app/utils/hooks/useAlertBar';
import * as act from 'redux/actions/pages/actionsPages';
import { isString, isArray } from 'lodash';
import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import './sessions.scss';
import {
    DisplayTimezoneSelect,
    TimezoneLabel,
} from 'components/app/common/displayTimezoneSelect';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import CreateSession from './createSession';
import SpeakerForm from '../speakers/speakerForm';
import DeleteIconSession from 'ComponentsV2/ProjectDashboard/Icons/Common/DeleteIconSession';
import useScreenSize from 'components/app/utils/hooks/useScreenSize/useScreenSize';

const { Option } = Select;

function Sessions({ search, showForm, toggleForm, currentTabs }) {
    const { projectId, showcaseId } = useParams();
    const dispatch = useDispatch();
    const currDate = new Date();
    const screenType = useScreenSize();
    const sessionsDatesFromStore = useSelector(
        (state) => state?.pages?.agendaSessionDates
    );
    const agendaSessions = useSelector((state) => state?.pages?.agendaSessions);
    const speakersdata = useSelector((state) => state.pages.agendaSpeakers);

    const { showAlertBar } = useAlertBar();
    const [isLoading, setIsLoading] = useState(true);
    const [sessionsDates, setSessionsDates] = useState(sessionsDatesFromStore);
    const [currentLimit, setCurrentLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [editSuccess, setEditSuccess] = useState(false);
    const [addSpeakerSuccess, setAddSpeakerSuccess] = useState(false);
    const [showDuplicatePrompt, setShowDuplicatePrompt] = useState(false);
    const [showBulkDeletePrompt, setShowBulkDeletePrompt] = useState(false);
    const [activeDate, setActiveDate] = useState(
        `${currDate.getFullYear()}-${
            currDate.getMonth() + 1
        }-${currDate.getDate()}`
    );
    const [addedDate, setAddedDate] = useState('');
    const [formTitle, setFormTitle] = useState('Create');
    const [selectedSession, setSelectedSession] = useState({});
    const [showSpeakerForm, toggleSpeakerForm] = useState(false);
    const [speakerId, setSpeakerId] = useState('');
    const [showTimezoneModal, setTimezoneModal] = useState(false);
    const [allCheckedSessions, setAllCheckedSessions] = useState({
        [activeDate]: [],
    });
    const [currentCheckedStatus, setCurrentCheckedStatus] = useState({
        checked: false,
        value: '',
    });
    const [changeFlag, setChangeFlag] = useState(true);
    const [pagination, setPagination] = useState({});
    const [isBulkDeleteDisable, setIsBulkDeleteDisable] = useState(false);

    const activeTimezone = useSelector(
        (state) => state?.settings?.activeTimezone || moment.tz.guess()
    );

    const user = useSelector((state) => state.user);

    const allLiveShowcases = useRef([]);

    useEffect(() => {
        return () => {
            dispatch(act.clearAllAgendaStates());
        };
    }, []);

    useEffect(() => {
        handleAgendaSessions();
    }, [activeTimezone]);

    useEffect(() => {
        if (!editSuccess && !addSpeakerSuccess && !addedDate) return;
        if (editSuccess) {
            getSessionDates('onedit');
            const getDate = selectedSession?.startTime
                ? moment(selectedSession?.startTime).format('YYYY-MM-DD')
                : addedDate;

            getSession(getDate, currentPage, currentLimit);
            setActiveDate(getDate);
            setEditSuccess(false);
        }
        if (addSpeakerSuccess) {
            getSpeakers();
        }
    }, [editSuccess, addSpeakerSuccess, addedDate]);

    useEffect(() => {
        if (!showForm) setSelectedSession({});
    }, [showForm]);

    useEffect(() => {
        if (!activeDate) return;
        const temp = allCheckedSessions[activeDate];
        if (currentCheckedStatus?.checked) {
            setAllCheckedSessions({
                ...allCheckedSessions,
                [activeDate]: [...(temp || []), currentCheckedStatus?.value],
            });
            setChangeFlag(!changeFlag);
        } else {
            setAllCheckedSessions({
                ...allCheckedSessions,
                [activeDate]: temp?.filter(
                    (id) => currentCheckedStatus?.value !== id
                ),
            });
            setChangeFlag(!changeFlag);
        }
    }, [currentCheckedStatus]);

    useEffect(() => {
        if (!allCheckedSessions[activeDate]?.length) return;
        const newobj = { ...allCheckedSessions };
        const uniqueItems = new Set(allCheckedSessions[activeDate]);
        newobj[activeDate] = Array.from(uniqueItems);
        setAllCheckedSessions(newobj);
    }, [changeFlag]);

    // remove date if there's no record
    useEffect(() => {
        if (!agendaSessions?.sessions?.length) {
            if (search) {
                getSessionDates('oninsert', search);
            } else {
                getSessionDates();
            }
        }
    }, [agendaSessions?.sessions]);

    useEffect(() => {
        if (currentTabs === 'sessions') {
            handleSearchChange(search);
        }
    }, [search]);

    const getSession = async (date, page, limit) => {
        try {
            setIsLoading(true);
            const currPage = page;
            const currLimit = limit;
            const response = await getAgendaSessions(
                projectId,
                user?.roleId === 4
                    ? showcaseId ||
                          allLiveShowcases.current
                              .map((showcase) => showcase.id)
                              .join(',')
                    : null,
                date,
                currLimit,
                currPage,
                search
            );
            if (response.status) {
                response.sessions.map((item) => {
                    if (isString(item.speakers)) {
                        item.speakers = JSON.parse(item.speakers);
                    }
                    if (isString(item.topicTags)) {
                        item.topicTags = JSON.parse(item.topicTags);
                    }
                    if (
                        item.logos &&
                        isString(item.logos) &&
                        item.logos !== 'null'
                    ) {
                        item.logos = JSON.parse(item.logos);
                    }
                });
                dispatch(act.getAgendaSessions(response));
                setPagination(response.pagination);
            }
        } catch (e) {
            console.error('ERROR getSession', e);
            showAlertBar('Fetching Session Failed', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (search) => {
        if (search === '') {
            getSessionDates();
        } else {
            getSessionDates('oninsert', search);
        }
    };

    const handleAgendaSessions = async () => {
        try {
            if (!showcaseId) {
                const { showcases } = await getLivePages(
                    projectId,
                    'title',
                    'asc',
                    20,
                    0,
                    '',
                    [],
                    user?.roleId === 4 ? ls.get('sponsorAuth') : '',
                    '',
                    1
                );
                if (showcases) {
                    allLiveShowcases.current = showcases;
                } else throw new Error('showcases not found');
            }

            // get sessions
            getSessionDates();
            // get speakers
            getSpeakers();
            // get tags
            getTags();
        } catch (error) {
            console.error(error);
        }
    };

    const getSessionDates = async (type = 'oninsert', search = '') => {
        try {
            setIsLoading(true);
            const response = await getAgendaSessionDates(
                projectId,
                user?.roleId === 4
                    ? showcaseId ||
                          allLiveShowcases.current
                              .map((showcase) => showcase.id)
                              .join(',')
                    : null,
                search
            );
            if (response.status) {
                dispatch(act.getAgendaSessionDates(response.dates));
                setSessionsDates(response.dates);
                if (type !== 'onedit' && response.dates?.length) {
                    setActiveDate(response.dates[0]);
                    getSession(response.dates[0]);
                }
            }
        } catch (e) {
            showAlertBar('Fetching Session Dates Failed', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const getSpeakers = async () => {
        try {
            const response = await getAgendaSpeakers(projectId);
            if (response.status) {
                dispatch(act.getAgendaSpeakers(response.speakers));
            }
        } catch (e) {
            showAlertBar('Fetching Speakers Failed', 'error');
        }
    };

    const getTags = async () => {
        try {
            const response = await getAgendaSessionTags(projectId);
            if (response.status) {
                dispatch(act.getAgendaSessionTags(response.topics));
            }
        } catch (e) {
            showAlertBar('Fetching Tags Failed', 'error');
        }
    };

    const handlePageChange = (page, limit) => {
        setCurrentPage(page);
        getSession(activeDate, page, limit);
    };

    const handleCurrentLimit = (limit) => {
        setCurrentLimit(limit);
        getSession(activeDate, 1, limit);
    };

    const handlePageCount = () => {
        return agendaSessions.pagination
            ? Math.ceil(agendaSessions.pagination.total / currentLimit)
            : 0;
    };

    const handleDeleteSession = async (id) => {
        try {
            const response = await deleteSession({
                id,
                projectId,
            });
            if (!response.status) throw response;
            setShowDeletePrompt(false);
            const temp = allCheckedSessions[activeDate];
            setAllCheckedSessions({
                ...allCheckedSessions,
                [activeDate]: temp?.filter((item) => item != id),
            });
            dispatch(act.deleteAgendaSession(id));
            showAlertBar(`Session successfully deleted.`, 'success');
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || `Failed to delete sessions.`,
            });
        }
    };

    const handleDuplicateSession = async (id) => {
        try {
            const response = await duplicateSession({
                id,
                projectId,
            });
            if (response.status) {
                setShowDuplicatePrompt(false);
                getSession(activeDate);
                openNotification('success', {
                    message: `Session successfully duplicated.`,
                });
            } else throw response;
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || `Failed to duplicate session.`,
            });
        }
    };

    const handleEachCheckBoxSelection = (event) => {
        const { checked, value } = event.target;
        setCurrentCheckedStatus({ checked, value });
    };

    const handleBulkDelete = async () => {
        try {
            const response = await bulkDeleteSession({
                projectId,
                sessionIds: allCheckedSessions[activeDate],
            });
            if (!response.status) throw response;
            setShowBulkDeletePrompt(false);
            setAllCheckedSessions({ [activeDate]: [] });
            getSession(activeDate, 1, currentLimit);
            openNotification('success', {
                message: response.message || `Session successfully duplicated.`,
            });
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || `Failed to delete sessions.`,
            });
        }
    };

    const editSpeaker = (sessionId, id) => {
        setSpeakerId(id);
        setSessionId(sessionId);
        toggleSpeakerForm(true);
    };

    const onSpeakerSaved = async (formDetails, prevImgSrc, id) => {
        getSessionDates();
        getSpeakers();
        getTags();
    };

    const handleActivedate = (date) => {
        // convert array to string
        setActiveDate(date);
        getSession(date);
    };

    const handlePreDelete = (id) => {
        setSessionId(id);
        setShowDeletePrompt(true);
    };

    const handlePreDuplicate = (id) => {
        setSessionId(id);
        setShowDuplicatePrompt(true);
    };

    const handleEditSession = (session) => {
        setSelectedSession(session);
        setFormTitle('Edit');
    };

    const handlePreBulkDelete = () => {
        if (allCheckedSessions[activeDate]?.length)
            setShowBulkDeletePrompt(true);
    };

    // Close prompt for delete
    const handleClosePrompt = (type) => {
        if (type === 'delete') {
            setShowDeletePrompt(false);
        }
        if (type === 'duplicate') {
            setShowDuplicatePrompt(false);
        }
        if (type === 'bulkDelete') {
            setShowBulkDeletePrompt(false);
        }
    };

    const renderSpeaker = (sessionId, speaker) => {
        return (
            <div
                onClick={() => editSpeaker(sessionId, speaker.id)}
                className="speaker"
                key={speaker.id}
            >
                {speaker.profilePhoto && speaker.profilePhoto !== 'null' ? (
                    <img
                        className="profile"
                        src={speaker.profilePhoto}
                        alt="speaker-profile"
                    />
                ) : (
                    <div className="speaker_profile">
                        <span>
                            {speaker.firstname?.substring(0, 1)}
                            {speaker.lastname?.substring(0, 1)}
                        </span>
                    </div>
                )}
                <div className="speaker-details">
                    <h5>
                        {speaker.firstname} {speaker.lastname}
                    </h5>
                    <span>
                        {`${speaker.jobtitle}${
                            speaker.jobtitle && speaker.company && ', '
                        } ${speaker.company}`}
                    </span>
                </div>
            </div>
        );
    };

    const renderOptions = () => {
        if (sessionsDates?.length) {
            return sessionsDates.map((date) => (
                <Option key={date} value={date}>
                    {moment(date).format('ddd, DD MMM')}
                </Option>
            ));
        }
    };

    const renderMobileView = () => {
        return (
            <div className="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-w-full md:tw-hidden">
                {/* Dates  */}
                <div className="tw-w-full">
                    <Select
                        showSearch
                        className="selectMobileView tw-text-base tw-font-semibold tw-text-mobileTextBlack"
                        allowClear={false}
                        value={activeDate}
                        onChange={(val) => handleActivedate(val)}
                        style={{ width: '100%' }}
                    >
                        {renderOptions()}
                    </Select>
                </div>
                <style>{`
                @media screen and (max-width: 780px) {
            .selectMobileView .ant-select-selection-item {
                display: flex !important;
                align-items: center !important;
            }
            .selectMobileView .ant-select-selector {
                border-radius: 9px !important;
                height: 44px !important;
            }
        }
            `}</style>

                <div className="tw-w-full tw-block md:tw-hidden">
                    <TimezoneLabel
                        tZstyle={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            fontSize: '13px ',
                            fontWeight: '600',
                            color: '#344054',
                        }}
                        setIsModalVisible={setTimezoneModal}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="session-container tw-p-4 tw-pt-0 tw-bg-white sm:tw-bg-transparent tw-mt-0 md:tw-pt-7 tw-h-[70vh] sm:tw-h-auto tw-overflow-auto">
            <div
                onClick={() => {
                    toggleForm(true);
                    setFormTitle('Create');
                }}
                className="addSession tw-hidden md:tw-flex tw-mb-5 tw-shadow-md"
            >
                <img src="/assets/icon_plus.svg" alt="add session" /> {` `}
                Create agenda session
            </div>

            <div className="session-lists tw-mt-0">
                <div className="session-dates tw-hidden md:tw-flex">
                    {sessionsDates?.length > 0 &&
                        sessionsDates
                            .slice()
                            .sort((a, b) => b - a)
                            .map((date) => {
                                return (
                                    <span
                                        onClick={() => handleActivedate(date)}
                                        className={`${
                                            date.includes(activeDate)
                                                ? 'active'
                                                : ''
                                        }`}
                                        key={date}
                                    >
                                        {moment(date).format('ddd, DD MMM')}
                                    </span>
                                );
                            })}
                </div>
                {/* {timezone &&
                    !isLoading &&
                    agendaSessions?.sessions?.length > 0 && (
                        <TimezoneLabel
                            tZstyle={{ textAlign: 'right' }}
                            allowChange={false}
                        />
                    )} */}
                <div className="tw-hidden md:tw-block">
                    <TimezoneLabel
                        tZstyle={{ textAlign: 'right' }}
                        setIsModalVisible={setTimezoneModal}
                    />
                </div>
                {renderMobileView()}
                <div
                    className={`session ${
                        sessionsDates?.length > 0 ? 'has-item' : ''
                    } ${
                        !agendaSessions?.sessions?.length &&
                        screenType === 'mobile' &&
                        'tw-hidden'
                    }`}
                >
                    {isLoading && (
                        <BoxPreloader noOfBoxes={5} hasTitle={false} />
                    )}
                    <DisplayTimezoneSelect
                        isModalVisible={showTimezoneModal}
                        setIsModalVisible={setTimezoneModal}
                    />
                    {agendaSessions?.sessions?.length &&
                    sessionsDates?.length ? (
                        <div className="session-item tw-gap-2 tw-flex-col">
                            <div className="tw-w-full tw-flex tw-flex-col ">
                                <div
                                    className={`tw-w-full tw-flex tw-gap-2 tw-justify-start tw-items-center`}
                                >
                                    <Popover
                                        content={
                                            isBulkDeleteDisable
                                                ? 'Selected sessions cannot be deleted.'
                                                : `Delete selected sessions (${
                                                      allCheckedSessions[
                                                          activeDate
                                                      ]?.length || 0
                                                  })`
                                        }
                                    >
                                        <span
                                            className={`${
                                                allCheckedSessions[activeDate]
                                                    ?.length &&
                                                !isBulkDeleteDisable
                                                    ? 'tw-cursor-pointer'
                                                    : 'tw-opacity-50 tw-cursor-default'
                                            } tw-flex tw-gap-2 tw-justify-start tw-items-center tw-text-blue-text tw-text-sm tw-font-semibold`}
                                            onClick={
                                                isBulkDeleteDisable
                                                    ? () => {}
                                                    : handlePreBulkDelete
                                            }
                                        >
                                            <DeleteIconSession />
                                            Delete
                                        </span>
                                    </Popover>
                                    {allCheckedSessions[activeDate]?.length >
                                        0 && (
                                        <Tag
                                            closable
                                            className="tw-flex tw-items-center"
                                            closeIcon={<CloseOutlined />}
                                            onClose={() => {
                                                setAllCheckedSessions({
                                                    ...allCheckedSessions,
                                                    [activeDate]: [],
                                                });
                                            }}
                                        >
                                            <span className="tw-text-sm">
                                                <strong>
                                                    {
                                                        allCheckedSessions[
                                                            activeDate
                                                        ].length
                                                    }{' '}
                                                    session
                                                    {allCheckedSessions[
                                                        activeDate
                                                    ].length > 1
                                                        ? 's '
                                                        : ' '}
                                                </strong>
                                                have been selected
                                            </span>
                                        </Tag>
                                    )}
                                </div>
                                <hr className="tw-w-full tw-bg-divider-gray " />
                            </div>
                            <Checkbox.Group
                                className="tw-gap-2 tw-flex tw-flex-col"
                                value={allCheckedSessions[activeDate]}
                            >
                                {!isLoading &&
                                    agendaSessions?.sessions?.length > 0 &&
                                    [...agendaSessions?.sessions]
                                        ?.sort(
                                            (a, b) =>
                                                moment(a?.startTime)
                                                    ?.tz(activeTimezone)
                                                    ?.format('Hmm') -
                                                moment(b?.startTime)
                                                    ?.tz(activeTimezone)
                                                    ?.format('Hmm')
                                        )
                                        ?.map((session) => {
                                            const timeStart = moment(
                                                session?.startTime
                                            )
                                                .tz(activeTimezone)
                                                .format('hh:mma');
                                            const timeEnd = moment(
                                                session?.endTime
                                            )
                                                .tz(activeTimezone)
                                                .format('hh:mma');

                                            const diffInDays =
                                                moment(session?.endTime)
                                                    .tz(activeTimezone)
                                                    .get('days') !==
                                                moment(session?.startTime)
                                                    .tz(activeTimezone)
                                                    .get('days');

                                            const speakers =
                                                session?.speakers.filter(
                                                    (s) =>
                                                        s
                                                            ?.LivestreamSessionSpeaker
                                                            ?.role === 'speaker'
                                                );
                                            const moderators =
                                                session?.speakers.filter(
                                                    (s) =>
                                                        s
                                                            ?.LivestreamSessionSpeaker
                                                            ?.role ===
                                                        'moderator'
                                                );
                                            const panelists =
                                                session?.speakers.filter(
                                                    (s) =>
                                                        s
                                                            ?.LivestreamSessionSpeaker
                                                            ?.role ===
                                                        'panelist'
                                                );

                                            return (
                                                <div
                                                    className="session-item row"
                                                    key={session.id}
                                                >
                                                    <div className="left tw-flex tw-gap-2 tw-pl-0 col-sm-3">
                                                        {session.createdBySponsor && (
                                                            <Checkbox
                                                                value={
                                                                    session.id
                                                                }
                                                                onClick={
                                                                    handleEachCheckBoxSelection
                                                                }
                                                            ></Checkbox>
                                                        )}
                                                        <div className="tw-flex tw-flex-col tw-gap-2 ">
                                                            <span className="d-block">
                                                                {timeStart}
                                                                {` - `}
                                                                {timeEnd}
                                                                <span
                                                                    className={`extraday-text ${
                                                                        !diffInDays
                                                                            ? 'hidden'
                                                                            : ''
                                                                    }`}
                                                                >{` +1 DAY`}</span>
                                                            </span>
                                                            <div className="logo tw-flex tw-gap-2 tw-flex-wrap tw-justify-center">
                                                                {isArray(
                                                                    session.logos
                                                                ) &&
                                                                    session.logos.map(
                                                                        (
                                                                            logo
                                                                        ) => {
                                                                            return (
                                                                                <img
                                                                                    style={{
                                                                                        maxHeight: 75,
                                                                                    }}
                                                                                    className="img-fluid mt-2"
                                                                                    src={
                                                                                        logo
                                                                                    }
                                                                                    key={
                                                                                        logo
                                                                                    }
                                                                                    alt="session-logo"
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                            </div>
                                                        </div>

                                                        {/* {!isEmpty(session.logo) && session.logo !== 'null' &&
                                                <img style={{ maxHeight: 75 }} className="img-fluid mt-3" src={session.logo} />
                                            } */}
                                                    </div>
                                                    <div className="right col-sm-9">
                                                        <div className="header">
                                                            <h2>
                                                                {session.name}
                                                            </h2>
                                                            <div className="actions">
                                                                <AntdDropdown
                                                                    overlay={
                                                                        <Menu>
                                                                            {session.createdBySponsor && (
                                                                                <>
                                                                                    <Menu.Item
                                                                                        key={`${session.id}delete`}
                                                                                        onClick={() =>
                                                                                            handlePreDelete(
                                                                                                session.id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Delete
                                                                                    </Menu.Item>
                                                                                    <Menu.Item
                                                                                        key={`${session.id}edit`}
                                                                                        onClick={() => {
                                                                                            handleEditSession(
                                                                                                session
                                                                                            );
                                                                                            toggleForm(
                                                                                                true
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Edit
                                                                                    </Menu.Item>
                                                                                </>
                                                                            )}
                                                                            <Menu.Item
                                                                                key={`${session.id}duplicate`}
                                                                                onClick={() =>
                                                                                    handlePreDuplicate(
                                                                                        session.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                Duplicate
                                                                            </Menu.Item>
                                                                        </Menu>
                                                                    }
                                                                >
                                                                    <MoreOutlined
                                                                        style={{
                                                                            fontSize:
                                                                                '17px',
                                                                            color: 'black',
                                                                        }}
                                                                    />
                                                                </AntdDropdown>
                                                            </div>
                                                        </div>
                                                        <div className="tags">
                                                            {session?.topicTags.map(
                                                                (tag) => {
                                                                    return (
                                                                        <span
                                                                            key={
                                                                                tag.id
                                                                            }
                                                                        >
                                                                            {
                                                                                tag?.name
                                                                            }
                                                                        </span>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                        <div
                                                            className="description tw-break-words"
                                                            dangerouslySetInnerHTML={{
                                                                __html: session.description,
                                                            }}
                                                        />
                                                        {session?.speakers
                                                            ?.length > 0 && (
                                                            <div className="speakers-container">
                                                                {speakers?.length >
                                                                    0 && (
                                                                    <h4>
                                                                        Speaker
                                                                        {speakers.length >
                                                                        1
                                                                            ? 's'
                                                                            : ''}
                                                                    </h4>
                                                                )}
                                                                <div className="speakers">
                                                                    {speakers.map(
                                                                        (
                                                                            speaker
                                                                        ) => {
                                                                            return renderSpeaker(
                                                                                session.id,
                                                                                speaker
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                                {moderators?.length >
                                                                    0 && (
                                                                    <h4>
                                                                        Moderator
                                                                        {moderators.length >
                                                                        1
                                                                            ? 's'
                                                                            : ''}
                                                                    </h4>
                                                                )}
                                                                <div className="speakers">
                                                                    {moderators.map(
                                                                        (
                                                                            speaker
                                                                        ) => {
                                                                            return renderSpeaker(
                                                                                session.id,
                                                                                speaker
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                                {panelists?.length >
                                                                    0 && (
                                                                    <h4>
                                                                        Panelist
                                                                        {panelists.length >
                                                                        1
                                                                            ? 's'
                                                                            : ''}
                                                                    </h4>
                                                                )}
                                                                <div className="speakers">
                                                                    {panelists.map(
                                                                        (
                                                                            speaker
                                                                        ) => {
                                                                            return renderSpeaker(
                                                                                session.id,
                                                                                speaker
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                            </Checkbox.Group>
                        </div>
                    ) : null}

                    {agendaSessions?.pagination?.totalPage > 1 &&
                        sessionsDates?.length > 0 && (
                            <div className=" align-items-center d-flex justify-content-center  mt-3">
                                <Pagination
                                    total={pagination?.total}
                                    current={pagination?.currentPage || 1}
                                    pageSize={pagination?.limit || 10}
                                    size="medium"
                                    showSizeChanger={true}
                                    onChange={(page, limit) =>
                                        handlePageChange(page, limit)
                                    }
                                />
                            </div>
                        )}
                </div>
            </div>

            {!isLoading &&
                search &&
                (!sessionsDates || sessionsDates?.length === 0) && (
                    <div className="no-record">
                        <h2>No result for "{search}"</h2>
                    </div>
                )}
            {!isLoading &&
                (!sessionsDates || sessionsDates?.length === 0) &&
                !search && (
                    <div className="no-record">
                        <h2>You haven’t created any session yet</h2>
                        <p>
                            Get started by clicking on the “Create agenda
                            session” button
                        </p>
                    </div>
                )}

            {/* Delete confirmation */}
            <Prompt
                show={showDeletePrompt}
                message="Are you sure you want to delete this session?"
                // description={`Attendees will no longer be able to access this`}
                buttons={['Cancel', 'Proceed to Delete']}
                onHide={() => handleClosePrompt('delete')}
                callback={() => handleDeleteSession(sessionId)}
            />
            <Prompt
                show={showDuplicatePrompt}
                message="Are you sure you want to duplicate this session?"
                buttons={['Cancel', 'Proceed to duplicate']}
                onHide={() => handleClosePrompt('duplicate')}
                callback={() => handleDuplicateSession(sessionId)}
            />

            <Prompt
                show={showBulkDeletePrompt}
                title="Delete sessions"
                message="Are you sure you want to delete the selected item(s)? This action cannot be undone."
                buttons={['Cancel', 'Delete']}
                onHide={() => handleClosePrompt('bulkDelete')}
                callback={() => handleBulkDelete()}
            />

            <CreateSession
                showForm={showForm}
                toggleForm={toggleForm}
                formAction={formTitle}
                selectedSession={selectedSession}
                setEditSuccess={setEditSuccess}
                setAddSpeakerSuccess={setAddSpeakerSuccess}
                setAddedDate={setAddedDate}
            />

            <SpeakerForm
                showForm={showSpeakerForm}
                toggleForm={toggleSpeakerForm}
                speakerId={speakerId}
                setSpeakerId={setSpeakerId}
                showAlertBar={showAlertBar}
                showBlanket={false}
                onSave={onSpeakerSaved}
                zIndex={100}
            />
        </div>
    );
}

export default Sessions;
