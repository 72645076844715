import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';

let authBearer = null;

const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            const auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export async function ImportSession(data, projectId) {
    setAuthBearer();

    const formData = new FormData();
    formData.append('file', data);

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/sessions/import?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                Authorization: authBearer,
            },
            body: formData,
        }
    );

    return await response.json();
}
