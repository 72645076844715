/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import useAlertBar from 'components/app/utils/hooks/useAlertBar';
import { Dropdown } from 'react-bootstrap';
import validator from 'validator';
import {
    getAgendaSessionTags,
    createAgendaSessions,
    updateAgendaSession,
} from 'apis/rest/AgendaSessions';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import FroalaEditor from 'react-froala-wysiwyg';
import { useSelector } from 'react-redux';
import ImageUpload from 'components/app/common/imageUpload';
import StreamingService from 'Apps/VenueBuilder/Components/Common/StreamingService';

import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';
import toInteger from 'lodash/toInteger';
import { getLocations } from 'apis/rest/agenda/locations';
import { getLivePages } from 'apis/rest/livestream/GetLivePages';
import {
    Select,
    Spin,
    Radio,
    Space,
    Tooltip,
    Checkbox,
    Tag,
    DatePicker,
    TimePicker,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { GetTopicTagGroups } from 'apis/rest/tags/GetTopicTagGroups';
import SearchSelectInput from 'components/app/common/searchSelectInput';
import { TimezoneLabel } from 'components/app/common/displayTimezoneSelect';
import StreamingServiceInput from 'components/app/common/streamingServiceInputs';
import sanitizeContent from 'utils/InputSanitization';
import FormSlider from '../../utility/FormSlider';
import SpeakerForm from '../../speakers/speakerForm';
import './createSession.scss';

const { Option } = Select;

const dateFormatForSessionSubmission = 'YYYY-MM-DD';
const MAX_LOGO_IMAGES = 10;

const LivePageSelect = ({
    projectId,
    setLivePageId,
    livePageId,
    selectedLivePageId,
    setIsSubmitted,
}) => {
    const [srchdLivePages, setSrchdLivePages] = useState(null);
    const [srchVal, setSrchVal] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [livePagesList, setLivePageList] = useState([]);

    useEffect(() => {
        getPages();
    }, []);

    useEffect(() => {
        if (srchVal) {
            handleSearch();
        } else setSrchdLivePages(null);
    }, [srchVal]);

    const getPages = async (type = 'firstLoad') => {
        try {
            setLoading(true);
            const { showcases } = await getLivePages(
                projectId,
                'title',
                'asc',
                20,
                0,
                srchVal,
                [],
                '',
                1,
                '',
                ''
            );
            if (!showcases || showcases.length === 0) throw new Error('failed');

            type === 'firstLoad'
                ? setLivePageList(showcases)
                : setSrchdLivePages(showcases);
            setLoading(false);
        } catch (error) {
            type === 'firstLoad' ? setLivePageList([]) : setSrchdLivePages([]);
            setLoading(false);
        }
    };

    const handleSearch = debounce(async () => {
        getPages('search');
    }, 100);

    const optionsData = srchdLivePages
        ? srchdLivePages.map(({ id, title }) => (
              <Option value={id} key={id}>
                  {title}
              </Option>
          ))
        : livePagesList.map(({ id, title }) => (
              <Option value={id} key={id}>
                  {title}
              </Option>
          ));

    return (
        <Select
            style={{ width: '100%' }}
            placeholder="Select Page"
            onSearch={setSrchVal}
            value={livePageId || null}
            defaultValue={livePageId || null}
            onChange={(pageId) => {
                setLivePageId(pageId);
                setIsSubmitted(false);
            }}
            searchValue={srchVal}
            showArrow={false}
            showSearch
            filterOption={false}
            notFoundContent={isLoading ? <Spin size="small" /> : null}
        >
            {optionsData}
        </Select>
    );
};

const SortableContainer = sortableContainer(({ children }) => {
    return <tbody className="selected-speakers">{children}</tbody>;
});

const SortableItem = sortableElement(
    ({ value, sortIndex, removeSpeaker, handleSpeakerRole }) => {
        return (
            <tr className="speaker-d">
                <td className="details tw-flex">
                    {value.profilePhoto && value.profilePhoto !== 'null' ? (
                        <img
                            alt="profile"
                            className="profile"
                            src={value.profilePhoto}
                        />
                    ) : (
                        <div className="speaker_profile">
                            <span className="name">
                                {value.firstname?.substring(0, 1)}
                                {value.lastname?.substring(0, 1)}
                            </span>
                        </div>
                    )}
                    <span className="tw-w-[48px] md:tw-w-[280px]">
                        {value.firstname}
                        {` `}
                        {value.lastname}
                    </span>
                </td>
                <td className="speaker-action">
                    <div className="speaker-role">
                        <span>Role</span>
                        <select
                            onChange={(e) =>
                                handleSpeakerRole(e, value.id, sortIndex)
                            }
                            value={value?.LivestreamSessionSpeaker?.role}
                        >
                            <option value="speaker">Speaker</option>
                            <option value="moderator">Moderator</option>
                            <option value="panelist">Panelist</option>
                        </select>
                    </div>
                    <img
                        onClick={() => removeSpeaker(value.id)}
                        src="assets/icon_close_light.svg"
                        className="remove-speaker"
                        alt="remove-tag"
                    />
                    <img
                        src="/assets/icon_draggable.svg"
                        className="drag-speaker"
                        alt="remove-tag"
                    />
                </td>
            </tr>
        );
    }
);

const iconStyle = {
    marginRight: '5px',
};

const CalendarIcon = () => (
    <img
        style={iconStyle}
        src="/assets/products/xpmanager/iconsv2/calendar.svg"
        alt=""
    />
);

const ClockIcon = () => (
    <img
        style={iconStyle}
        src="/assets/products/xpmanager/iconsv2/clock.svg"
        alt=""
    />
);

function CreateSession({
    showForm,
    toggleForm,
    formAction,
    selectedSession,
    setEditSuccess,
    setAddSpeakerSuccess,
    setAddedDate,
}) {
    const { projectId, showcaseId } = useParams();
    const user = useSelector((state) => state.user);
    const sponsorEditInputsRef = useRef();

    const tagsdata = useSelector((state) => state.pages.sessionTags);
    const speakersdata = useSelector((state) => state.pages.agendaSpeakers);
    const activeTimezone = useSelector(
        (state) => state?.settings?.activeTimezone || moment.tz.guess()
    );

    const { showAlertBar } = useAlertBar();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedSpeakers, setSelectedSpeakers] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showSpeakerForm, toggleSpeakerForm] = useState(false);
    const [speakerSearch, setSpeakerSearch] = useState(null);
    const [speakers, setSpeakers] = useState(speakersdata);
    const [tags, setTags] = useState(tagsdata);
    const [tagGroupsPaginationData, setTagGroupsPaginationData] = useState({});
    const [tagsPaginationData, setTagsPaginationData] = useState({});
    const [logoList, setLogoList] = useState([]);
    const [visibleImageComponent, setVisibleImageComponent] = useState(false);
    const [livePageId, setLivePageId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [locationData, setLocationData] = useState(null);

    const [ctaType, setCtaType] = useState('none');

    const [ctaStreamingService, setCtaStreamingService] = useState('');
    const [ctaStreamingServiceUrl, setCtaStreamingServiceUrl] = useState('');
    const [ctaStreamingDefaultWatch, setCtaStreamingDefaultWatch] = useState(0);
    const [ctaStreamingDefaultLogo, setCtaStreamingDefaultLogo] =
        useState(null);
    const [ctaStreamingChannelId, setCtaStreamingChannelId] = useState('');
    const [ctaDisableAutoPlay, setCtaDisableAutoPlay] = useState(true);
    const [ctaDisableVideoQuality, setCtaDisableVideoQuality] = useState(false);
    const [ctaStreamingServiceText, setCtaStreamingServiceText] =
        useState('Watch Video');

    // ctaType default, custom and none
    // default ctaDefaultText and ctaVdoText
    // custom ctaDefaultText
    const [ctaVdoText, setCtaVdoText] = useState('Replay');
    const [ctaDefaultText, setCtaDefaultText] = useState('Join');

    // ctaLink if type if not none, use this
    const [ctaLink, setCtaLink] = useState('');
    const [ctaLinkCustom, setCtaLinkCustom] = useState('Join');
    const [ctaDefaultTextCustom, setCtaDefaultTextCustom] = useState('Join');

    const [openCtaInNewWindow, setCustomOpenNewWindow] = useState(true);

    const [openDefaultCtaInNewWindow, setDefaultOpenNewWindow] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleGetLocations = async (search = '') => {
        try {
            const response = await getLocations(projectId, 1, search);
            if (response.status) {
                const filterListLocations = [];

                response.physicalVenues.map((location) =>
                    filterListLocations.push({
                        label: `${location?.name}`,
                        value: location?.id,
                    })
                );

                return filterListLocations;
            }
        } catch (error) {
            console.error('ERROR handleGetCompanies', error);
        }
    };

    const getDisabledHours = (type) => {
        const hoursToDisable = [];
        // for start date
        if (type === 'startDate') {
            if (!startDate) return hoursToDisable;
            const isStartDateToday = moment(startDate)
                .tz(activeTimezone)
                .isSame(moment().tz(activeTimezone), 'day');

            if (!isStartDateToday) return hoursToDisable;
            const hourThreshold = +moment().tz(activeTimezone).hour();
            if (!hourThreshold) return [];
            for (let i = 0; i < hourThreshold; i += 1) {
                hoursToDisable.push(i);
            }
            return hoursToDisable;
        }
        // for end date
        if (!startDate || !endDate) return hoursToDisable;

        const isSameDate = moment(startDate)
            .tz(activeTimezone)
            .isSame(moment(endDate).tz(activeTimezone), 'day');

        if (isSameDate) {
            const hourThreshold = +moment(startDate).tz(activeTimezone).hour();
            if (!hourThreshold) return [];
            for (let i = 0; i < hourThreshold; i += 1) {
                hoursToDisable.push(i);
            }
            return hoursToDisable;
        }
        return hoursToDisable;
    };

    const handleDateTimeChange = (inputDate, type, refDate) => {
        let valueToReturn = '';
        if (!refDate) {
            valueToReturn = moment.tz(inputDate, activeTimezone);
        } else if (type === 'date') {
            valueToReturn = moment.tz(refDate, activeTimezone).set({
                year: inputDate.get('year'),
                month: inputDate.get('month'),
                date: inputDate.get('date'),
                hour: inputDate.get('hour'),
                minute: inputDate.get('minute'),
            });
        } else if (type === 'time') {
            valueToReturn = moment.tz(refDate, activeTimezone).set({
                hour: inputDate.get('hour'),
                minute: inputDate.get('minute'),
            });
        }
        return valueToReturn;
    };

    const handleStartDateTimeChange = (date, type) => {
        setStartDate(handleDateTimeChange(date, type, startDate));
    };

    const handleEndDateTimeChange = (date, type) => {
        setEndDate(handleDateTimeChange(date, type, endDate));
        setEndTime('filled');
    };

    const handleGetTopicTagGroups = async (page = 1) => {
        try {
            const response = await GetTopicTagGroups(projectId, '', page);
            if (response.status && response?.pagination?.totalPage !== 0) {
                const topicsTagsList = [...tags];
                setTagGroupsPaginationData(response?.pagination);
                const resultData = response?.topics;
                if (resultData) {
                    resultData.map((group) => {
                        topicsTagsList.push({
                            label: group?.name,
                            type: 'group',
                            options: group?.topicTags.map((tag) => ({
                                value: tag?.id,
                                label: tag?.name,
                                key: tag?.id,
                            })),
                        });
                    });
                }

                setTags(topicsTagsList);
            }

            if (isEmpty(response?.topics)) {
                handleGetTopics();
            }
        } catch (error) {
            console.error('ERROR handleGetTopicTagGroups', error);
        }
    };

    const handleGetTopics = async (page = 1) => {
        try {
            const responseWithOutGroup = await getAgendaSessionTags(
                projectId,
                '',
                true,
                page
            );

            const paginationObject = responseWithOutGroup?.pagination;
            const currentPage = toInteger(paginationObject.currentPage);

            if (responseWithOutGroup.status) {
                let topicsTagsList = [...tags];
                setTagsPaginationData({
                    ...paginationObject,
                    currentPage,
                });

                const topicTagsListObject =
                    topicsTagsList?.find(
                        (tagList) => tagList.type === 'topic'
                    ) || {};

                if (isEmpty(responseWithOutGroup?.topics)) {
                    setIsDisabled(true);
                }

                if (!isEmpty(responseWithOutGroup?.topics)) {
                    if (isEmpty(topicTagsListObject)) {
                        topicsTagsList.push({
                            label: 'Tags that do not belong to group',
                            type: 'topic',
                            options: responseWithOutGroup?.topics.map(
                                (tag) => ({
                                    value: tag?.id,
                                    label: tag?.name,
                                    key: tag?.id,
                                })
                            ),
                        });
                    } else {
                        topicsTagsList = topicsTagsList?.map((tagList) => {
                            if (tagList?.type === 'topic') {
                                const updatedResponseOptions =
                                    responseWithOutGroup?.topics.map((tag) => ({
                                        value: tag?.id,
                                        label: tag?.name,
                                        key: tag?.id,
                                    }));

                                const options = [
                                    ...tagList.options,
                                    ...updatedResponseOptions,
                                ];

                                return { ...tagList, options };
                            }
                            return tagList;
                        });
                    }
                }

                setTags(topicsTagsList);
            }
        } catch (error) {
            console.error('ERROR handleGetTopics', error);
        }
    };

    useEffect(() => {
        handleGetTopicTagGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isEditorContentEmpty = (content) => {
        // Remove HTML tags and trim whitespace
        const textContent = content
            .replace(/<[^>]*>/g, '') // Remove HTML tags
            .replace(/&nbsp;/g, ' ') // Replace &nbsp; with space
            .replace(/\s\s+/g, ' ') // Replace multiple spaces with single space
            .trim();
        return textContent === '';
    };

    const handleModelChange = (model) => {
        if (isEditorContentEmpty(model)) {
            setDescription('');
            return;
        }
        setDescription(sanitizeContent({ str: model, type: 'bio' }));
        setIsSubmitted(false);
    };

    const handleTitleChange = (value) => {
        if (isEditorContentEmpty(value)) {
            setTitle('');
            return;
        }
        setTitle(value);
    };

    useEffect(() => {
        if (logoList?.length > MAX_LOGO_IMAGES) {
            setLogoList(logoList?.slice(0, MAX_LOGO_IMAGES));
            showAlertBar(`Maximum of ${MAX_LOGO_IMAGES} Logos allowed`, 'info');
        }
    }, [logoList]);

    useEffect(() => {
        if (speakerSearch !== null && speakerSearch.length > 0) {
            setSpeakers(
                speakersdata.filter(
                    (s) =>
                        s.firstname
                            .toLowerCase()
                            .includes(speakerSearch.toLowerCase()) ||
                        s.lastname
                            .toLowerCase()
                            .includes(speakerSearch.toLowerCase())
                )
            );
        } else {
            setSpeakers(speakersdata);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [speakerSearch]);

    useEffect(() => {
        if (speakersdata?.length > 0) {
            setSpeakers(speakersdata);
        }
    }, [speakersdata]);

    // useEffect(() => {
    //     if (tagSearch !== null && tagSearch.length > 0) {
    //         setTags(
    //             tags.filter((s) => s.name.toLowerCase().includes(tagSearch))
    //         );
    //     } else {
    //         setTags(tagsdata);
    //     }
    // }, [tagSearch]);

    const onSpeakerSaved = () => {
        setAddSpeakerSuccess(true);
    };

    // const removeSelectedTag = (id) => {
    //     setSelectedTags(selectedTags.filter((tag) => tag && tag.id !== id));
    // };

    // const handleSelectedTag = (item) => {
    //     const tag = selectedTags.filter((tag) => tag && tag.id === item.id);
    //     setIsSubmitted(false);
    //     if (!tag.length) {
    //         setSelectedTags([
    //             ...selectedTags,
    //             { id: item.id, name: item.name },
    //         ]);
    //     }
    // };

    const handleSelectedSpeaker = (item) => {
        const speaker = selectedSpeakers.filter(
            (tag) => tag && tag.key === item.id
        );
        // setIsSubmitted(false);
        if (!speaker.length) {
            setSelectedSpeakers([
                ...selectedSpeakers,
                {
                    ...item,
                    LivestreamSessionSpeaker: {
                        role: 'speaker',
                    },
                },
            ]);
        }
    };

    const handleSpeakerRole = (e, id, index) => {
        const updateSpeakerRole = [...selectedSpeakers];
        updateSpeakerRole[index] = {
            ...selectedSpeakers[index],
            LivestreamSessionSpeaker: {
                role: e.target.value,
            },
        };
        setSelectedSpeakers(updateSpeakerRole);
    };

    const removeSpeaker = (id) => {
        setSelectedSpeakers(selectedSpeakers.filter((s) => s && s.id !== id));
    };

    const filteredSpeakers = useMemo(
        () =>
            speakers.filter(
                (speaker) =>
                    !selectedSpeakers.some(
                        (selectedOnces) => selectedOnces.id === speaker.id
                    )
            ),
        [speakers, selectedSpeakers]
    );

    useEffect(() => {
        if (formAction === 'Edit' && showForm) {
            const topicsTagsList = [];

            if (!isEmpty(selectedSession.topicTags)) {
                selectedSession.topicTags.forEach((tag) => {
                    topicsTagsList.push({
                        value: tag?.id,
                        label: tag?.name,
                        key: tag?.id,
                    });
                });
            }

            setTitle(selectedSession.name);
            setLogoList(
                isEmpty(selectedSession.logos) ? [] : selectedSession.logos
            );
            setDescription(
                sanitizeContent({
                    str: selectedSession.description,
                    type: 'bio',
                })
            );
            setStartDate(selectedSession.startTime);
            setStartTime(selectedSession.startTime);
            setEndDate(selectedSession.endTime);
            setEndTime(selectedSession.endTime);
            setSelectedTags(topicsTagsList);
            setSelectedSpeakers(selectedSession.speakers);
            setLocationId(
                selectedSession?.physicalVenue?.id
                    ? selectedSession?.physicalVenue?.id
                    : ''
            );
            setLivePageId(selectedSession?.showcaseId);
            setCtaStreamingService(
                selectedSession.ctaStreamingService
                    ? selectedSession.ctaStreamingService
                    : ''
            );
            setCtaStreamingServiceUrl(
                selectedSession.ctaStreamingServiceUrl
                    ? selectedSession.ctaStreamingServiceUrl
                    : ''
            );
            setCtaStreamingChannelId(
                selectedSession.ctaStreamingChannelId
                    ? selectedSession.ctaStreamingChannelId
                    : ''
            );
            setCtaStreamingDefaultLogo(
                selectedSession.ctaStreamingDefaultLogo
                    ? selectedSession.ctaStreamingDefaultLogo
                    : null
            );
            setCtaDisableAutoPlay(selectedSession?.ctaDisableAutoPlay);
            setCtaDisableVideoQuality(selectedSession?.ctaDisableVideoQuality);
            setCtaStreamingServiceText(
                typeof selectedSession.ctaStreamingServiceText === 'string'
                    ? selectedSession.ctaStreamingServiceText
                    : 'Watch Video'
            );

            if (!isEmpty(user?.exhibitorCompanyProfileId)) {
                if (!selectedSession?.physicalVenue?.showForSponsor) {
                    setLocationData(
                        selectedSession?.physicalVenue
                            ? {
                                  label: `${selectedSession?.physicalVenue?.name}`,
                                  value: selectedSession?.physicalVenue?.id,
                              }
                            : null
                    );
                }
            }

            setCtaType(selectedSession.ctaType);
            if (selectedSession.ctaType === 'default') {
                setCtaDefaultText(
                    typeof selectedSession?.ctaDefaultText === 'string'
                        ? selectedSession?.ctaDefaultText
                        : 'Join'
                );
                setCtaVdoText(
                    typeof selectedSession?.ctaVdoText === 'string'
                        ? selectedSession?.ctaVdoText
                        : 'Replay'
                );
                setCtaLink(selectedSession?.ctaLink);

                setDefaultOpenNewWindow(
                    selectedSession?.openDefaultCtaInNewWindow
                ); // default open new cta
            }

            if (selectedSession.ctaType === 'custom') {
                setCtaDefaultTextCustom(selectedSession.ctaDefaultText);
                setCustomOpenNewWindow(selectedSession.openCtaInNewWindow);

                setCtaLinkCustom(selectedSession?.ctaLink);
            }
        }

        if (!showForm) {
            setVisibleImageComponent(false);
        } else {
            setVisibleImageComponent(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formAction, showForm]);

    useEffect(() => {
        if (!showForm) {
            clearFields();
        } else if (!selectedSession.startTime) {
            setStartDate(new Date(moment.tz(activeTimezone).format()));
            setStartTime(new Date(moment.tz(activeTimezone).format()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showForm]);

    const handleSubmit = async (e, type) => {
        e.preventDefault();

        try {
            const streamingServiceInputsValidate =
                await sponsorEditInputsRef?.current?.handleSubmitForm();

            const ctaLinkCustomChecker =
                ctaType === 'custom'
                    ? validator.isURL(ctaLinkCustom)
                    : ctaType === 'default'
                    ? ctaLink.length > 0
                        ? validator.isURL(ctaLink)
                        : true
                    : true;

            const ctaLinkText =
                ctaType === 'custom'
                    ? ctaDefaultTextCustom
                    : ctaType === 'default'
                    ? ctaDefaultText
                    : true;

            if (
                title &&
                startDate &&
                endDate &&
                endTime &&
                livePageId &&
                ctaLinkCustomChecker &&
                ctaLinkText &&
                logoList?.length <= MAX_LOGO_IMAGES &&
                streamingServiceInputsValidate

                // selectedSpeakers.length > 0
            ) {
                const durationOfSessionInMinutes = Math.ceil(
                    moment(endDate).diff(
                        moment(startDate),
                        'minutes',
                        true // diff will be in float instead of truncated number
                    )
                );
                if (durationOfSessionInMinutes < 0) {
                    showAlertBar(
                        'Invalid session timings. Check end date/time',
                        'error'
                    );
                    return null;
                }
                if (durationOfSessionInMinutes < 5) {
                    showAlertBar(
                        'Session duration should be of atleast 5 minutes',
                        'error'
                    );
                    return null;
                }
                if (durationOfSessionInMinutes / 60 > 24) {
                    showAlertBar(
                        'Session duration should be less than 24 hours',
                        'error'
                    );
                    return null;
                }
                const speakersList = selectedSpeakers?.map((speaker) => {
                    return {
                        id: speaker.id,
                        role: speaker.LivestreamSessionSpeaker.role,
                    };
                });
                const topicTagsList = selectedTags.map((tag) => tag.value);

                const logoListFiltered = isArray(logoList)
                    ? logoList.filter((e) => e)
                    : [];
                const data = {
                    projectId,
                    showcaseId: showcaseId || livePageId || '',
                    physicalVenueId: locationId,
                    name: title,
                    logos: logoList,
                    previousLogos: JSON.stringify(logoListFiltered),
                    description,
                    startTime: moment(startDate).utc(),
                    endTime: moment(endDate).utc(),
                    mode: 'physical',
                    sessionTypeId: 1,
                    sessionTrackId: 1,
                    digitalVenue: '',
                    topicTags: JSON.stringify(topicTagsList),
                    speakers: JSON.stringify(speakersList),
                    ctaType,
                    ctaStreamingService,
                    ctaStreamingServiceUrl,
                    ctaStreamingChannelId,
                    ctaStreamingDefaultLogo,
                    ctaDisableAutoPlay,
                    ctaDisableVideoQuality,
                    ctaStreamingServiceText,
                };

                if (ctaType === 'default') {
                    data.ctaVdoText = ctaVdoText;
                    data.ctaDefaultText = ctaDefaultText;
                    data.openCtaInNewWindow = false;
                    data.openDefaultCtaInNewWindow = openDefaultCtaInNewWindow;
                    data.ctaLink = ctaLink;
                }

                if (ctaType === 'custom') {
                    data.ctaDefaultText = ctaDefaultTextCustom;
                    data.openCtaInNewWindow = openCtaInNewWindow;
                    data.ctaLink = ctaLinkCustom;
                }

                if (ctaType === 'none') {
                    data.ctaVdoText = '';
                    data.ctaDefaultText = '';
                    data.openCtaInNewWindow = false;
                    data.ctaLink = '';
                }

                let createSession;
                if (selectedSession.id) {
                    createSession = await updateAgendaSession(
                        selectedSession.id,
                        data
                    );
                } else {
                    createSession = await createAgendaSessions(data);
                }

                if (createSession?.status) {
                    if (selectedSession.id) {
                        setEditSuccess(true);
                        setAddedDate(
                            moment(startDate)
                                .tz(activeTimezone)
                                .format(dateFormatForSessionSubmission)
                        );
                        showAlertBar(`Session successfully updated`, 'success');
                    } else {
                        setAddedDate(
                            moment(startDate)
                                .tz(activeTimezone)
                                .format(dateFormatForSessionSubmission)
                        );
                        setEditSuccess(true);
                        showAlertBar(`Session successfully created`, 'success');
                    }
                    if (type === 'save') {
                        toggleForm(false);
                    }
                    clearFields();
                } else if (selectedSession.id) {
                    showAlertBar(createSession.message, 'error');
                } else {
                    showAlertBar(createSession.message, 'error');
                }
            }
        } catch (error) {
            console.log(`error`, error);
        }
    };

    const clearFields = () => {
        setIsSubmitted(false);
        setTitle('');
        setLogoList([]);
        setDescription('');
        setStartDate('');
        setStartTime('');
        setEndDate('');
        setEndTime('');
        setSelectedTags([]);
        setLocationId('');
        setSelectedSpeakers([]);
        setCtaStreamingService('');
        setCtaType('none');
        setCtaDefaultText('Join');
        setCtaVdoText('Replay');
        setCtaLink('');
        setCtaDefaultTextCustom('');
        setCustomOpenNewWindow(true);
        setDefaultOpenNewWindow(true);
        setCtaLinkCustom('');
        setCtaStreamingServiceText('Watch Video');
        setLivePageId('');
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const rearrangedSpeakers = arrayMove(
            selectedSpeakers,
            oldIndex,
            newIndex
        );

        setSelectedSpeakers(rearrangedSpeakers);
    };

    const renderToolTip = (text) => {
        return (
            <Tooltip placement="right" title={text}>
                <InfoCircleOutlined />
            </Tooltip>
        );
    };

    return (
        <>
            <FormSlider
                width="700px"
                isOpen={showForm}
                showBlanket
                zIndex={999}
                toggleSideBar={toggleForm}
                className="tw-w-full md:tw-w-[700px]"
            >
                <div className="sessionForm">
                    <h1>{formAction} Session</h1>
                    <div className="form">
                        <div className="session-details">
                            <div className="input-set">
                                <label>
                                    Session title <small>*</small>{' '}
                                    {renderToolTip('Session title')}
                                </label>
                                <input
                                    className="form-control"
                                    name="title"
                                    value={title}
                                    onChange={(e) =>
                                        handleTitleChange(e.target.value)
                                    }
                                />
                                {isSubmitted && !title && (
                                    <span className="error">
                                        Required field.
                                    </span>
                                )}
                            </div>
                            {!showcaseId && (
                                <div className="input-set">
                                    <label>
                                        Session LivePage <small>*</small>{' '}
                                        {renderToolTip(
                                            'This is the livepage under which this session appears.'
                                        )}
                                    </label>
                                    <LivePageSelect
                                        {...{
                                            projectId,
                                            setLivePageId,
                                            livePageId,
                                            selectedLivePageId:
                                                selectedSession?.showcaseId,
                                            setIsSubmitted,
                                        }}
                                    />
                                    {isSubmitted && !livePageId && (
                                        <span className="error">
                                            Required field.
                                        </span>
                                    )}
                                </div>
                            )}

                            <div
                                style={{ marginTop: '35px' }}
                                className="input-set row"
                            >
                                <div className="column tw-w-[50%] md:tw-w-[68%]">
                                    <div
                                        className="date"
                                        id="start-date-create-agenda"
                                        style={{ position: 'relative' }}
                                    >
                                        <label>
                                            Start date <small>*</small>
                                        </label>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'start-date-create-agenda'
                                                )
                                            }
                                            disabledDate={(current) => {
                                                return (
                                                    current &&
                                                    current <
                                                        moment().startOf('day')
                                                );
                                            }}
                                            value={
                                                startDate
                                                    ? moment(startDate).tz(
                                                          activeTimezone
                                                      )
                                                    : ''
                                            }
                                            onChange={(date) =>
                                                handleStartDateTimeChange(
                                                    date,
                                                    'date'
                                                )
                                            }
                                            format="ll"
                                            picker="date"
                                            allowClear={false}
                                            suffixIcon={<CalendarIcon />}
                                        />
                                        {isSubmitted && !startDate && (
                                            <span className="error">
                                                Required field.
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="column time tw-w-[45.5%] md:tw-w-[29%]">
                                    <div
                                        className="time"
                                        id="start-time-create-agenda"
                                        style={{ position: 'relative' }}
                                    >
                                        <label>
                                            Start time <small>*</small>
                                        </label>
                                        <TimePicker
                                            style={{ width: '100%' }}
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'start-time-create-agenda'
                                                )
                                            }
                                            disabledHours={() =>
                                                getDisabledHours('startDate')
                                            }
                                            allowClear={false}
                                            format="hh:mm:a"
                                            value={
                                                startDate
                                                    ? moment(startDate).tz(
                                                          activeTimezone
                                                      )
                                                    : ''
                                            }
                                            onChange={(date) =>
                                                handleStartDateTimeChange(
                                                    date,
                                                    'time'
                                                )
                                            }
                                            suffixIcon={<ClockIcon />}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="input-set row">
                                <div className="column tw-w-[50%] md:tw-w-[68%]">
                                    <div
                                        className="date"
                                        id="end-date-create-agenda"
                                        style={{ position: 'relative' }}
                                    >
                                        <label>
                                            End date <small>*</small>
                                        </label>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'end-date-create-agenda'
                                                )
                                            }
                                            disabledDate={(current) => {
                                                return (
                                                    current &&
                                                    current <
                                                        moment(startDate)
                                                            .tz(activeTimezone)
                                                            .startOf('day')
                                                );
                                            }}
                                            value={
                                                endDate
                                                    ? moment(endDate).tz(
                                                          activeTimezone
                                                      )
                                                    : ''
                                            }
                                            onChange={(date) =>
                                                handleEndDateTimeChange(
                                                    date,
                                                    'date'
                                                )
                                            }
                                            format="ll"
                                            picker="date"
                                            allowClear={false}
                                            suffixIcon={<CalendarIcon />}
                                        />
                                        {isSubmitted && !endDate && (
                                            <span className="error">
                                                Required field.
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="column time tw-w-[45.5%] md:tw-w-[29%]">
                                    <div
                                        className="time"
                                        id="end-time-create-agenda"
                                        style={{ position: 'relative' }}
                                    >
                                        <label>
                                            End time <small>*</small>
                                        </label>
                                        <TimePicker
                                            style={{ width: '100%' }}
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'end-time-create-agenda'
                                                )
                                            }
                                            allowClear={false}
                                            format="hh:mm:a"
                                            disabled={!endDate}
                                            disabledHours={getDisabledHours}
                                            value={
                                                endDate
                                                    ? moment(endDate).tz(
                                                          activeTimezone
                                                      )
                                                    : ''
                                            }
                                            onChange={(date) =>
                                                handleEndDateTimeChange(
                                                    date,
                                                    'time'
                                                )
                                            }
                                            suffixIcon={<ClockIcon />}
                                        />
                                        {isSubmitted && !endTime && (
                                            <span className="error">
                                                Required field.
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <TimezoneLabel
                                    tZstyle={{
                                        textAlign: 'left',
                                        marginTop: '15px',
                                    }}
                                    allowChange={false}
                                    // showLocalTz
                                />
                            </div>
                            <div className="input-set">
                                <label>
                                    Description
                                </label>
                                {/* Text only */}
                                <FroalaEditor
                                    tag="textarea"
                                    config={{
                                        key: process.env
                                            .REACT_APP_FROALA_LICENSE_KEY,
                                        attribution: false,
                                        pastePlain: true,
                                        placeholderText:
                                            'Edit your content here!',
                                        // iframe: true,
                                        htmlRemoveTags: ['script', 'base'],
                                        heightMin: 150,
                                        quickInsertTags: [''],
                                        toolbarSticky: false,
                                        toolbarButtons: {
                                            moreText: {
                                                buttons: [
                                                    'bold',
                                                    'italic',
                                                    'underline',
                                                    // 'strikeThrough',
                                                    // 'subscript',
                                                    // 'superscript',
                                                    'fontFamily',
                                                    'fontSize',
                                                    // 'backgroundColor',
                                                    // 'inlineClass',
                                                    // 'inlineStyle',
                                                    // 'clearFormatting',
                                                ],
                                                buttonsVisible: 0,
                                            },
                                            moreParagraph: {
                                                buttons: [
                                                    'alignLeft',
                                                    'alignCenter',
                                                    'alignRight',
                                                    'formatOLSimple',
                                                    'alignJustify',
                                                    'formatOL',
                                                    'formatUL',
                                                    'lineHeight',
                                                ],
                                                buttonsVisible: 0,
                                            },
                                        },
                                        events: {
                                            'paste.beforeCleanup': function (
                                                clipboard_html
                                            ) {
                                                return sanitizeContent({
                                                    str: clipboard_html,
                                                    type: 'bio',
                                                });
                                            },
                                        },
                                    }}
                                    model={sanitizeContent({
                                        str: description,
                                        type: 'bio',
                                    })}
                                    onModelChange={handleModelChange}
                                    className="rich-text-editor"
                                />
                            </div>
                            <div className="input-set widget-menu">
                                <label>Speakers</label>
                                <table>
                                    <SortableContainer
                                        onSortEnd={onSortEnd}
                                        pressDelay={200}
                                        helperClass="widget-menu__sortable-helper sortable-helper-index"
                                    >
                                        {selectedSpeakers.length > 0 &&
                                            selectedSpeakers
                                                .slice()
                                                .map((speaker, index) => {
                                                    return (
                                                        <SortableItem
                                                            key={`speaker-${speaker.id}`}
                                                            index={index}
                                                            value={speaker}
                                                            className="sortable-helper-index"
                                                            sortIndex={index}
                                                            handleSpeakerRole={
                                                                handleSpeakerRole
                                                            }
                                                            removeSpeaker={
                                                                removeSpeaker
                                                            }
                                                        />
                                                    );
                                                })}
                                    </SortableContainer>
                                </table>
                                <Dropdown>
                                    <Dropdown.Toggle className="addSpeaker">
                                        <img
                                            src="/assets/icon-plus-dark.svg"
                                            alt="add speaker"
                                        />{' '}
                                        Add speaker
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="tags-items sp">
                                            {!isEmpty(speakersdata) && (
                                                <input
                                                    type="text"
                                                    placeholder="Search speaker"
                                                    onChange={(e) => {
                                                        setSpeakerSearch(
                                                            e.target.value
                                                        );
                                                    }}
                                                    className="form-control m-0"
                                                />
                                            )}
                                            <div
                                                className={`speakers ${
                                                    speakersdata.length > 0
                                                        ? 'has-item'
                                                        : ''
                                                }`}
                                            >
                                                {speakerSearch &&
                                                isEmpty(filteredSpeakers) ? (
                                                    <span className="mx-auto select-speaker">
                                                        No Record Found
                                                    </span>
                                                ) : isEmpty(
                                                      filteredSpeakers
                                                  ) ? (
                                                    isEmpty(speakersdata) ? (
                                                        <span className="mx-auto select-speaker">
                                                            No Speaker Created
                                                        </span>
                                                    ) : (
                                                        <span className="mx-auto select-speaker">
                                                            All the speaker(s)
                                                            are selected
                                                        </span>
                                                    )
                                                ) : (
                                                    <>
                                                        <span className="select-speaker">
                                                            Select a speaker
                                                        </span>
                                                        {filteredSpeakers.length >
                                                            0 &&
                                                            filteredSpeakers.map(
                                                                (speaker) => {
                                                                    return (
                                                                        <div
                                                                            className="speaker-popup"
                                                                            key={
                                                                                speaker.id
                                                                            }
                                                                            onClick={() =>
                                                                                handleSelectedSpeaker(
                                                                                    speaker
                                                                                )
                                                                            }
                                                                        >
                                                                            {speaker.profilePhoto &&
                                                                            speaker.profilePhoto !==
                                                                                'null' ? (
                                                                                <img
                                                                                    className="profile"
                                                                                    src={
                                                                                        speaker.profilePhoto
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <div className="speaker_profile">
                                                                                    <span className="name">
                                                                                        {speaker.firstname?.substring(
                                                                                            0,
                                                                                            1
                                                                                        )}
                                                                                        {speaker.lastname?.substring(
                                                                                            0,
                                                                                            1
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                            <span>
                                                                                {
                                                                                    speaker.firstname
                                                                                }
                                                                                {` `}
                                                                                {
                                                                                    speaker.lastname
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/*isSubmitted && selectedSpeakers.length === 0 && <span className="error">Speakers are required.</span>*/}
                            </div>
                            <div className="input-set">
                                <label>
                                    Tags {renderToolTip('Select session tags')}
                                </label>

                                <Select
                                    labelInValue
                                    allowClear
                                    onChange={setSelectedTags}
                                    filterOption={false}
                                    options={tags}
                                    value={selectedTags}
                                    placeholder="Choose Topic tags"
                                    optionFilterProp="label"
                                    notFoundContent={
                                        <p className="tw-text-black">No data</p>
                                    }
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    tagRender={(props) => {
                                        return (
                                            <Tag
                                                {...props}
                                                className="badge badge-xpmanager font-weight-normal border-0"
                                            >
                                                {props?.label}
                                            </Tag>
                                        );
                                    }}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <div className="tw-w-full tw-mt-4 tw-mb-2 tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center ">
                                                <p className="tw-p-0 tw-m-0 tw-bg-gray-200 tw-w-11/12 tw-h-px" />

                                                <button
                                                    className={`${
                                                        isDisabled
                                                            ? 'tw-bg-black/70'
                                                            : 'tw-bg-black tw-border-black'
                                                    } tw-rounded tw-text-white tw-text-xs tw-font-semibold tw-font-montserrat tw-px-3 tw-py-1.5 tw-border-solid tw-border`}
                                                    onClick={() => {
                                                        if (
                                                            tagGroupsPaginationData?.currentPage !==
                                                                tagGroupsPaginationData?.totalPage &&
                                                            tagGroupsPaginationData?.totalPage !==
                                                                0
                                                        ) {
                                                            handleGetTopicTagGroups(
                                                                tagGroupsPaginationData?.currentPage +
                                                                    1
                                                            );
                                                        } else {
                                                            handleGetTopics(
                                                                (tagsPaginationData?.currentPage ||
                                                                    0) + 1
                                                            );
                                                        }
                                                    }}
                                                    disabled={isDisabled}
                                                >
                                                    Load more
                                                </button>
                                            </div>
                                        </>
                                    )}
                                />
                            </div>

                            <StreamingService
                                headingTitle="Streaming service"
                                streamType={ctaStreamingService}
                                type={setCtaStreamingService}
                                required={false}
                                customStyle={{ padding: 0, marginBottom: 15 }}
                                // error={!streamType && isSubmitted}
                            />

                            <StreamingServiceInput
                                streamType={ctaStreamingService}
                                ctaStreamingServiceUrl={ctaStreamingServiceUrl}
                                setCtaStreamingServiceUrl={
                                    setCtaStreamingServiceUrl
                                }
                                ctaStreamingDefaultWatch={
                                    setCtaStreamingDefaultWatch
                                }
                                ctaStreamingDefaultLogo={
                                    ctaStreamingDefaultLogo
                                }
                                setCtaStreamingDefaultLogo={
                                    setCtaStreamingDefaultLogo
                                }
                                ctaStreamingChannelId={ctaStreamingChannelId}
                                setCtaStreamingChannelId={
                                    setCtaStreamingChannelId
                                }
                                ctaDisableAutoPlay={ctaDisableAutoPlay}
                                setCtaDisableAutoPlay={setCtaDisableAutoPlay}
                                ctaDisableVideoQuality={ctaDisableVideoQuality}
                                setCtaDisableVideoQuality={
                                    setCtaDisableVideoQuality
                                }
                                ref={sponsorEditInputsRef}
                            />

                            {!isEmpty(ctaStreamingService) && (
                                <div className="input-set">
                                    <label>
                                        Watch CTA Text{' '}
                                        {renderToolTip('CTA text button')}
                                    </label>
                                    <input
                                        className="form-control"
                                        name="ctaStreamingServiceText"
                                        value={ctaStreamingServiceText}
                                        onChange={(e) =>
                                            setCtaStreamingServiceText(
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            )}

                            <div className="input-set">
                                <label>
                                    Session CTA behaviour{' '}
                                    {renderToolTip('Session CTA behaviour')}
                                </label>
                                <div className="radio-group">
                                    <Radio.Group
                                        onChange={(e) => {
                                            setIsSubmitted(false);
                                            setCtaType(e.target.value);
                                        }}
                                        value={ctaType}
                                        type="radio"
                                    >
                                        <Space
                                            direction="vertical"
                                            className="tw-flex tw-flex-col tw-w-full"
                                        >
                                            <Radio value="default">
                                                <div className="radio-wrapper tw-flex tw-flex-col tw-gap-2 tw-flex-1">
                                                    <span className="radio-head">
                                                        Default{' '}
                                                        {renderToolTip(
                                                            'The default behaviour links to the session livepage'
                                                        )}
                                                    </span>
                                                    {ctaType === 'default' && (
                                                        <div className="radio-content">
                                                            <div className="input-radio checkboxCustom">
                                                                <Checkbox
                                                                    checked={
                                                                        openDefaultCtaInNewWindow
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setIsSubmitted(
                                                                            false
                                                                        );
                                                                        setDefaultOpenNewWindow(
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        );
                                                                    }}
                                                                >
                                                                    Open in new
                                                                    window
                                                                </Checkbox>
                                                            </div>
                                                            <div className="input-radio flex">
                                                                <div className="input-radio-item">
                                                                    <label>
                                                                        Default
                                                                        CTA Text{' '}
                                                                        <small>
                                                                            *
                                                                        </small>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        value={
                                                                            ctaDefaultText
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setIsSubmitted(
                                                                                false
                                                                            );
                                                                            setCtaDefaultText(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                    />
                                                                    {isSubmitted &&
                                                                        !ctaDefaultText && (
                                                                            <span className="error">
                                                                                Required
                                                                                field.
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Radio>
                                            <Radio value="custom">
                                                <div className="radio-wrapper tw-flex tw-flex-col tw-gap-2 tw-flex-1">
                                                    <span className="radio-head">
                                                        Custom Link{' '}
                                                        {renderToolTip(
                                                            'Choose this option if you want to link the session’s CTA to a custom link.'
                                                        )}
                                                    </span>
                                                    {ctaType === 'custom' && (
                                                        <div className="radio-content">
                                                            <div className="input-radio">
                                                                <label>
                                                                    Link{' '}
                                                                    <small>
                                                                        *
                                                                    </small>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    value={
                                                                        ctaLinkCustom
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setIsSubmitted(
                                                                            false
                                                                        );
                                                                        setCtaLinkCustom(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />

                                                                {isSubmitted &&
                                                                    !ctaLinkCustom && (
                                                                        <span className="error">
                                                                            Required
                                                                            field.
                                                                        </span>
                                                                    )}
                                                                {isSubmitted &&
                                                                    ctaLinkCustom &&
                                                                    !validator.isURL(
                                                                        ctaLinkCustom
                                                                    ) && (
                                                                        <span className="error">
                                                                            Invalid
                                                                            URL.
                                                                        </span>
                                                                    )}
                                                            </div>
                                                            <div className="input-radio checkboxCustom">
                                                                <Checkbox
                                                                    checked={
                                                                        openCtaInNewWindow
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setIsSubmitted(
                                                                            false
                                                                        );
                                                                        setCustomOpenNewWindow(
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        );
                                                                    }}
                                                                >
                                                                    Open in new
                                                                    window
                                                                </Checkbox>
                                                            </div>
                                                            <div
                                                                className="input-radio"
                                                                style={{
                                                                    marginTop: 8,
                                                                }}
                                                            >
                                                                <label>
                                                                    CTA text{' '}
                                                                    <small>
                                                                        *
                                                                    </small>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    value={
                                                                        ctaDefaultTextCustom
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setIsSubmitted(
                                                                            false
                                                                        );
                                                                        setCtaDefaultTextCustom(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                                {isSubmitted &&
                                                                    !ctaDefaultTextCustom && (
                                                                        <span className="error">
                                                                            Required
                                                                            field.
                                                                        </span>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Radio>
                                            <Radio value="none">
                                                <span className="radio-head">
                                                    No CTA{' '}
                                                    {renderToolTip(
                                                        'Choose this option if you don’t want to have any CTA for the session.'
                                                    )}
                                                </span>
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className="input-set">
                                <label>Location</label>
                                <SearchSelectInput
                                    fetchOptions={handleGetLocations}
                                    onChange={(newValue) => {
                                        if (!isUndefined(newValue)) {
                                            setLocationId(newValue);
                                        } else {
                                            setLocationId('');
                                        }
                                    }}
                                    showArrow={false}
                                    showSearch
                                    filterOption={false}
                                    allowClear
                                    withLogoOptions={false}
                                    // size={'large'}
                                    placeholder="Search location"
                                    value={locationId}
                                    extraData={locationData}
                                />
                            </div>

                            <div className="input-set tw-flex tw-flex-col tw-gap-3">
                                <label className="tw-mb-0">
                                    Session logo(s){' '}
                                    {renderToolTip(
                                        'Select or upload session logo(s)'
                                    )}
                                </label>
                                {visibleImageComponent && (
                                    <ImageUpload
                                        multiple
                                        uploadText="+ Add new logo"
                                        previewImages={
                                            !isEmpty(logoList) ? logoList : []
                                        }
                                        prepareFilesUpload={(files) => {
                                            setIsSubmitted(false);
                                            setLogoList(files);
                                        }}
                                    />
                                )}
                                <span style={{ display: 'block' }}>
                                    Add a maximum of {MAX_LOGO_IMAGES} logos
                                </span>
                                {logoList?.length > MAX_LOGO_IMAGES ? (
                                    <span className="error">
                                        Maximum of {MAX_LOGO_IMAGES} Logos only
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="session-footer">
                            <button
                                className="btn btn-secondary"
                                onClick={() => toggleForm(false)}
                            >
                                Cancel
                            </button>
                            {formAction !== 'Edit' && (
                                <button
                                    className="btn btn-primary"
                                    // disabled={isSubmitted}
                                    onClick={(e) => {
                                        setIsSubmitted(true);
                                        handleSubmit(e, 'save-create');
                                    }}
                                >
                                    Save and create another
                                </button>
                            )}
                            <button
                                className="btn btn-primary"
                                // disabled={isSubmitted}
                                onClick={(e) => {
                                    setIsSubmitted(true);
                                    handleSubmit(e, 'save');
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>

                <SpeakerForm
                    showForm={showSpeakerForm}
                    toggleForm={toggleSpeakerForm}
                    showAlertBar={showAlertBar}
                    showBlanket={false}
                    onSave={onSpeakerSaved}
                    zIndex={102}
                />
            </FormSlider>
        </>
    );
}

export default CreateSession;
