/* eslint-disable react/prop-types */
import { getSpeakerImportLogs } from 'apis/rest/speakers/getSpeakerImportLogs';
import * as ls from 'local-storage';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { Table, Modal } from 'antd';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import PreviewIcon from '../../instant-meetings/icons/PreviewIcon';
import CrossIcon from '../../media-assets/icons/CrossIcon';

export default function SpeakerLogsModal({
    handleClose,
    open,
    containerId = 'speaker-container',
}) {
    const location = useLocation();
    const isSponsor = !!ls.get('sponsorAuth');
    const { projectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [logsData, setLogsData] = useState([]);

    const fetchLogs = async (page = 1) => {
        try {
            setLoading(true);
            const logsData = await getSpeakerImportLogs({ page, projectId });
            if (logsData?.status) {
                setLogsData(logsData || []);
            }
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || 'Something went wrong!',
            });
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: (
                <span className="tw-text-md tw-font-semibold">File name</span>
            ),
            dataIndex: 'fileName',
            render: (fileName) => {
                return <span>{fileName}</span>;
            },
        },
        {
            title: (
                <span className="tw-text-md tw-font-semibold">
                    Date and Time
                </span>
            ),
            dataIndex: 'createdAt',
            render: (createdAt) => {
                if (!createdAt) return null;
                return (
                    <span>
                        {moment(createdAt)
                            .tz(createdAt)
                            .format('DD MMMM YYYY [at] h:mm:ss a')}
                    </span>
                );
            },
        },
        {
            title: <span className="tw-text-md tw-font-semibold">User</span>,
            dataIndex: isSponsor ? 'sponsors.userName' : 'users.userName',
            render: (userName) => {
                return <span>{userName}</span>;
            },
        },
        {
            title: (
                <span className="tw-text-md tw-font-semibold">
                    Total records
                </span>
            ),
            dataIndex: 'totalRecords',
            render: (totalRecords) => {
                return <span>{totalRecords}</span>;
            },
        },
        {
            title: (
                <span className="tw-text-md tw-font-semibold">
                    Total import
                </span>
            ),
            dataIndex: 'totalImports',
            render: (totalImports) => {
                return <span>{totalImports}</span>;
            },
        },
        {
            title: (
                <span className="tw-text-md tw-font-semibold">
                    Import status
                </span>
            ),
            dataIndex: 'importStatus',
            render: (importStatus, dataObj) => {
                return importStatus !== 'error' ? (
                    <span className="tw-text-md tw-font-semibold tw-text-[#06A64A]">
                        File imported successfully
                    </span>
                ) : (
                    <a
                        className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-md tw-font-semibold tw-text-[#A60606] tw-select-none tw-cursor-pointer"
                        href={
                            isSponsor
                                ? `${location?.pathname}/speaker-import/${dataObj?.id}`
                                : `${location?.pathname}/import/${dataObj?.id}`
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Failed to import
                        <PreviewIcon />
                    </a>
                );
            },
        },
    ];

    useEffect(() => {
        fetchLogs();
    }, []);

    return (
        <Modal
            visible={open}
            onClose={handleClose}
            style={{ maxWidth: '900px', width: '100%' }}
            getContainer={() => document.querySelector(`#${containerId}`)}
            className="tw-w-[360px] md:tw-w-auto speakerlogs"
            footer={null}
            closable={false}
            bodyStyle={{ padding: 2 }}
        >
            <Table
                loading={loading}
                className="md:tw-mt-8"
                columns={columns}
                dataSource={logsData?.speakerLogs}
                scroll={{ x: 520 }}
                title={() => (
                    <div className="tw-flex tw-justify-between tw-items-center">
                        <span className="tw-text-lg tw-font-semibold">
                            Speaker Import Logs
                        </span>
                        <div onClick={handleClose}>
                            <CrossIcon />
                        </div>
                    </div>
                )}
                pagination={{
                    hideOnSinglePage: true,
                    onChange: (page) => fetchLogs(page),
                    total: logsData?.pagination?.total,
                    pageSize: logsData?.pagination?.limit,
                    showSizeChanger: false,
                    current: logsData?.pagination?.currentPage || 1,
                }}
                bordered
            />
            <style>{`
            .speakerlogs .ant-modal-content{
            border-radius: 8px !important;
            }
            `}</style>
        </Modal>
    );
}
