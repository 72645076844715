import React, { useEffect, useState, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { Radio, Tooltip, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

import CopyIcon from 'Apps/VenueBuilder/Icons/CopyIcon';
import {
    getCriteriaShowcaseBlock,
    updateSpecificBlock,
    createUpdateCriteriaShowcaseBlock,
    deleteCriteriaShowcaseBlock,
    setActiveContent,
    getProjectFields,
} from 'Apps/VenueBuilder/Components/Layout/AppSideBar/actions';
import { checkIfNullQueryFieldExist } from 'Apps/VenueBuilder/helpers/common';
import LockedOverlay from 'components/app/common/lockedOverlay';
import QueryBuilder from './QueryBuilder';

import styles from './index.module.css';

import { updateMeetCustomCSS } from '../../Layout/AppSideBar/Components/ContentBlocks/components/MeetingBlock';
import sanitizeContent from 'utils/InputSanitization';

const VisibilityControl = ({
    title,
    blockId,
    blockName,
    blockData,
    showcaseId,
    isStarterPlan = false,
}) => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const selection = useSelector((state) => state?.header?.selection);
    // get blockid state
    const [selectedCriteria, setSelectedCriteria] = useState(false);
    const [accessCriteriaValue, setAccessCriteriaValue] = useState(null);

    const [accessCriteriaFields, setAccessCriteriaFields] = useState(null);

    const [onChangeBlock, setOnChangeBlock] = useState(false);
    const [blockNameData, setBlockName] = useState('');
    const [captionHtml, setCaptionHtml] = useState(
        blockData?.captionHtml || ''
    );

    const getCriteria = async () => {
        const responseData = await dispatch(
            getCriteriaShowcaseBlock(projectId, blockId)
        );

        if (responseData?.id) {
            setAccessCriteriaValue(responseData);
            setSelectedCriteria(true);
        } else {
            setSelectedCriteria(false);
        }
    };

    const getProjectFieldsData = async () => {
        const responseData = await dispatch(getProjectFields(projectId));

        if (responseData?.status) {
            setAccessCriteriaFields(responseData?.data);
        } else {
            // fallback data if Get failed
            setAccessCriteriaFields({
                AllFieldsUnderProject: {
                    fields: [
                        {
                            field: 'ticketName',
                            label: 'Ticket Name',
                            __typename: 'field',
                        },
                        {
                            field: 'firstname',
                            label: 'First Name',
                            __typename: 'field',
                        },
                        {
                            field: 'lastname',
                            label: 'Last Name',
                            __typename: 'field',
                        },
                        { field: 'email', label: 'Email', __typename: 'field' },
                        {
                            field: 'paymentStatus',
                            label: 'Payment Status',
                            __typename: 'field',
                        },
                        {
                            field: 'status',
                            label: 'Status',
                            __typename: 'field',
                        },
                        {
                            field: 'ticketNo',
                            label: 'Ticket No',
                            __typename: 'field',
                        },
                        {
                            field: 'company',
                            label: 'Company',
                            __typename: 'field',
                        },
                        {
                            field: 'jobTitle',
                            label: 'Job Title',
                            __typename: 'field',
                        },
                    ],
                },
            });
        }
    };

    const handleCopyBlockId = () => {
        copy(blockNameData);
        openNotification('success', { message: 'Copied to clipboard!' });
    };

    const saveNameSetting = async () => {
        
        const forbiddenPatterns =
            /<script|<iframe|<svg|<img|<style|onerror=|onload=|onmouseover=|onfocus=|onclick=|oninput=|onchange=/i;

        if (forbiddenPatterns.test(blockNameData)) {
           return openNotification('error', {
                message:
                    'Invalid input detected! Please remove forbidden tags or attributes.',
            });
        }

        const response = await dispatch(
            updateSpecificBlock(
                {
                    id: blockId,
                    name: sanitizeContent({str: blockNameData}),
                },
                showcaseId,
                false
            )
        );

        if (response?.status) {
            openNotification('success', {
                message: 'Block name successfully updated.',
            });
            setOnChangeBlock(false);
        }
    };

    const handleSaveControl = () => {
        if (
            accessCriteriaValue &&
            !checkIsFieldNullExist(accessCriteriaValue)
        ) {
            if (selectedCriteria) {
                dispatch(
                    createUpdateCriteriaShowcaseBlock(
                        accessCriteriaValue,
                        projectId,
                        blockId
                    )
                );
                dispatch(
                    updateSpecificBlock(
                        {
                            id: blockId,
                            isPublic: false,
                        },
                        showcaseId,
                        false
                    )
                );
            } else {
                dispatch(deleteCriteriaShowcaseBlock(projectId, blockId));
                setAccessCriteriaValue(null);
                dispatch(
                    updateSpecificBlock(
                        {
                            id: blockId,
                            isPublic: true,
                        },
                        showcaseId,
                        false
                    )
                );
            }
        }
        if (window.ace && window.ace.edit && !isStarterPlan) {
            const editor = window.ace.edit('ace-editor');
            let customCss = editor.getValue();
            if (customCss !== blockData?.customCss) {
                customCss = customCss === '' ? ' ' : customCss;
                if (blockData?.type === 'Meeting')
                    updateMeetCustomCSS(customCss);
                dispatch(
                    updateSpecificBlock(
                        {
                            id: blockId,
                            customCss,
                        },
                        showcaseId,
                        false
                    )
                );
            }
        }
        if (
            blockData?.type === 'LiveStream' &&
            blockData?.streamingService === 'customstream'
        ) {
            dispatch(
                updateSpecificBlock(
                    {
                        id: blockId,
                        captionHtml,
                        type: blockData?.type,
                    },
                    showcaseId,
                    false
                )
            );
        }
    };

    const initializeAceEditor = () => {
        try {
            if (window.ace && window.ace.edit) {
                const editor = window.ace.edit('ace-editor');
                editor.setTheme('ace/theme/github');
                editor.session.setMode('ace/mode/css');
                editor.setOptions({
                    highlightActiveLine: true,
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    highlightSelectedWord: true,
                    enableMultiselect: true,
                    showLineNumbers: true,
                    readOnly: isStarterPlan,
                    showPrintMargin: false,
                });
                editor.setValue(
                    blockData?.customCss && blockData?.customCss?.length > 0
                        ? blockData?.customCss
                        : ''
                );
                editor.clearSelection();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const checkIsFieldNullExist = useCallback(checkIfNullQueryFieldExist, [
        accessCriteriaValue,
    ]);

    useEffect(() => {
        getProjectFieldsData();
        getCriteria();
        setBlockName(blockName);
    }, []);

    useEffect(() => {
        if (
            (!isStarterPlan && selection?.type === 'widget') ||
            selection?.type !== 'widget'
        )
            initializeAceEditor();
    }, [isStarterPlan, selection]);

    return (
        <div className={styles.VisibilityControlContainer}>
            {selection?.type && selection?.type !== 'widget' && (
                <>
                    <div className={styles.InputDiv}>
                        <div className={styles.Heading}>
                            <h3>
                                Block ID
                                <Tooltip
                                    placement="right"
                                    title="Content Block ID"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </h3>
                        </div>
                        <div className={styles.InputContainer}>
                            <Input
                                name="blockId"
                                onChange={(e) => setBlockName(e.target.value)}
                                value={blockNameData}
                                disabled={!onChangeBlock}
                            />
                            <span onClick={() => handleCopyBlockId(true)}>
                                <CopyIcon />
                            </span>
                        </div>
                        <div className={styles.InputLinks}>
                            {!onChangeBlock && (
                                <span onClick={() => setOnChangeBlock(true)}>
                                    Change
                                </span>
                            )}
                            {onChangeBlock && (
                                <>
                                    <span
                                        onClick={() => {
                                            setOnChangeBlock(false);
                                            setBlockName(blockName);
                                        }}
                                    >
                                        Cancel
                                    </span>
                                    <span
                                        onClick={() => saveNameSetting()}
                                        style={{ marginLeft: 10 }}
                                    >
                                        Save
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}

            {selection?.type && selection?.type !== 'widget' && (
                <>
                    <h2 className={styles.Heading}>Visibility Control</h2>
                    <div className="visibility-control">
                        <Radio.Group
                            onChange={(e) =>
                                setSelectedCriteria(e.target.value)
                            }
                            value={selectedCriteria}
                        >
                            <Radio value={false}>
                                <span className="radio-title">
                                    Visible to everyone
                                </span>
                            </Radio>
                            <Radio value>
                                <span className="radio-title">
                                    Visible only to specific people
                                </span>

                                <div className="query-builder">
                                    {selectedCriteria && (
                                        <>
                                            <span className="desc">
                                                Make this block visible only to
                                                people <br /> meeting the
                                                following rules, where:
                                            </span>
                                            {accessCriteriaFields && (
                                                <QueryBuilder
                                                    fields={
                                                        accessCriteriaFields
                                                    }
                                                    value={accessCriteriaValue}
                                                    onChange={(value) => {
                                                        setAccessCriteriaValue(
                                                            value
                                                        );
                                                    }}
                                                />
                                            )}
                                            {accessCriteriaValue &&
                                                checkIsFieldNullExist(
                                                    accessCriteriaValue
                                                ) && (
                                                    <span
                                                        className={
                                                            styles.EmptyFilter
                                                        }
                                                    >
                                                        Required.
                                                    </span>
                                                )}
                                        </>
                                    )}
                                </div>
                            </Radio>
                        </Radio.Group>
                    </div>
                </>
            )}

            {blockData?.type === 'LiveStream' &&
                blockData?.streamingService === 'customstream' && (
                    <div className={styles.InputDiv}>
                        <div className={styles.Heading}>
                            <h3>Player Custom Options</h3>
                        </div>
                        <Input
                            name="captionHtml"
                            onChange={(e) => setCaptionHtml(e.target.value)}
                            defaultValue={blockData?.captionHtml}
                            value={captionHtml}
                        />
                    </div>
                )}
            <div
                className={`${LockedOverlay.getParentClass({
                    isInStarterPlan: isStarterPlan,
                })} tw-flex tw-flex-row`}
            >
                <LockedOverlay isInStarterPlan={isStarterPlan}>
                    <div className="tw-w-full tw-flex tw-flex-col">
                        <h2 className={styles.Heading}>
                            CSS Code{' '}
                            {isStarterPlan && selection?.type === 'widget'
                                ? '(Upgrade)'
                                : ''}
                        </h2>

                        {(!isStarterPlan && selection?.type === 'widget') ||
                        selection?.type !== 'widget' ? (
                            <div id="ace-editor" className="ace-editor" />
                        ) : (
                            ''
                        )}
                    </div>
                </LockedOverlay>
            </div>
            <div className={styles.VisibilityControlButtons}>
                <span
                    onClick={() => dispatch(setActiveContent('content'))}
                    className={styles.ButtonCancel}
                >
                    Cancel
                </span>

                <button
                    onClick={() => {
                        handleSaveControl();
                        dispatch(setActiveContent('content'));
                    }}
                    className={styles.ButtonSave}
                    disabled={
                        selectedCriteria &&
                        accessCriteriaValue &&
                        checkIsFieldNullExist(accessCriteriaValue)
                    }
                >
                    Save
                </button>
            </div>

            <style jsx>{`
                .ace-editor {
                    width: calc(100% - 32px);
                    min-height: 300px;
                    margin: 16px;
                }
                .visibility-control .ant-radio-group {
                    border: 0px !important;
                    border-radius: 4px;
                    width: 100%;
                }
                .visibility-control .ant-radio-wrapper {
                    display: flex !important;
                    align-items: center;
                    padding: 16px;
                    margin: 16px;
                    min-height: 50px;
                }
                .visibility-control .ant-radio-wrapper {
                    margin: 16px;
                    border-radius: 4px;
                    border: 1px solid #dadeeb;
                    min-height: 50px;
                }
                .visibility-control span.ant-radio + * {
                    display: flex;
                    flex-direction: column;
                }
                .visibility-control .ant-radio {
                    position: absolute;
                    top: 15px;
                }
                .visibility-control
                    .ant-radio-wrapper.ant-radio-wrapper-checked {
                    border: 1px solid #000000;
                }
                .visibility-control .ant-radio-inner::after {
                    background-color: #000000 !important;
                    width: 12px !important;
                    height: 12px !important;
                }
                .visibility-control .ant-radio-checked .ant-radio-inner {
                    border-color: #000000 !important;
                    width: 20px !important;
                    height: 20px !important;
                }
                .visibility-control .ant-radio-inner {
                    width: 20px !important;
                    height: 20px !important;
                }
                .visibility-control .radio-title {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.02em;
                    color: #000000;
                    padding-left: 22px;
                    position: absolute;
                    left: 20px;
                    top: 16px;
                }
                .visibility-control .divider {
                    border-bottom: 1px solid #e3e8f7;
                }
                .visibility-control .query-builder {
                    margin-top: 18px;
                    margin-left: -3px;
                }
                .visibility-control .query-builder span.desc {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.02em;
                    color: #000000;
                    margin-top: 15px;
                }
            `}</style>
        </div>
    );
};

export default VisibilityControl;
