import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Collapse, Dropdown, Menu, Select, Tag } from 'antd';
import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwitchComponent from 'Apps/VenueBuilder/Components/Common/SwitchComponent';
import DrawerComponent from 'Apps/VenueBuilder/Components/Common/DrawerComponent';
import DraggableList from 'Apps/VenueBuilder/Components/Common/DraggableList';

import PlusIcon from 'Apps/VenueBuilder/Icons/PlusIcon';
import ContentLayout from 'Apps/VenueBuilder/Components/Common/ContentLayout';
import BlockFooter from 'Apps/VenueBuilder/Components/Common/BlockFooter';
import GlobalLoading from 'components/app/components/common/globalLoading';
import ImageUpload from 'components/app/common/imageUpload';
import RichTextEditor from 'components/app/common/richTextEditor';

import SearchSelectInput from 'components/app/common/searchSelectInput';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import kebabCase from 'lodash/kebabCase';
import orderBy from 'lodash/orderBy';
import toInteger from 'lodash/toInteger';

import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';

import AddItem from '../drawers/addItem';
import Items from '../drawers/items';
import PreviewModal from '../previewModal';

import { GetCompanies } from 'apis/rest/sponsorship/GetCompanies';
import { getAgendaSessionTags } from 'apis/rest/AgendaSessions';
import { CreateNewProduct } from 'apis/rest/products/CreateProduct';
import { GetProduct } from 'apis/rest/products/GetProduct';
import { GetResource } from 'apis/rest/products/GetResource';
import { DeleteResource } from 'apis/rest/products/DeleteResource';
import { UpdateProduct } from 'apis/rest/products/UpdateProduct';
import { checkYoutubeOrVimeoLink, createVideoThumbnailURL } from '../helpers';
import { GetTopicTagGroups } from 'apis/rest/tags/GetTopicTagGroups';

import './createProduct.scss';
import { useSelector } from 'react-redux';
import sanitizeContent from 'utils/InputSanitization';
import { isValidUrl } from 'ComponentsV2/CommonUtils/isValidUrl';
import { Option } from 'antd/lib/mentions';

const { Panel } = Collapse;

export const DRAWER_TYPES = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    RESOURCE_LINK: 'Link',
    RESOURCE_IMAGE: 'Image',
    RESOURCE_PDF: 'PDF',
};
function CreateProduct() {
    const IMAGE_CROP_ASPECT_RATIO = 7 / 5;
    const history = useHistory();
    const { projectId, productId, companyId } = useParams();
    const user = useSelector((state) => state.user);

    const { errors, setError, trigger, register } = useForm();
    const [isLoading, setIsLoading] = useState(true);

    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [exhibitorCompanyProfile, setExhibitorCompanyProfile] =
        useState(null);
    const [exhibitorCompanyProfileId, setExhibitorCompanyProfileId] =
        useState(null);
    const [topicTags, setTopicTags] = useState([]);
    const [allTopicsTags, setAllTopicsTags] = useState([]);
    const [topicTagsFull, setTopicTagsFull] = useState([]);
    const [tagGroupsPaginationData, setTagGroupsPaginationData] = useState({});
    const [tagsPaginationData, setTagsPaginationData] = useState({});
    const [productGalleryItems, setProductGalleryItems] = useState([]);
    const [currentImage, setCurrentImage] = useState(null);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');
    const [resources, setResources] = useState([]);
    const [enableCta, setEnableCta] = useState(false);
    const [ctaLabel, setctaLabel] = useState('Get in touch');
    const [ctaLink, setctaLink] = useState('');
    const [ctaLinkError, setCtaLinkError] = useState(false);
    const [ctaBackgroundColor, setctaBackgroundColor] = useState('#e2e9f3');
    const [ctaColor, setctaColor] = useState('#000000');
    const [custom1, setCustom1] = useState('');
    const [custom2, setCustom2] = useState('');
    const [custom3, setCustom3] = useState('');
    const [custom4, setCustom4] = useState('');
    const [custom5, setCustom5] = useState('');
    const [status, setStatus] = useState('');

    const [isVisibleDrawer, setIsVisibleDrawer] = useState({
        visible: false,
        item: null,
    });
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [isChangeCode, setIsChangeCode] = useState(false);
    const [previousCode, setPreviousCode] = useState('');
    const [hasTagInput, setHasTagInput] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        handleGetTopicTagGroups();
        if (productId) {
            handleGetProduct();
        } else {
            setIsLoading(false);
            setStatus('draft');
        }

        if (!isEmpty(user?.exhibitorCompanyProfileId)) {
            setExhibitorCompanyProfileId(user?.exhibitorCompanyProfileId);
            // It will be remove after event
            if (projectId === '614170be4ea589003fa6d32e') {
                setHasTagInput(false);
            }
        }
    }, []);

    const handleCreateProduct = () => {
        if (status === 'draft') {
            if (!ctaLinkError) handleCreateOrUpdateProduct('draft');
            else
                openNotification('error', {
                    message: 'Invalid CTA link ',
                });
        } else if (status === 'publish') {
            if (!ctaLinkError) handleCreateOrUpdateProduct('publish');
            else
                openNotification('error', {
                    message: 'Invalid CTA link ',
                });
        }
    };
    const handleCreateProductMobile = (value) => {
        if (value === 'draft') {
            if (!ctaLinkError) handleCreateOrUpdateProduct('draft');
            else
                openNotification('error', {
                    message: 'Invalid CTA link ',
                });
        } else if (value === 'publish') {
            if (!ctaLinkError) handleCreateOrUpdateProduct('publish');
            else
                openNotification('error', {
                    message: 'Invalid CTA link ',
                });
        }
    };

    const handleGetProduct = async () => {
        try {
            const response = await GetProduct(productId, projectId);
            if (response.status) {
                const {
                    name,
                    code,
                    description,
                    exhibitorCompanyProfile,
                    exhibitorCompanyProfileId,
                    topicTags,
                    productGalleryItems,
                    productResources,
                    enableCta,
                    ctaLabel,
                    ctaLink,
                    ctaBackgroundColor,
                    ctaColor,
                    custom1,
                    custom2,
                    custom3,
                    custom4,
                    custom5,
                    status,
                } = response.product;

                const filterProductGalleryItems = [];
                if (!isEmpty(productGalleryItems)) {
                    productGalleryItems.map((item) => {
                        filterProductGalleryItems.push({
                            name: item?.url,
                            imageUrl:
                                item?.type === 'video'
                                    ? createVideoThumbnailURL(item?.url)
                                    : item?.url,
                            id: item?.id,
                            url: item?.url,
                            type: item?.type,
                        });
                    });
                }

                const topicsTagsList = [];

                if (!isEmpty(topicTags)) {
                    topicTags.map((tag) => {
                        topicsTagsList.push({
                            value: tag?.id,
                            label: tag?.name,
                            key: tag?.id,
                        });
                    });
                }

                setStatus(!isEmpty(status) ? status : 'draft');
                setName(!isEmpty(name) ? name : '');
                setCode(!isEmpty(code) ? code : '');
                setPreviousCode(!isEmpty(code) ? code : '');
                setDescription(!isEmpty(description) ? description : '');
                setExhibitorCompanyProfileId(
                    !isEmpty(exhibitorCompanyProfileId)
                        ? exhibitorCompanyProfileId
                        : ''
                );
                setExhibitorCompanyProfile(
                    !isEmpty(exhibitorCompanyProfile)
                        ? exhibitorCompanyProfile
                        : ''
                );
                setTopicTags(topicsTagsList);
                setTopicTagsFull(!isEmpty(topicTags) ? topicTags : []);
                setProductGalleryItems(filterProductGalleryItems);
                setResources(
                    !isEmpty(productResources)
                        ? orderBy(productResources, ['order'], ['asc'])
                        : []
                );
                setEnableCta(enableCta);
                setctaLabel(!isEmpty(ctaLabel) ? ctaLabel : 'Get in touch');
                setctaLink(!isEmpty(ctaLink) ? ctaLink : '');
                setctaBackgroundColor(
                    !isEmpty(ctaBackgroundColor)
                        ? ctaBackgroundColor
                        : '#e2e9f3'
                );
                setctaColor(!isEmpty(ctaColor) ? ctaColor : '#000000');
                setCustom1(!isEmpty(custom1) ? custom1 : '');
                setCustom2(!isEmpty(custom2) ? custom2 : '');
                setCustom3(!isEmpty(custom3) ? custom3 : '');
                setCustom4(!isEmpty(custom4) ? custom4 : '');
                setCustom5(!isEmpty(custom5) ? custom5 : '');
                setIsChangeCode(false);
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR GetProduct', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetCompanies = async (search = '') => {
        try {
            const response = await GetCompanies(projectId, 1, search);
            if (response.status) {
                if (!isEmpty(response?.sponsors)) {
                    return response?.sponsors.map((company) => ({
                        label: `${company?.name}`,
                        value: company?.id,
                        logo: company?.logo,
                    }));
                }
                return [];
            }
        } catch (error) {
            console.error('ERROR handleGetCompanies', error);
        }
    };
    const handleGetResource = async (resourceId) => {
        try {
            const response = await GetResource(projectId, resourceId);
            if (response.status) {
                setResources([...resources, response.resource]);
            }
        } catch (error) {
            console.error('ERROR handleGetResource', error);
        }
    };

    const handleDeleteResource = async (resourceId) => {
        try {
            await DeleteResource(projectId, resourceId);
        } catch (error) {
            console.error('ERROR handleGetResource', error);
        }
    };

    const handleGetTopicTagGroups = async (page = 1) => {
        try {
            const response = await GetTopicTagGroups(projectId, '', page);
            if (response.status && response?.pagination?.totalPage !== 0) {
                const topicsTagsList = [...allTopicsTags];
                setTagGroupsPaginationData(response?.pagination);
                const resultData = response.topics;
                if (resultData) {
                    resultData.map((group) => {
                        topicsTagsList.push({
                            label: group?.name,
                            type: 'group',
                            options: group?.topicTags.map((tag) => ({
                                value: tag?.id,
                                label: tag?.name,
                                key: tag?.id,
                            })),
                        });
                    });
                }

                setAllTopicsTags(topicsTagsList);
            }

            if (isEmpty(response?.topics)) {
                handleGetTopics();
            }
        } catch (error) {
            console.error('ERROR handleGetTopicTagGroups', error);
        }
    };

    const handleGetTopics = async (page = 1) => {
        try {
            const responseWithOutGroup = await getAgendaSessionTags(
                projectId,
                '',
                true,
                page
            );

            const paginationObject = responseWithOutGroup?.pagination;
            const currentPage = toInteger(paginationObject?.currentPage);

            if (responseWithOutGroup.status) {
                let topicsTagsList = [...allTopicsTags];
                setTagsPaginationData({
                    ...paginationObject,
                    currentPage,
                });

                const topicTagsListObject =
                    topicsTagsList?.find(
                        (tagList) => tagList.type === 'topic'
                    ) || {};

                if (isEmpty(responseWithOutGroup?.topics)) {
                    setIsDisabled(true);
                }

                if (!isEmpty(responseWithOutGroup?.topics)) {
                    if (isEmpty(topicTagsListObject)) {
                        topicsTagsList.push({
                            label: 'Tags that do not belong to group',
                            type: 'topic',
                            options: responseWithOutGroup?.topics.map(
                                (tag) => ({
                                    value: tag?.id,
                                    label: tag?.name,
                                    key: tag?.id,
                                })
                            ),
                        });
                    } else {
                        topicsTagsList = topicsTagsList?.map((tagList) => {
                            if (tagList?.type === 'topic') {
                                const updatedResponseOptions =
                                    responseWithOutGroup?.topics.map((tag) => ({
                                        value: tag?.id,
                                        label: tag?.name,
                                        key: tag?.id,
                                    }));

                                const options = [
                                    ...tagList.options,
                                    ...updatedResponseOptions,
                                ];

                                return { ...tagList, options };
                            }
                            return tagList;
                        });
                    }
                }

                setAllTopicsTags(topicsTagsList);
            }
        } catch (error) {
            console.error('ERROR handleGetTopics', error);
        }
    };

    const handleCreateOrUpdateProduct = async (status) => {
        trigger().then((inputValidation) => {
            try {
                if (inputValidation) {
                    if (productId) {
                        // edit product
                        updateProduct(status);
                    } else {
                        //create product
                        createProduct(status);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        });
    };

    const handlePreviewProduct = async () => {
        trigger().then((inputValidation) => {
            if (inputValidation && !ctaLinkError) {
                setIsPreviewModalVisible(true);
            }
        });
    };

    const createProduct = async (status) => {
        const filterProductGallery = [];
        if (!isEmpty(productGalleryItems)) {
            productGalleryItems.map((item) => {
                filterProductGallery.push({
                    url: item?.url,
                    type: item?.type,
                });
            });
        }
        const filterProductResource = !isEmpty(resources)
            ? resources?.map((item) => item?.id)
            : [];
        const filterTags = !isEmpty(topicTags)
            ? topicTags?.map((item) => item?.key)
            : [];

        const inputs = {
            name,
            description: description,
            exhibitorCompanyProfileId,
            topicTags: filterTags,
            status,

            enableCta,
            ctaLink: sanitizeContent({ str: ctaLink }),
            ctaColor,
            ctaLabel,
            ctaBackgroundColor,

            custom1,
            custom2,
            custom3,
            custom4,
            custom5,
            productGalleryItems: filterProductGallery,
            resources: filterProductResource,
            projectId,
        };

        try {
            setIsLoading(true);
            const response = await CreateNewProduct(inputs);
            if (response.status) {
                openNotification('success', {
                    message: 'Product created!',
                });
                history.push(
                    `/company/${companyId}/project/${projectId}/products/`
                );
            } else throw response;
        } catch (error) {
            console.error('ERROR CreateNewProduct', error?.message);
            openNotification('error', {
                message: error?.message || 'Something went wrong',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const updateProductCode = async () => {
        const inputs = {
            name,
            code,
            projectId,
        };
        try {
            const response = await UpdateProduct(productId, inputs);
            if (response.status) {
                openNotification('success', {
                    message: 'Product code updated!',
                });
            } else throw response;
        } catch (error) {
            console.error('ERROR UpdateProduct', error);
            openNotification('error', {
                message: error?.message || 'Something went wrong',
            });
        }
    };

    const updateProduct = async (status) => {
        const filterProductGallery = [];
        if (!isEmpty(productGalleryItems)) {
            productGalleryItems.map((item) => {
                filterProductGallery.push({
                    url: item?.url,
                    type: item?.type,
                    id: item?.id,
                });
            });
        }
        const filterProductResource = !isEmpty(resources)
            ? resources?.map((item) => item?.id)
            : [];
        const filterTags = !isEmpty(topicTags)
            ? topicTags?.map((item) => item?.key)
            : [];

        const inputs = {
            name,
            code,
            description: description,
            exhibitorCompanyProfileId,
            topicTags: filterTags,
            status,

            enableCta,
            ctaLink: sanitizeContent({ str: ctaLink }),
            ctaColor,
            ctaLabel,
            ctaBackgroundColor,

            custom1,
            custom2,
            custom3,
            custom4,
            custom5,
            productGalleryItems: filterProductGallery,
            resources: filterProductResource,
            projectId,
        };

        try {
            const response = await UpdateProduct(productId, inputs);
            if (response.status) {
                openNotification('success', {
                    message: 'Product updated!',
                });
                setIsLoading(true);
                handleGetProduct();
                history.push(
                    `/company/${companyId}/project/${projectId}/products/`
                );
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR UpdateProduct', error);
            openNotification('error', {
                message: error?.message || 'Something went wrong!',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onClickRemoveItem = (removeItem) => {
        const productGalleryFinal = productGalleryItems.filter(
            (item) => item !== removeItem
        );
        setProductGalleryItems(productGalleryFinal);
    };
    const onClickRemoveResourceItem = (removeItem) => {
        handleDeleteResource(removeItem?.id);
        const resourcesFinal = resources.filter(
            (item) => item.id !== removeItem.id
        );
        setResources(resourcesFinal);
    };

    const renderMenus = (
        <Menu>
            <Menu.Item
                onClick={() =>
                    setIsVisibleDrawer({
                        visible: true,
                        item: DRAWER_TYPES.RESOURCE_PDF,
                    })
                }
            >
                Add PDF
            </Menu.Item>
            <Menu.Item
                onClick={() =>
                    setIsVisibleDrawer({
                        visible: true,
                        item: DRAWER_TYPES.RESOURCE_LINK,
                    })
                }
            >
                Add link
            </Menu.Item>
            <Menu.Item
                onClick={() =>
                    setIsVisibleDrawer({
                        visible: true,
                        item: DRAWER_TYPES.RESOURCE_IMAGE,
                    })
                }
            >
                Add image
            </Menu.Item>
        </Menu>
    );

    const renderGalleryMenus = (
        <Menu>
            <Menu.Item
                onClick={() =>
                    setIsVisibleDrawer({
                        visible: true,
                        item: DRAWER_TYPES.IMAGE,
                    })
                }
            >
                Add image
            </Menu.Item>
            <Menu.Item
                onClick={() =>
                    setIsVisibleDrawer({
                        visible: true,
                        item: DRAWER_TYPES.VIDEO,
                    })
                }
            >
                Add Video URL
            </Menu.Item>
        </Menu>
    );

    const renderDrawerTitle = (item) => {
        switch (item) {
            case DRAWER_TYPES.IMAGE:
                return 'Add Image';
            case DRAWER_TYPES.VIDEO:
                return 'Add Video Link';
            case DRAWER_TYPES.RESOURCE_LINK:
                return 'Add Link';
            case DRAWER_TYPES.RESOURCE_IMAGE:
                return 'Add Image';
            case DRAWER_TYPES.RESOURCE_PDF:
                return 'Add PDF';
            default:
                return 'Add Item';
        }
    };

    const onClickDrawerSave = (item) => {
        switch (item) {
            case DRAWER_TYPES.IMAGE:
                if (!isEmpty(currentImage)) {
                    setProductGalleryItems([
                        ...productGalleryItems,
                        {
                            name: currentImage,
                            url: currentImage,
                            imageUrl: currentImage,
                            id: currentImage,
                            type: 'image',
                        },
                    ]);
                }
                setCurrentImage(null);
                setIsVisibleDrawer({ visible: false });
                break;
            case DRAWER_TYPES.VIDEO:
                if (checkYoutubeOrVimeoLink(currentVideoUrl)) {
                    setProductGalleryItems([
                        ...productGalleryItems,
                        {
                            name: currentVideoUrl,
                            url: currentVideoUrl,
                            imageUrl: createVideoThumbnailURL(currentVideoUrl),
                            id: currentVideoUrl,
                            type: 'video',
                        },
                    ]);
                    setCurrentVideoUrl(null);
                    setIsVisibleDrawer({ visible: false });
                    setError('currentVideoUrl', {
                        type: 'manual',
                        message: null,
                    });
                } else {
                    setError('currentVideoUrl', {
                        type: 'manual',
                        message: 'Invalid URL',
                    });
                }
                break;
            case DRAWER_TYPES.RESOURCE_LINK:
                break;
            case DRAWER_TYPES.RESOURCE_IMAGE:
                break;
            case DRAWER_TYPES.RESOURCE_PDF:
                break;
            default:
                setIsVisibleDrawer({ visible: false });
                break;
        }
    };

    const onClickDrawerCancel = (item) => {
        switch (item) {
            case DRAWER_TYPES.IMAGE:
                setCurrentImage(null);
                break;
            case DRAWER_TYPES.VIDEO:
                setError('currentVideoUrl', {
                    type: 'manual',
                    message: null,
                });
                break;
            case DRAWER_TYPES.RESOURCE_LINK:
                break;
            case DRAWER_TYPES.RESOURCE_IMAGE:
                break;
            case DRAWER_TYPES.RESOURCE_PDF:
                break;
            default:
                break;
        }
        setIsVisibleDrawer({ visible: false });
    };

    return (
        <Layout disableSubMenuWidth className="tw-p-0" hideBreadcrumbs>
            {isLoading && <GlobalLoading />}
            {!isLoading && (
                <div className="container tw-bg-white sm:tw-bg-transparent tw-p-0 md:tw-py-4 ">
                    <div className="row md:tw-mt-5 tw-mt-0 tw-flex">
                        <div className="col-sm-8 align-items-center tw-hidden md:tw-flex">
                            <button
                                className="btn btn-secondary px-2 mr-2"
                                onClick={() =>
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/products/`
                                    )
                                }
                            >
                                <FontAwesomeIcon icon="chevron-left" />
                            </button>
                            <h1 className="mr-5 mb-0">
                                {name || 'Create New Product'}
                            </h1>
                        </div>
                        {/* Mobile View heading */}
                        <div className="col-sm-8 tw-flex-col tw-flex md:tw-hidden tw-gap-6 tw-p-5 tw-pb-0 tw-pt-8 tw-relative tw-justify-center tw-items-center">
                            <div className="tw-flex tw-flex-row tw-items-center">
                                <button
                                    className="btn btn-secondary tw-absolute tw-top-5 tw-right-5 tw-cursor-pointer"
                                    onClick={() =>
                                        history.push(
                                            `/company/${companyId}/project/${projectId}/products/`
                                        )
                                    }
                                >
                                    <CloseOutlined className="tw-text-base tw-font-bold" />
                                </button>
                            </div>
                            <h1 className="mb-0 tw-text-[26px] tw-font-semibold tw-text-mobileTextBlack">
                                {name || 'Create Product'}
                            </h1>
                        </div>
                        <div className="col-sm-4 justify-content-end align-items-center tw-hidden md:tw-flex">
                            <Select value={status} onChange={setStatus}>
                                <Option value="draft" disabled={isEmpty(name)}>
                                    Draft
                                </Option>
                                <Option
                                    value="publish"
                                    disabled={isEmpty(name)}
                                >
                                    Publish
                                </Option>
                            </Select>
                            <button
                                className="myxp btn btn-secondary mx-2"
                                onClick={() => handlePreviewProduct()}
                                disabled={isEmpty(name)}
                            >
                                Preview
                            </button>
                            <button
                                className="myxp btn btn-secondary"
                                style={{ width: 'auto' }}
                                onClick={() =>
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/products/`
                                    )
                                }
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => handleCreateProduct()}
                                className={`myxp btn btn-primary mr-1 `}
                                disabled={!status || isEmpty(name)}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                    <h2 className="mb-3 tw-mt-5 tw-hidden md:tw-block">
                        {' '}
                        <span className="tw-flex">Basic Details</span>
                    </h2>
                    <div className="tw-bg-white tw-px-3 tw-py-3 tw-pb-7">
                        <div className="bg-white p-4">
                            <div className="row tw-gap-4">
                                <div className="col">
                                    <div className="value">
                                        <label>
                                            Product name{' '}
                                            <span className="font-weight-bold text-danger">
                                                *
                                            </span>{' '}
                                        </label>
                                        <input
                                            className="form-control"
                                            name="name"
                                            placeholder="Product name"
                                            required
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}
                                            onBlur={(e) => {
                                                const productCode = `${kebabCase(
                                                    e.target.value
                                                )}-${Math.floor(
                                                    Math.random() * 9999
                                                )}`;
                                                if (isEmpty(previousCode)) {
                                                    setCode(productCode);
                                                    setPreviousCode(
                                                        productCode
                                                    );
                                                }
                                            }}
                                            ref={register({
                                                required:
                                                    'Product name is required',
                                                maxLength: {
                                                    value: 150,
                                                    message:
                                                        'Product name exceed max length(150)',
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message:
                                                        'Product name min length(3)',
                                                },
                                            })}
                                        />
                                        {errors.name && (
                                            <div className="invalid-feedback d-block">
                                                {errors.name?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {productId && (
                                    <div className="col-6">
                                        <div className="value">
                                            <label>Product Code</label>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    className="form-control"
                                                    name="code"
                                                    required
                                                    value={code}
                                                    onChange={(e) => {
                                                        setCode(e.target.value);
                                                    }}
                                                    ref={register({
                                                        required:
                                                            'Product code is required',
                                                        maxLength: {
                                                            value: 90,
                                                            message:
                                                                'Product code exceed max length(90)',
                                                        },
                                                        minLength: {
                                                            value: 3,
                                                            message:
                                                                'Product code min length(3)',
                                                        },
                                                    })}
                                                    disabled={!isChangeCode}
                                                />
                                                <CopyOutlined
                                                    onClick={() => {
                                                        copy(code);
                                                        openNotification(
                                                            'success',
                                                            {
                                                                message:
                                                                    'Copied to clipboard!',
                                                            }
                                                        );
                                                    }}
                                                    className="ml-2"
                                                    style={{ fontSize: '20px' }}
                                                />
                                            </div>
                                            {!isChangeCode && (
                                                <a
                                                    onClick={() =>
                                                        setIsChangeCode(true)
                                                    }
                                                    className="btn-link"
                                                >
                                                    Change
                                                </a>
                                            )}
                                            {isChangeCode && (
                                                <div>
                                                    <a
                                                        onClick={() => {
                                                            setCode(
                                                                previousCode
                                                            );
                                                            setIsChangeCode(
                                                                false
                                                            );
                                                        }}
                                                        className="btn-link mr-3"
                                                    >
                                                        Cancel
                                                    </a>
                                                    <a
                                                        onClick={() => {
                                                            setPreviousCode(
                                                                code
                                                            );
                                                            setIsChangeCode(
                                                                false
                                                            );
                                                            updateProductCode();
                                                        }}
                                                        className="btn-link"
                                                    >
                                                        Save
                                                    </a>
                                                </div>
                                            )}
                                            {errors.code && (
                                                <div className="invalid-feedback d-block">
                                                    {errors.code?.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="col-12">
                                    <div className="value">
                                        <label>Description</label>
                                        <RichTextEditor
                                            placeholderText="Enter what is the company about"
                                            id="description"
                                            model={description}
                                            onModelChange={setDescription}
                                            allowOnlyLineBreaks
                                            htmlAllowedTags={[
                                                'br',
                                                'span',
                                                'p',
                                                'div',
                                                'iframe',
                                                'a',
                                            ]}
                                            heightMin={75}
                                            charCounterMax={1000}
                                            className="tw-text-sm tw-text-black"
                                        />
                                        <small className="form-text text-muted">
                                            Max character limit-1000
                                        </small>
                                        {errors.description && (
                                            <div className="invalid-feedback d-block">
                                                {errors.description?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {isEmpty(user?.exhibitorCompanyProfileId) && (
                                    <div className="col-12">
                                        <div className="value">
                                            <label>Sponsor</label>
                                            <SearchSelectInput
                                                fetchOptions={
                                                    handleGetCompanies
                                                }
                                                onChange={(newValue) => {
                                                    if (
                                                        !isUndefined(newValue)
                                                    ) {
                                                        setExhibitorCompanyProfileId(
                                                            newValue
                                                        );
                                                    } else {
                                                        setExhibitorCompanyProfileId(
                                                            ''
                                                        );
                                                    }
                                                }}
                                                showArrow={false}
                                                showSearch
                                                filterOption={false}
                                                allowClear
                                                withLogoOptions
                                                size="large"
                                                placeholder="Search for sponsor"
                                                value={
                                                    exhibitorCompanyProfileId
                                                }
                                                // labelInValue={true}
                                            />
                                            {errors.exhibitorCompanyProfile && (
                                                <div className="invalid-feedback d-block">
                                                    {
                                                        errors
                                                            .exhibitorCompanyProfile
                                                            ?.message
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {hasTagInput && (
                                    <div className="col-12">
                                        <div className="value">
                                            <label>Tags</label>
                                            <Select
                                                labelInValue
                                                allowClear
                                                onChange={setTopicTags}
                                                options={allTopicsTags}
                                                value={topicTags}
                                                placeholder="Choose Topic tags"
                                                optionFilterProp="label"
                                                className="tw-text-base"
                                                notFoundContent={
                                                    <p className="tw-text-black">
                                                        No data
                                                    </p>
                                                }
                                                filterOption={false}
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                tagRender={(props) => {
                                                    return (
                                                        <Tag
                                                            {...props}
                                                            className="badge badge-xpmanager font-weight-normal border-0 tw-text-base"
                                                        >
                                                            {props?.label}
                                                        </Tag>
                                                    );
                                                }}
                                                dropdownRender={(menu) => (
                                                    <>
                                                        {menu}

                                                        <div className="tw-w-full tw-mt-4 tw-mb-2 tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center ">
                                                            <p className="tw-p-0 tw-m-0 tw-bg-gray-200 tw-w-11/12 tw-h-px" />

                                                            <button
                                                                className={`${
                                                                    isDisabled
                                                                        ? 'tw-bg-black/70'
                                                                        : 'tw-bg-black tw-border-black'
                                                                } tw-rounded tw-text-white tw-text-xs tw-font-semibold tw-font-montserrat tw-px-3 tw-py-1.5 tw-border-solid tw-border`}
                                                                onClick={() => {
                                                                    if (
                                                                        tagGroupsPaginationData?.currentPage !==
                                                                            tagGroupsPaginationData?.totalPage &&
                                                                        tagGroupsPaginationData?.totalPage !==
                                                                            0
                                                                    ) {
                                                                        handleGetTopicTagGroups(
                                                                            tagGroupsPaginationData?.currentPage +
                                                                                1
                                                                        );
                                                                    } else {
                                                                        handleGetTopics(
                                                                            (tagsPaginationData?.currentPage ||
                                                                                0) +
                                                                                1
                                                                        );
                                                                    }
                                                                }}
                                                                disabled={
                                                                    isDisabled
                                                                }
                                                            >
                                                                Load more
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            />
                                            {errors.topicTags && (
                                                <div className="invalid-feedback d-block">
                                                    {errors.topicTags?.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="col-12">
                                    <div className="value">
                                        <label>Product Gallery </label>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={renderGalleryMenus}
                                        >
                                            <div className="myxp btn btn-secondary w-100 ml-0">
                                                <PlusIcon />
                                                <span className="ml-1">
                                                    Add
                                                </span>
                                            </div>
                                        </Dropdown>
                                    </div>
                                    <span>
                                        Note: Adding product images here will
                                        appear on the product preview of the
                                        page.
                                    </span>
                                    {!isEmpty(productGalleryItems) && (
                                        <DraggableList
                                            listData={productGalleryItems}
                                            onReordered={setProductGalleryItems}
                                            visibilityKey="blockVisibility"
                                            visibilityIcon="close"
                                            onVisibilityChange={(e, data) =>
                                                onClickRemoveItem(data)
                                            }
                                            onItemClicked={(e) => null}
                                            trimNames
                                        />
                                    )}
                                </div>

                                <div className="col-12">
                                    <div className="value">
                                        <label>Resources</label>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={renderMenus}
                                        >
                                            <div className="myxp btn btn-secondary w-100 ml-0">
                                                <PlusIcon />
                                                <span className="ml-1">
                                                    Add Resource
                                                </span>
                                            </div>
                                        </Dropdown>
                                    </div>
                                    <span>
                                        Note: Adding product specification PDF,
                                        links & images here will appear on the
                                        product preview of the page.
                                    </span>
                                    <Items
                                        items={resources?.map((item) => {
                                            return {
                                                name: item?.label,
                                                imageUrl: item?.icon,
                                                ...item,
                                            };
                                        })}
                                        onDeleteItem={(item) =>
                                            onClickRemoveResourceItem(item)
                                        }
                                        onReorderImages={setResources}
                                        setVisible={(e) => null}
                                        submitted={false}
                                        hidden
                                        containerStyle={{ padding: 0 }}
                                        contentStyle={{ border: 0 }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white px-4 pb-2 mt-3">
                            <div className="row">
                                <div className="col-12">
                                    <SwitchComponent
                                        label="Show CTA button"
                                        switchStatus={enableCta}
                                        onChange={(e) => {
                                            setEnableCta(e);
                                            if (!e) {
                                                setCtaLinkError(false);
                                                setctaLink('');
                                            }
                                        }}
                                        background="#fff"
                                        padding={0}
                                    />
                                    <hr className="mt-0" />
                                    {!enableCta && (
                                        <span>
                                            Note: Enable custom CTA for your
                                            product{' '}
                                        </span>
                                    )}
                                </div>
                                <div className="col-12">
                                    <Collapse
                                        activeKey={enableCta ? ['1'] : []}
                                        accordion
                                        collapsible="disabled"
                                        className="panel-CTA-content"
                                        ghost
                                    >
                                        <Panel showArrow={false} key="1">
                                            <div className="value tw-mb-4">
                                                <label>
                                                    Link{' '}
                                                    <span className="font-weight-bold text-danger">
                                                        *
                                                    </span>{' '}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="ctaLink"
                                                    value={ctaLink}
                                                    placeholder="https://"
                                                    onChange={(e) => {
                                                        setctaLink(
                                                            e.target.value
                                                        );
                                                        setCtaLinkError(
                                                            !isValidUrl(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                    ref={register({
                                                        required: {
                                                            value: enableCta,
                                                            message:
                                                                'CTA Link is required',
                                                        },
                                                        pattern: {
                                                            value: /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm,
                                                            message:
                                                                'URL invalid',
                                                        },
                                                    })}
                                                />
                                                <small className="form-text text-muted">
                                                    Please provide full URL
                                                    starts with https://
                                                </small>

                                                {errors.ctaLink ||
                                                    (ctaLinkError && (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.ctaLink
                                                                ?.message ||
                                                                'Invalid URL'}
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className="value tw-mb-4">
                                                <label>CTA Label</label>
                                                <input
                                                    className="form-control"
                                                    name="ctaLabel"
                                                    defaultValue={ctaLabel}
                                                    onChange={(e) => {
                                                        setctaLabel(
                                                            e.target.value
                                                        );
                                                    }}
                                                    ref={register({
                                                        required: enableCta
                                                            ? 'CTA Label is required'
                                                            : false,
                                                        maxLength: {
                                                            value: 50,
                                                            message:
                                                                'CTA Label exceed max length(50)',
                                                        },
                                                        minLength: {
                                                            value: 2,
                                                            message:
                                                                'CTA Label min length(2)',
                                                        },
                                                    })}
                                                />
                                                {errors.ctaLabel && (
                                                    <div className="invalid-feedback d-block">
                                                        {
                                                            errors.ctaLabel
                                                                ?.message
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                            <div className="value tw-mb-4">
                                                <label>
                                                    CTA Background Color
                                                </label>
                                                <div className="input-group mt-2">
                                                    <input
                                                        type="color"
                                                        className="input--color"
                                                        value={
                                                            ctaBackgroundColor
                                                        }
                                                        name="ctaBackgroundColor"
                                                        onChange={(e) =>
                                                            setctaBackgroundColor(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control input--color_content cta-color-input"
                                                        value={
                                                            ctaBackgroundColor
                                                        }
                                                        name="buttonColor"
                                                        onChange={(e) =>
                                                            setctaBackgroundColor(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="value ">
                                                <label>CTA Text Color</label>
                                                <div className="input-group mt-2">
                                                    <input
                                                        type="color"
                                                        className="input--color"
                                                        value={ctaColor}
                                                        name="ctaColor"
                                                        onChange={(e) =>
                                                            setctaColor(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control input--color_content cta-color-input"
                                                        value={ctaColor}
                                                        name="ctaColor"
                                                        onChange={(e) =>
                                                            setctaColor(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white p-4 mt-3">
                            <div className="row tw-gap-4">
                                <div className="tw-w-full tw-max-w-md w-font-openSans tw-px-4">
                                    <span className="tw-text-[14.4px] tw-text-[#344054] tw-font-semibold ">
                                        Custom Field
                                    </span>
                                    <p className="tw-text-[13px] tw-text-[#787C83] tw-font-inter tw-mb-0 tw-mt-3">
                                        Custom Field can be pre-defined and set
                                        up with GEVME's assistance, please
                                        contact our Support at
                                        <a
                                            href="mailto:cs.virtual@gevme.com"
                                            className="tw-ml-1 tw-text-[#787C83] tw-hover:underline"
                                        >
                                            cs.virtual@gevme.com
                                        </a>
                                    </p>
                                </div>

                                <div className="col-12">
                                    <div className="value">
                                        <label>Custom Field 1</label>
                                        <input
                                            className="form-control"
                                            name="custom1"
                                            required
                                            onChange={(e) => {
                                                setCustom1(e.target.value);
                                            }}
                                            defaultValue={custom1}
                                        />
                                        {errors.custom1 && (
                                            <div className="invalid-feedback d-block">
                                                {errors.custom1?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="value">
                                        <label>Custom Field 2</label>
                                        <input
                                            className="form-control"
                                            name="custom2"
                                            required
                                            onChange={(e) => {
                                                setCustom2(e.target.value);
                                            }}
                                            defaultValue={custom2}
                                        />

                                        {errors.custom2 && (
                                            <div className="invalid-feedback d-block">
                                                {errors.custom2?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="value">
                                        <label>Custom Field 3</label>
                                        <input
                                            className="form-control"
                                            name="custom3"
                                            required
                                            onChange={(e) => {
                                                setCustom3(e.target.value);
                                            }}
                                            defaultValue={custom3}
                                        />
                                        {errors.custom3 && (
                                            <div className="invalid-feedback d-block">
                                                {errors.custom3?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="value">
                                        <label>Custom Field 4</label>
                                        <input
                                            className="form-control"
                                            name="custom4"
                                            required
                                            onChange={(e) => {
                                                setCustom4(e.target.value);
                                            }}
                                            defaultValue={custom4}
                                        />
                                        {errors.custom4 && (
                                            <div className="invalid-feedback d-block">
                                                {errors.custom4?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="value">
                                        <label>Custom Field 5</label>
                                        <input
                                            className="form-control"
                                            name="custom5"
                                            required
                                            onChange={(e) => {
                                                setCustom5(e.target.value);
                                            }}
                                            defaultValue={custom5}
                                        />
                                        {errors.custom5 && (
                                            <div className="invalid-feedback d-block">
                                                {errors.custom5?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 tw-justify-start tw-text-sm align-items-center tw-flex md:tw-hidden tw-mt-4 !tw-text-sm">
                            <button
                                className="myxp btn btn-secondary tw-font-semibold tw-min-h-[40px]"
                                style={{ width: 'auto' }}
                                onClick={() =>
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/products/`
                                    )
                                }
                            >
                                Cancel
                            </button>
                            <div className="tw-flex tw-gap-2 ">
                                <button
                                    className="myxp btn btn-secondary mx-2 tw-font-semibold tw-min-h-[40px] tw-w-fit"
                                    onClick={() => {
                                        setStatus('draft');
                                        handleCreateProductMobile('draft');
                                    }}
                                    disabled={isEmpty(name)}
                                >
                                    Save as draft
                                </button>
                                <button
                                    onClick={() => {
                                        setStatus('publish');
                                        handleCreateProductMobile('publish');
                                    }}
                                    className="myxp btn btn-primary mr-1 tw-font-semibold tw-min-h-[40px]"
                                    disabled={isEmpty(name)}
                                >
                                    Publish
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <DrawerComponent
                title={renderDrawerTitle(isVisibleDrawer?.item)}
                visible={isVisibleDrawer.visible}
                placement="right"
                style={{ top: 0 }}
                mask
            >
                {isVisibleDrawer?.item === DRAWER_TYPES.RESOURCE_LINK && (
                    <AddItem
                        visible={isVisibleDrawer}
                        setVisible={setIsVisibleDrawer}
                        setResource={(resourceId) => {
                            setIsVisibleDrawer({ visible: false });
                            handleGetResource(resourceId);
                        }}
                        formStyle={{ paddingBottom: 0 }}
                        projectId={projectId}
                    />
                )}
                {isVisibleDrawer?.item === DRAWER_TYPES.RESOURCE_IMAGE && (
                    <AddItem
                        visible={isVisibleDrawer}
                        setVisible={setIsVisibleDrawer}
                        setResource={(resourceId) => {
                            setIsVisibleDrawer({ visible: false });
                            handleGetResource(resourceId);
                        }}
                        formStyle={{ paddingBottom: 0 }}
                        projectId={projectId}
                    />
                )}
                {isVisibleDrawer?.item === DRAWER_TYPES.RESOURCE_PDF && (
                    <AddItem
                        visible={isVisibleDrawer}
                        setVisible={setIsVisibleDrawer}
                        setResource={(resourceId) => {
                            setIsVisibleDrawer({ visible: false });
                            handleGetResource(resourceId);
                        }}
                        formStyle={{ paddingBottom: 0 }}
                        projectId={projectId}
                    />
                )}
                {isVisibleDrawer?.item === DRAWER_TYPES.IMAGE && (
                    <>
                        <ContentLayout
                            title={renderDrawerTitle(isVisibleDrawer?.item)}
                            onBack={() =>
                                setIsVisibleDrawer({ visible: false })
                            }
                            customStyle={{ height: 'calc(100% - 65px)' }}
                        >
                            <div className="p-3">
                                <ImageUpload
                                    previewImages={currentImage}
                                    prepareFilesUpload={(files) => {
                                        setCurrentImage(files[0]);
                                    }}
                                    handleFileDelete={(file) => {
                                        setCurrentImage(null);
                                    }}
                                    autoUpload
                                    withoutRedux
                                    projectId={projectId}
                                    imageCropAspectRatio={
                                        IMAGE_CROP_ASPECT_RATIO
                                    }
                                />
                            </div>
                        </ContentLayout>
                        <BlockFooter
                            onCancel={() => {
                                onClickDrawerCancel(isVisibleDrawer?.item);
                            }}
                            disabledSave={false}
                            onSave={() => {
                                onClickDrawerSave(isVisibleDrawer?.item);
                            }}
                        />
                    </>
                )}
                {isVisibleDrawer?.item === DRAWER_TYPES.VIDEO && (
                    <>
                        <ContentLayout
                            title={renderDrawerTitle(isVisibleDrawer?.item)}
                            onBack={() =>
                                setIsVisibleDrawer({ visible: false })
                            }
                            customStyle={{ height: 'calc(100% - 65px)' }}
                        >
                            <div className="p-3">
                                <div className="value">
                                    <label>Video preview</label>
                                    <div className="video-preview-container">
                                        {!checkYoutubeOrVimeoLink(
                                            currentVideoUrl
                                        ) && <span>Preview</span>}
                                        {currentVideoUrl && (
                                            <img
                                                src={createVideoThumbnailURL(
                                                    currentVideoUrl
                                                )}
                                                className="img-fluid"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="value">
                                    <label>Link</label>
                                    <input
                                        className="form-control"
                                        name="currentVideoUrl"
                                        defaultValue={currentVideoUrl}
                                        placeholder="Paste video link of your product  "
                                        onChange={(e) => {
                                            setCurrentVideoUrl(e.target.value);
                                        }}
                                    />
                                    <small className="form-text text-muted">
                                        Only YouTube and Vimeo, Example;
                                        https://www.youtube.com/watch?v=OvrqAw
                                        https://vimeo.com/579105618
                                    </small>
                                    {errors?.currentVideoUrl && (
                                        <div className="invalid-feedback d-block">
                                            {errors?.currentVideoUrl?.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ContentLayout>
                        <BlockFooter
                            onCancel={() => {
                                onClickDrawerCancel(isVisibleDrawer?.item);
                            }}
                            disabledSave={false}
                            onSave={() => {
                                onClickDrawerSave(isVisibleDrawer?.item);
                            }}
                        />
                    </>
                )}
            </DrawerComponent>
            <PreviewModal
                visible={isPreviewModalVisible}
                setVisible={setIsPreviewModalVisible}
                data={{
                    name,
                    description,
                    exhibitorCompanyProfileId,
                    topicTags: !isEmpty(topicTags)
                        ? topicTags.map((item) => item?.label)
                        : [],
                    productGalleryItems: !isEmpty(productGalleryItems)
                        ? productGalleryItems.map((item) => item?.url)
                        : [],
                    resources,
                    custom1,
                    custom2,
                    custom3,
                    custom4,
                    custom5,
                    enableCta,
                    ctaLabel,
                    ctaLink,
                    ctaBackgroundColor,
                    ctaColor,
                }}
            />

            <SidebarContainer>
                <MainMenu priActive="products" all />
            </SidebarContainer>
        </Layout>
    );
}

CreateProduct.propTypes = {};

export default CreateProduct;
