import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs, Select, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';

import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import Prompt from 'components/app/common/prompt';
import useIsMobile from 'hooks/useIsMobile';

import { GetPolls } from 'apis/rest/polls/GetPolls';
import { UpdatePollStatus } from 'apis/rest/polls/UpdatePollStatus';
import { DeletePoll } from 'apis/rest/polls/DeletePoll';
import { DuplicatePoll } from 'apis/rest/polls/DuplicatePoll';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import RadioGroupButtons from 'ComponentsV2/CommonComponents/MobileView/radioGroup/RadioGroupButtons';
import LiveTable from './tables/liveTable';
import ScheduledTable from './tables/scheduledTable';
import EndedTable from './tables/endedTable';
import DraftTable from './tables/draftTable';

import ScheduleModal from './createPoll/scheduleModal';

import './polls.scss';

import Layout from '../../../../common/layout';
import CancelScheduleModal from '../mobileComponents/CancelScheduleModal';
import useScreenSize from 'components/app/utils/hooks/useScreenSize/useScreenSize';
import ScheduleModalMobile from './createPoll/scheduleModal/ScheduleModalMobile';

const { TabPane } = Tabs;
const options = [
    {
        label: 'Live',
        value: 'live',
    },
    {
        label: 'Scheduled',
        value: 'queued',
    },
    {
        label: 'Ended',
        value: 'end',
    },
    {
        label: 'Draft',
        value: 'created',
    },
];
export const getFullname = (data) => {
    if (!data) return '';
    return `${data?.firstname} ${data?.lastname}`;
};

function NotificationPolls() {
    const history = useHistory();
    const isMobile = useIsMobile();
    const { projectId, companyId } = useParams();
    const deviceType = useScreenSize();
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const [isLoading, setIsLoading] = useState(false);
    const [polls, setPolls] = useState(null);
    const [currentTab, setCurrentTab] = useState('live');
    const [pollTableCurrentPage, setPollTableCurrentPage] = useState(1);
    const [pollTableSearchText, setPollTableSearchText] = useState('');
    const [sorter, setSorter] = useState({
        sortBy: 'question',
        sortOrder: 'ASC',
    });

    const [notificationSendNow, setNotificationSendNow] = useState(true);
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
    const [selectedNotificationData, setSelectedNotificationData] =
        useState(null);

    const [showEndPollPrompt, setShowEndPollPrompt] = useState(false);
    const [showCancelPollPrompt, setShowCancelPollPrompt] = useState(false);
    const [showDeletePollPrompt, setShowDeletePollPrompt] = useState(false);
    const [showDuplicatePollPrompt, setShowDuplicatePollPrompt] =
        useState(false);

    const [endPollId, setEndPollId] = useState('');
    const [cancelPollId, setCancelPollId] = useState('');
    const [deletePollId, setDeletePollId] = useState('');
    const [duplicatePollId, setDuplicatePollId] = useState('');
    const [scheduleDate, setScheduleDate] = useState(
        moment().tz(timezone).add(10, 'minutes')
    );

    useEffect(() => {
        setScheduleDate(moment().tz(timezone).add(10, 'minutes'));
    }, [timezone]);

    useEffect(() => {
        handleGetPolls();
    }, [pollTableCurrentPage, pollTableSearchText, currentTab, sorter]);

    const handleGetPolls = async () => {
        setIsLoading(true);
        try {
            const response = await GetPolls(
                projectId,
                currentTab,
                '',
                pollTableCurrentPage,
                pollTableSearchText,
                timezone.toLowerCase(),
                sorter?.sortBy,
                sorter?.sortOrder
            );
            if (response.status) {
                if (isEmpty(response.polls)) {
                    if (response.pagination.currentPage > 1)
                        setPollTableCurrentPage(1);
                }
                setPolls(response);
            } else {
                openNotification('error', { message: response?.message });
            }
        } catch (error) {
            console.error('ERROR handleGetNotifications', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePreEnd = (pollId) => {
        setShowEndPollPrompt(true);
        setEndPollId(pollId);
    };

    const handlePreCancel = (pollId) => {
        setShowCancelPollPrompt(true);
        setCancelPollId(pollId);
    };

    const handlePreDuplicate = (pollId) => {
        setShowDuplicatePollPrompt(true);
        setDuplicatePollId(pollId);
    };

    const handlePreDelete = (pollId) => {
        setShowDeletePollPrompt(true);
        setDeletePollId(pollId);
    };

    // Close prompt for delete and duplicate
    const handleClosePrompt = (type) => {
        if (type === 'end') {
            setShowEndPollPrompt(false);
            setEndPollId('');
        }
        if (type === 'cancel') {
            setShowCancelPollPrompt(false);
            setCancelPollId('');
        }
        if (type === 'delete') {
            setShowDeletePollPrompt(false);
            setDeletePollId('');
        }
        if (type === 'duplicate') {
            setShowDuplicatePollPrompt(false);
            setDuplicatePollId('');
        }
    };

    const onClickEndPoll = async (pollId) => {
        try {
            const response = await UpdatePollStatus(pollId, 'end', projectId);
            if (response.status) {
                handleGetPolls();
                openNotification('success', {
                    message: 'Poll has been ended.',
                });
            } else {
                openNotification('error', {
                    message: 'An error was encountered please try again',
                });
            }
        } catch (error) {
            console.error('ERROR onClickEndPoll', error);
            openNotification('error', {
                message: 'An error was encountered please try again',
            });
        } finally {
            setShowEndPollPrompt(false);
        }
    };

    const onClickCancelPoll = async (pollId) => {
        try {
            const response = await UpdatePollStatus(
                pollId,
                'unpublish',
                projectId
            );
            if (response.status) {
                handleGetPolls();
                openNotification('success', {
                    message: 'Scheduled has been cancelled.',
                });
            } else {
                openNotification('error', {
                    message: 'An error was encountered please try again',
                });
            }
        } catch (error) {
            console.error('ERROR onClickCancelPoll', error);
            openNotification('error', {
                message: 'An error was encountered please try again',
            });
        } finally {
            setShowCancelPollPrompt(false);
        }
    };

    const onClickDeletePoll = async (pollId) => {
        try {
            const response = await DeletePoll(pollId, projectId);
            if (response.status) {
                handleGetPolls();
                openNotification('success', {
                    message: 'Poll has been deleted.',
                });
            } else {
                openNotification('error', {
                    message: 'An error was encountered please try again',
                });
            }
        } catch (error) {
            console.error('ERROR onClickDeletePoll', error);
            openNotification('error', {
                message: 'An error was encountered please try again',
            });
        } finally {
            setShowDeletePollPrompt(false);
        }
    };

    const onClickDuplicatePoll = async (pollId) => {
        try {
            const response = await DuplicatePoll(pollId, projectId);
            if (response.status) {
                handleGetPolls();
                openNotification('success', {
                    message: 'Poll has been duplicated.',
                });
            } else {
                openNotification('error', {
                    message: 'An error was encountered please try again',
                });
            }
        } catch (error) {
            console.error('ERROR onClickDuplicatePoll', error);
            openNotification('error', {
                message: 'An error was encountered please try again',
            });
        } finally {
            setShowDuplicatePollPrompt(false);
        }
    };

    const tabsMobileView = () => {
        const onChange3 = (e) => {
            const tabKey = e.target.value;
            if (tabKey === currentTab) return;
            setCurrentTab(tabKey);
        };
        const renderTabPannel = (key) => {
            switch (key) {
                case 'live':
                    return (
                        <LiveTable
                            data={polls}
                            loading={isLoading}
                            pagination={(page) => {
                                setPollTableSearchText(pollTableSearchText);
                                setPollTableCurrentPage(page);
                            }}
                            search={(searchText) => {
                                setPollTableCurrentPage(1);
                                setPollTableSearchText(searchText);
                            }}
                            onClickEndPoll={handlePreEnd}
                            setSorter={setSorter}
                        />
                    );
                case 'queued':
                    return (
                        <ScheduledTable
                            data={polls}
                            loading={isLoading}
                            pagination={(page) => {
                                setPollTableSearchText(pollTableSearchText);
                                setPollTableCurrentPage(page);
                            }}
                            search={(searchText) => {
                                setPollTableCurrentPage(1);
                                setPollTableSearchText(searchText);
                            }}
                            onClickCancelPoll={handlePreCancel}
                            onClickDuplicatePoll={handlePreDuplicate}
                            setSorter={setSorter}
                        />
                    );

                case 'end':
                    return (
                        <EndedTable
                            data={polls}
                            loading={isLoading}
                            pagination={(page) => {
                                setPollTableSearchText(pollTableSearchText);
                                setPollTableCurrentPage(page);
                            }}
                            search={(searchText) => {
                                setPollTableCurrentPage(1);
                                setPollTableSearchText(searchText);
                            }}
                            onClickDuplicatePoll={handlePreDuplicate}
                            onClickDeletePoll={handlePreDelete}
                            setSorter={setSorter}
                        />
                    );
                case 'created':
                    return (
                        <DraftTable
                            data={polls}
                            loading={isLoading}
                            pagination={(page) => {
                                setPollTableSearchText(pollTableSearchText);
                                setPollTableCurrentPage(page);
                            }}
                            search={(searchText) => {
                                setPollTableCurrentPage(1);
                                setPollTableSearchText(searchText);
                            }}
                            sendNotification={(type, item) => {
                                setIsScheduleModalVisible(true);
                                setNotificationSendNow(type === 'sendNow');
                                setSelectedNotificationData(item);
                            }}
                            onClickDuplicatePoll={handlePreDuplicate}
                            onClickDeletePoll={handlePreDelete}
                            setSorter={setSorter}
                        />
                    );
                default:
                    break;
            }
        };
        return (
            <>
                <div className="tw-bg-white tw-gap-4 tw-flex tw-flex-col tw-w-full md:tw-hidden tw-mt-8 tw-h-[85vh]">
                    {/* Radio  */}
                    <div className="tw-p-4 tw-pb-0 tw-w-full">
                        <RadioGroupButtons
                            changeHandler={onChange3}
                            options={options}
                            currentValue={currentTab}
                        />
                    </div>
                    {/* Timezone  */}
                    <div className="tw-w-full">
                        <TimezoneSelector
                            selectStyle={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '600',
                                color: '#101828',
                                fontFamily: 'Open Sans',
                            }}
                            selectClassnames="selectMobileView"
                            wrapperClass="wrapperMobileView tw-p-4 tw-py-0"
                        />
                    </div>
                    {/* tabs  */}
                    {isLoading && <GlobalLoading />}
                    {renderTabPannel(currentTab)}
                </div>
            </>
        );
    };

    return (
        <Layout disableSubMenuWidth className="tw-p-0">
            {deviceType !== 'mobile' && (
                <>
                    <Prompt
                        show={showEndPollPrompt}
                        message="Are you sure you want to end of this poll?"
                        buttons={['Cancel', 'Proceed to End']}
                        onHide={() => handleClosePrompt('end')}
                        callback={() => onClickEndPoll(endPollId)}
                    />
                    <Prompt
                        show={showCancelPollPrompt}
                        message="Are you sure you want to cancel of this poll?"
                        buttons={['Cancel', 'Proceed to Cancel']}
                        onHide={() => handleClosePrompt('cancel')}
                        callback={() => onClickCancelPoll(cancelPollId)}
                    />
                    <Prompt
                        show={showDeletePollPrompt}
                        message="Are you sure you want to delete of this poll?"
                        buttons={['Cancel', 'Proceed to Delete']}
                        onHide={() => handleClosePrompt('delete')}
                        callback={() => onClickDeletePoll(deletePollId)}
                    />
                    <Prompt
                        show={showDuplicatePollPrompt}
                        message="Are you sure you want to duplicate of this poll?"
                        buttons={['Cancel', 'Proceed to Duplicate']}
                        onHide={() => handleClosePrompt('duplicate')}
                        callback={() => onClickDuplicatePoll(duplicatePollId)}
                    />
                </>
            )}
            {deviceType === 'mobile' && (
                <>
                    <CancelScheduleModal
                        isVisible={showEndPollPrompt}
                        onCancel={() => handleClosePrompt('end')}
                        onConfirm={() => onClickEndPoll(endPollId)}
                        confirmButtonText={'Proceed to End'}
                        heading={'End poll'}
                        description={
                            'Are you sure you want to end of this poll?'
                        }
                    />
                    <CancelScheduleModal
                        isVisible={showCancelPollPrompt}
                        onCancel={() => handleClosePrompt('cancel')}
                        onConfirm={() => onClickCancelPoll(cancelPollId)}
                        confirmButtonText={'Proceed to Cancel'}
                        heading={'Cancel poll'}
                        description={
                            'Are you sure you want to cancel of this poll?'
                        }
                    />
                    <CancelScheduleModal
                        isVisible={showDeletePollPrompt}
                        onCancel={() => handleClosePrompt('delete')}
                        onConfirm={() => onClickDeletePoll(deletePollId)}
                        confirmButtonText={'Proceed to Delete'}
                        heading={'Delete poll'}
                        description={
                            'Are you sure you want to delete of this poll?'
                        }
                    />
                    <CancelScheduleModal
                        isVisible={showDuplicatePollPrompt}
                        onCancel={() => handleClosePrompt('duplicate')}
                        onConfirm={() => onClickDuplicatePoll(duplicatePollId)}
                        confirmButtonText={'Proceed to Duplicate'}
                        heading={'Duplicate poll'}
                        description={
                            'Are you sure you want to duplicate of this poll?'
                        }
                    />
                </>
            )}
            <div className="container polls">
                <div className="row">
                    <div className="col-6">
                        <h1 className="my-3 tw-hidden md:tw-block">Polls</h1>
                    </div>

                    <div className="col-6 md:tw-block tw-hidden">
                        <button
                            className="myxp btn btn-primary ml-auto my-3"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/notification/create-poll`
                                )
                            }
                        >
                            New Poll
                        </button>
                    </div>

                    {isMobile ? (
                        tabsMobileView()
                    ) : (
                        <div className="col-12 md:tw-block tw-hidden">
                            <TimezoneSelector
                                selectStyle={{
                                    width: 150,
                                    zIndex: 10,
                                    right: 20,
                                }}
                            />
                            <Tabs
                                className="bg-white py-3 border border-radius sponsors-tabs"
                                defaultActiveKey={currentTab}
                                activeKey={currentTab}
                                onChange={(tabKey) => {
                                    setCurrentTab(tabKey);
                                }}
                            >
                                <TabPane tab="Live" key="live">
                                    <LiveTable
                                        data={polls}
                                        loading={isLoading}
                                        pagination={(page) => {
                                            setPollTableSearchText(
                                                pollTableSearchText
                                            );
                                            setPollTableCurrentPage(page);
                                        }}
                                        search={(searchText) => {
                                            setPollTableCurrentPage(1);
                                            setPollTableSearchText(searchText);
                                        }}
                                        onClickEndPoll={handlePreEnd}
                                        setSorter={setSorter}
                                    />
                                </TabPane>
                                <TabPane tab="Scheduled" key="queued">
                                    <ScheduledTable
                                        data={polls}
                                        loading={isLoading}
                                        pagination={(page) => {
                                            setPollTableSearchText(
                                                pollTableSearchText
                                            );
                                            setPollTableCurrentPage(page);
                                        }}
                                        search={(searchText) => {
                                            setPollTableCurrentPage(1);
                                            setPollTableSearchText(searchText);
                                        }}
                                        onClickCancelPoll={handlePreCancel}
                                        onClickDuplicatePoll={
                                            handlePreDuplicate
                                        }
                                        setSorter={setSorter}
                                    />
                                </TabPane>
                                <TabPane tab="Ended" key="end">
                                    <EndedTable
                                        data={polls}
                                        loading={isLoading}
                                        pagination={(page) => {
                                            setPollTableSearchText(
                                                pollTableSearchText
                                            );
                                            setPollTableCurrentPage(page);
                                        }}
                                        search={(searchText) => {
                                            setPollTableCurrentPage(1);
                                            setPollTableSearchText(searchText);
                                        }}
                                        onClickDuplicatePoll={
                                            handlePreDuplicate
                                        }
                                        onClickDeletePoll={handlePreDelete}
                                        setSorter={setSorter}
                                    />
                                </TabPane>
                                <TabPane tab="Draft" key="created">
                                    <DraftTable
                                        data={polls}
                                        loading={isLoading}
                                        pagination={(page) => {
                                            setPollTableSearchText(
                                                pollTableSearchText
                                            );
                                            setPollTableCurrentPage(page);
                                        }}
                                        search={(searchText) => {
                                            setPollTableCurrentPage(1);
                                            setPollTableSearchText(searchText);
                                        }}
                                        sendNotification={(type, item) => {
                                            setIsScheduleModalVisible(true);
                                            setNotificationSendNow(
                                                type === 'sendNow'
                                            );
                                            setSelectedNotificationData(item);
                                        }}
                                        onClickDuplicatePoll={
                                            handlePreDuplicate
                                        }
                                        onClickDeletePoll={handlePreDelete}
                                        setSorter={setSorter}
                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    )}
                </div>
            </div>
            {deviceType !== 'mobile' && (
                <ScheduleModal
                    {...{
                        scheduleDate,
                        setScheduleDate,
                        sendMode: notificationSendNow ? 'sendNow' : 'schedule',
                    }}
                    notificationSendNow={notificationSendNow}
                    visible={isScheduleModalVisible}
                    setVisible={setIsScheduleModalVisible}
                    notificatonData={selectedNotificationData}
                    onRefreshData={() => handleGetPolls()}
                    notificationEditMode
                    sendDirect
                />
            )}
            {deviceType === 'mobile' && (
                <ScheduleModalMobile
                    {...{
                        scheduleDate,
                        setScheduleDate,
                        sendMode: notificationSendNow ? 'sendNow' : 'schedule',
                    }}
                    notificationSendNow={notificationSendNow}
                    visible={isScheduleModalVisible}
                    setVisible={setIsScheduleModalVisible}
                    notificatonData={selectedNotificationData}
                    onRefreshData={() => handleGetPolls()}
                    notificationEditMode
                    sendDirect
                    onCancel={() => setIsScheduleModalVisible(false)}
                />
            )}
            <SidebarContainer>
                <MainMenu priActive="pushengagement" all />
            </SidebarContainer>
            <style>{`
            .sponsors-company-table .ant-table-container table > thead > tr:first-child th:first-child
            {
                padding-left: 0px;
            }
            .sponsors-company-table .ant-table-measure-row{
                display: none;                
            }
            `}</style>
        </Layout>
    );
}

NotificationPolls.propTypes = {};

export default NotificationPolls;
