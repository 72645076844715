import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, useParams } from 'react-router-dom';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import { getLeadsCaptured, exportLeadsCaptured } from 'apis/rest/LeadsUsers';
import { Table, Button } from 'antd';
import './index.scss';
import { capitalize, isEmpty } from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';

function Report() {
    const { projectId } = useParams();
    const [leadsData, setLeadsData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const limit = 10;

    const getLeads = useCallback(
        async (page = 1) => {
            try {
                setLoading(true);
                const { status, leads, pagination } = await getLeadsCaptured({
                    projectId,
                    page,
                    limit,
                });
                if (status && leads?.length > 0) {
                    const columns = Object.keys(leads[0]).map((ele) => ({
                        title: (
                            <span className="tableHead">{capitalize(ele)}</span>
                        ),
                        dataIndex: ele,
                        key: ele,
                    }));
                    const dataSrc = leads.map((ele, index) => ({
                        ...ele,
                        key: index,
                    }));
                    setLeadsData({ columns, dataSrc, pagination });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        [projectId]
    );
    const exportLeadsData = async () => {
        try {
            setIsExporting(true);
            const response = await exportLeadsCaptured({ projectId });
            FileSaver.saveAs(response, `Captured-Leads-${moment().unix()}.csv`);
        } catch (error) {
            console.error(error);
        } finally {
            setIsExporting(false);
        }
    };

    useEffect(() => {
        getLeads();
    }, []);

    return (
        <Layout background="#F7F9FA" className="tw-p-0">
            <SidebarContainer>
                <MainMenu priActive="sponsorHome" all />
            </SidebarContainer>
            <div className="reportContainer tw-m-0 md:tw-m-5">
                <div className="rprtheader tw-hidden md:tw-flex">
                    <h1 className="mb-3">Leads Captured</h1>

                    <Button
                        onClick={exportLeadsData}
                        loading={isExporting}
                        className="exportBtn"
                    >
                        Export Leads
                    </Button>
                </div>
                {/* Mobile View */}
                {!isEmpty(leadsData) ? (
                    <div className="tw-flex tw-flex-col tw-w-full tw-mb-3 tw-items-start md:tw-hidden tw-py-3 tw-px-5">
                        <div className="tw-w-full tw-flex tw-justify-start">
                            <button
                                className={`tw-w-full tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-base tw-font-semibold tw-text-center tw-cursor-pointer ${
                                    isExporting ? 'tw-opacity-70' : ''
                                }`}
                                onClick={exportLeadsData}
                                disabled={isExporting}
                            >
                                {isExporting
                                    ? 'Exporting leads...'
                                    : 'Export Leads'}
                            </button>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <div
                    className={`tw-bg-white ${
                        !isEmpty(leadsData) ? '' : 'tw-mt-8'
                    } tw-h-[95vh] sm:tw-h-auto md:tw-p-0`}
                >
                    <Table
                        loading={isLoading}
                        dataSource={leadsData?.dataSrc || []}
                        columns={leadsData?.columns || []}
                        pagination={{
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            total: leadsData?.pagination?.total,
                            pageSize: limit,
                            current: Number(
                                leadsData?.pagination?.currentPage || 1
                            ),
                            onChange: (current) => {
                                getLeads(current);
                            },
                        }}
                        className="sm:tw-shadow-md tw-shadow-none tw-rounded-md"
                        scroll={{ x: 767 }}
                    />
                </div>
            </div>
        </Layout>
    );
}

export default withRouter(Report);
