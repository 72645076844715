import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { isEmpty } from 'lodash';

import * as ls from 'local-storage';

import { changeProject } from 'redux/actions';

import './headerMain.scss';

import { Menu, Dropdown, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { updateProfile } from 'apis/rest/UpdateProfile';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { getUser } from 'apis/rest/User';
import { setUser, updateUser } from 'redux/actions/common/userActions';
import BackIcon from '../../../../../../ComponentsV2/Icons/BackIcon';

function HeaderMain(props) {
    const {
        changeProject,
        project,
        isProjListing,
        isCompListing,
        menus,
        setActiveMenu,
        activeMenu,
        projectOnly,
        header,
        resetVenueDetails,
        handleLogout,
    } = props;

    const dispatch = useDispatch();
    const { projectId, companyId } = useParams();
    const { pathname } = useLocation();
    const history = useHistory();
    const companyPermissionChecked = useRef(false);
    const isInProjectRoute = projectOnly || projectId;
    const isHomeDashboard = pathname?.endsWith('sponsor-home');
    const user = useSelector((state) => state.user);
    const company = useSelector((state) => state.projects.company);
    const companies = user?.companies;
    const projectList = useSelector((state) => state.projects.list);
    const isSponsorSingleCompany = useSelector(
        (state) => state.sponsorMenu.singleCompany
    );

    const { projects } = user;
    const currentProjectData =
        projectList && projectList.length
            ? projectList.filter((p) => p.id === projectId)[0] ||
              ls.get('activeProjData')
            : ls.get('activeProjData');

    // Redux - state
    const headerTitle = useSelector((state) => state.header.headerTitle);
    const projectIdData =
        useSelector((state) => state.header.projectId) || projectId;
    const { activeMenu: activeSponsorMenu } = useSelector(
        (state) => state?.sponsorMenu
    );

    // Location and History
    const location = useLocation();
    const path = location.pathname;

    // Component states

    const [searchVal, setSearchVal] = useState('');
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [showDefaultLogoPaths] = useState([
        '/',
        `/company/${companyId}/project/create`,
    ]);

    const handleWindowScroll = () => {
        try {
            setIsMenuVisible(false);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUpdatedUserData = async () => {
        try {
            const response = await getUser();
            dispatch(setUser(response));
            const updatedCompanyList = response.user.companies;
            const companyToRequest = updatedCompanyList.find(
                (c) => c.id === companyId
            );
            if (companyToRequest) {
                handleCompanyChange(companyToRequest);
            } else {
                handleCompanyChange(updatedCompanyList[0]);
                openNotification('error', {
                    message: 'You do not have access of this organization.',
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const handleSponsor = () => {
            if (!ls.get('sponsorAuth')) {
                history.replace('/login');
            }
        };
        if (ls.get('sponsorAuth'))
            window.addEventListener('storage', handleSponsor);

        if (window) {
            window.addEventListener('scroll', handleWindowScroll);
        }

        return () => {
            window.removeEventListener('storage', handleSponsor);
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, []);

    useEffect(() => {
        if (
            !isEmpty(companies) &&
            !isEmpty(user?.company) &&
            companyId &&
            user.company.id !== companyId &&
            !companyPermissionChecked.current
        ) {
            companyPermissionChecked.current = true;
            const companyToRequest = companies.find((c) => c.id === companyId);
            if (companyToRequest) handleCompanyChange(companyToRequest);
            else {
                fetchUpdatedUserData();
            }
        }
    }, [companyId, user, companies]);

    // Returns boolean if paths match, this is for showing or hiding default logo/title
    const pathStatus = () => showDefaultLogoPaths.includes(path);
    // Handler for Project Name click
    const handleProjectNameClick = () => {
        if (isProjListing) history.push(`/company/${companyId}/project/`);
        else history.push(`/company/${companyId}/project/${projectIdData}`);
    };

    /**
     * handles switching of project
     * @param {object} e event object
     */
    const handleProjectChange = (e) => {
        const projectId = e.target.value;

        const selectedProject = projects.find((project) => {
            return project.id === projectId;
        });

        ls('project', selectedProject);

        changeProject(selectedProject);
    };

    const handleCompanyChange = (comp) => {
        if (comp?.id !== company?.id) {
            setIsMenuVisible(false);
            dispatch(
                updateUser({
                    user: {
                        ...user,
                        company: {
                            id: comp.id,
                            name: comp.name,
                        },
                    },
                })
            );
        }
        if (comp?.id !== companyId) {
            history.push(
                `/company/${comp?.UserCompany?.companyId}/project`,
                comp
            );
        }
    };

    const handleSwitchDashboard = async (e) => {
        try {
            e.preventDefault();

            const res = await updateProfile({ dashboardVersion: 'v2' });
            if (res && res.status) {
                // ls('user', { ...user, dashboardVersion: 'v2' });
                dispatch(
                    updateUser({ user: { ...user, dashboardVersion: 'v2' } })
                );
            }
            history.push(
                `/company/${companyId}/project/${projectId}/dashboard/home/overview${
                    user?.isFirstProject ? '?firstProject=1' : ''
                }`
            );
        } catch (error) {
            console.error(error);
        }
    };

    /* TEMPORARY */
    const isSwitch2020 = () => user.roleId === 4;
    const isSponsor = () => !!ls.get('sponsorIdentifier');

    const URL = isSponsor()
        ? !isHomeDashboard
            ? `/company/${companyId}/project/${projectId}/sponsor-home`
            : '/company-profiles'
        : isSwitch2020()
        ? `/sponsor`
        : isProjListing || isCompListing
        ? '/'
        : `/company/${companyId}/project/`;
    const headerTitleFinal =
        activeSponsorMenu?.link &&
        activeSponsorMenu?.link === 'sponsor-home' ? (
            headerTitle
        ) : (
            <span className="tw-text-lg">{activeSponsorMenu?.name}</span>
        );

    const menu = () => {
        try {
            let companyListCopy = companies;
            const style = (
                <style jsx="true">{`
                    .company-list {
                        display: flex;
                        flex-direction: column;
                        padding: 20px;
                        background: #ffffff;
                        border: 1px solid #dbe4eb;
                        box-sizing: border-box;
                        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
                        border-radius: 4px;
                        min-width: 316px;
                        max-width: 316px;
                    }
                    .company-list h4 {
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: -0.03em;
                        color: #000000;
                        border: none;
                        margin: 20px 0;
                        text-transform: none;
                    }

                    .company-list-wrap {
                        border: none;
                        max-height: 200px;
                        overflow: auto;
                    }

                    .company-list .ant-input-affix-wrapper {
                        background: #ffffff;
                        border: 1px solid #dadeeb;
                        box-sizing: border-box;
                        border-radius: 4px;
                    }

                    .company-list input {
                        margin: 0;
                        font-family: Open Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 143.87%;
                        display: flex;
                        align-items: center;
                        letter-spacing: -0.03em;
                        color: #495057;
                    }

                    .company-list-item {
                        font-family: Open Sans;
                        font-style: normal;
                        font-weight: normal !important;
                        font-size: 14px;
                        line-height: 19px !important;
                        letter-spacing: -0.03em;
                        color: #000000;
                        margin-bottom: 20px !important;
                        padding: 0 !important;
                        height: auto !important;
                    }
                    .company-list-item:last-child {
                        margin-bottom: 0 !important;
                    }
                    .company-list-item.active {
                        font-weight: 600;
                        color: #1890ff;
                    }
                `}</style>
            );

            if (searchVal) {
                companyListCopy = companies.filter((comp) =>
                    comp?.name?.toLowerCase().includes(searchVal.toLowerCase())
                );
            }

            if (companyListCopy.length === 0 && searchVal)
                return (
                    <div className="company-list">
                        <div className="header-company-list">
                            {companies.length > 0 && (
                                <Input
                                    placeholder="Search Organization"
                                    onChange={(e) =>
                                        setSearchVal(e.target.value)
                                    }
                                    prefix={<SearchOutlined />}
                                />
                            )}
                            <h4>Switch Organization</h4>
                        </div>
                        <Menu className="company-list-wrap">
                            <Menu.Item title="No result">No result</Menu.Item>
                        </Menu>
                        {style}
                    </div>
                );

            if (companyListCopy.length === 0) return null;

            return (
                <div className="company-list">
                    <div className="header-company-list">
                        {companies.length > 0 && (
                            <Input
                                placeholder="Search Organization"
                                onChange={(e) => setSearchVal(e.target.value)}
                                prefix={<SearchOutlined />}
                            />
                        )}
                        <h4>Switch Organization</h4>
                    </div>
                    <Menu className="company-list-wrap">
                        {companyListCopy.map((comp) => (
                            <Menu.Item
                                key={comp?.id}
                                title={comp?.name}
                                className={`company-list-item ${
                                    comp?.id === company?.id ? 'active' : ''
                                }`}
                                onClick={() => handleCompanyChange(comp)}
                            >
                                {comp?.name}
                            </Menu.Item>
                        ))}
                    </Menu>
                    {style}
                </div>
            );
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="header-main">
            {!isSponsorSingleCompany && (
                <div className="header-main__title-group">
                    {/* Temporary link */}
                    <Link onClick={resetVenueDetails} to={URL}>
                        {isSwitch2020() ? (
                            <img
                                className="sponsor"
                                src="/assets/products/xpmanager/icons/topNav/logo-myxp-sponsor.svg"
                                alt="Gevme Sponsor"
                            />
                        ) : isInProjectRoute ? (
                            <>
                                {' '}
                                {!isHomeDashboard ? (
                                    <img
                                        src="/assets/icon_arrow_left.svg"
                                        alt="Arrow back"
                                        style={{ transform: 'rotate(180deg)' }}
                                    />
                                ) : (
                                    <BackIcon />
                                )}{' '}
                            </>
                        ) : (
                            <img
                                className="solo"
                                src="assets/GEVME_LOGO.svg"
                                alt="Gevme Admin"
                            />
                        )}
                    </Link>
                </div>
            )}
            {isSponsor() ? (
                <div id="sponsor-path" className="header-main__sponsorTitle">
                    {isProjListing
                        ? user?.company?.name
                        : pathStatus()
                        ? ''
                        : headerTitleFinal}
                </div>
            ) : isInProjectRoute ? (
                <>
                    <h1 className="project-title-h1">
                        {currentProjectData?.title || currentProjectData?.name}
                    </h1>
                    <button
                        className="btn btn-link switch-dashboard-cta"
                        onClick={handleSwitchDashboard}
                    >
                        <img
                            src="/assets/dashboardv2/sparkle-icon.svg"
                            alt="Switch to the new dashboard experience"
                        />
                        Switch to the{' '}
                        <span className="span-link">
                            &nbsp;new dashboard experience
                        </span>
                    </button>
                </>
            ) : (
                <>
                    {header ? (
                        <h1 className="active-company ant-dropdown-link m-0">
                            {header}
                        </h1>
                    ) : (
                        <>
                            <Dropdown
                                overlay={menu}
                                trigger={['click']}
                                visible={isMenuVisible}
                                onVisibleChange={(e) => setIsMenuVisible(e)}
                                icon
                            >
                                <h1
                                    className="active-company ant-dropdown-link m-0"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {company?.name}{' '}
                                    <i
                                        style={{
                                            fontSize: '14px',
                                            marginLeft: '5px',
                                        }}
                                        className="fa fa-chevron-down"
                                    />
                                </h1>
                            </Dropdown>

                            {menus && menus.length !== 0 && (
                                <div className="header-menu-wrap">
                                    <ul className="header-menu-list">
                                        {menus.map(
                                            (menuItem) =>
                                                !menuItem.disabled && (
                                                    <li
                                                        className={`header-menu-item ${
                                                            activeMenu &&
                                                            activeMenu ===
                                                                menuItem.name
                                                                ? 'active'
                                                                : ''
                                                        } ${
                                                            menuItem.disabled
                                                                ? 'disabled'
                                                                : ''
                                                        }`}
                                                        onClick={(e) => {
                                                            if (
                                                                !menuItem.disabled &&
                                                                setActiveMenu
                                                            )
                                                                setActiveMenu(
                                                                    menuItem.name
                                                                );
                                                        }}
                                                    >
                                                        {menuItem?.label}
                                                    </li>
                                                )
                                        )}
                                    </ul>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        project: state.dashboardManager.project,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeProject: (selectedProject) =>
            dispatch(changeProject(selectedProject)),
        resetVenueDetails: () => {
            dispatch({ type: 'RESET_VENUE_DETAILS' });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMain);
