import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Tabs, Button, DatePicker } from 'antd';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';

import {
    getAllMediaAssets,
    getAllMediaAssetsUsers,
    uploadMediaAsset,
    createMediaAsset,
    deleteManyMediaAsset,
} from 'apis/rest/media-assets/mediaAssets';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import GlobalLoading from 'components/app/components/common/globalLoading';
import Prompt from 'components/app/common/prompt';
import Dropdown from 'components/app/common/dropdown';
import MediaAssetCard from 'components/app/common/mediaAssetCard';
import LockedOverlay from 'components/app/common/lockedOverlay';
import CommonBanner from 'components/app/common/commonBanner';
import MeetRecordingListing from '../Video/MeetRecordingListing';
import { NoMediaAssets } from './components';
import {
    AddIcon,
    DeleteIcon,
    DownChevronIcon,
    SearchIcon,
    SelectAllIcon,
    SortIcon,
    NoDataIcon,
    UploadErrorIcon,
    MediaAssetsViewIcon,
} from './icons';
import './index.scss';
import CrossIcon from './icons/CrossIcon';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

export const ALLOWED_FILE_TYPES = {
    audio: ['m4a', 'flac', 'mp3', 'wav'],
    image: ['png', 'jpeg', 'svg', 'gif', 'jpg'],
    video: ['mp4'],
};
const MAX_ALLOWED_FILE_SIZES_IN_MB = {
    startUpUser: {
        audio: 50,
        video: 500,
        image: 5,
    },
    others: {
        audio: 50,
        video: 1024,
        image: 5,
    },
};
const UPLOAD_FILE_TYPES = {
    // audio types
    mp3: 'audio/mpeg',
    m4a: 'audio/mp4',
    flac: 'audio/x-flac',
    wav: 'audio/wav',
    // video types
    mp4: 'video/mp4',
    // avi: 'video/x-msvideo',
    // mov: 'video/quicktime',
    // wmv: 'video/x-ms-wmv',
    // mkv: 'video/x-matroska',
    // image types
    png: 'image/png',
    jpeg: 'image/jpeg',
    svg: 'image/svg+xml',
    gif: 'image/gif',
    jpg: 'image/jpeg',
};

function convertFileSizeFromMBToMBorGB(fileSize) {
    if (fileSize < 1024) {
        return `${fileSize} MB`;
    }
    return `${fileSize / 1024} GB`;
}

function MediaAssets({
    multiple = true,
    handleSelectFile,
    isTypeModal = false,
    modalType = 'audio',
    modalSelectedAssetId = null,
    menu = {},
    isInStarterPlan = false,
}) {
    const { projectId } = useParams();

    // Disabling for now
    // const tabsMenu = {};
    // menu?.tabs && menu.tabs.forEach((tab) => tabsMenu[tab?.name] = ({ ...tab }));

    const [mediaAssetsData, setMediaAssetsData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState(
        isTypeModal ? modalType || 'video' : 'video'
    );
    const [hasMediaAssets, setHasMediaAssets] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isMediaAssetCreating, setIsMediaAssetCreating] = useState(false);
    const playingMediaPlayerRef = useRef(null);
    const mediaAssetDetailsRef = useRef(null);

    // scroll pagination
    const mediaAssetsRef = useRef(null);
    const currentPageRef = useRef(1);

    // upload input ref
    const uploadInputRef = useRef(null);
    const [uploadError, setUploadError] = useState(null);

    // search state
    const [searchValue, setSearchValue] = useState('');
    const debouncedSetSearch = useCallback(debounce(setSearchValue, 500), []);

    // select all option
    const [selectedMediaAssets, setSelectedMediaAssets] = useState(
        modalSelectedAssetId ? [modalSelectedAssetId] : []
    );
    const [allMediaAssets, setAllMediaAssets] = useState([]);
    // delete many media assets option
    const [deletePrompt, setDeletePrompt] = useState(null);

    // added by options
    const [isAddedByLoading, setIsAddedByLoading] = useState(false);
    const [showAddedBy, setShowAddedBy] = useState(false);
    const [addedBy, setAddedBy] = useState('');
    const [addedByOptions, setAddedByOptions] = useState([]);
    const [selectedAddedBy, setSelectedAddedBy] = useState([]);

    // date range options
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // sort by options
    const [showSortBy, setShowSortBy] = useState(false);
    const [sortBy, setSortBy] = useState('createdAt-desc');
    const sortByOptions = [
        {
            label: 'Date added (newest first)',
            value: 'createdAt-desc',
        },
        {
            label: 'Date added (oldest first)',
            value: 'createdAt-asc',
        },
        {
            label: 'File Name (A-Z)',
            value: 'name-asc',
        },
        {
            label: 'File Name (Z-A)',
            value: 'name-desc',
        },
        {
            label: 'File Size (smallest first)',
            value: 'size-asc',
        },
        {
            label: 'File Size (largest first)',
            value: 'size-desc',
        },
    ];

    function hideAllMenus() {
        if (showAddedBy) setShowAddedBy(false);
        if (showSortBy) setShowSortBy(false);
    }

    function resetAllOptions() {
        setIsFirstLoad(true);
        setHasMediaAssets(false);
        currentPageRef.current = 1;
        mediaAssetsRef.current = null;
        setMediaAssetsData(null);
        setUploadError(null);
        setSearchValue('');
        setSelectedMediaAssets(
            modalSelectedAssetId ? [modalSelectedAssetId] : []
        );
        setAddedBy('');
        setSelectedAddedBy([]);
        setStartDate(null);
        setEndDate(null);
        setSortBy('createdAt-desc');
    }

    function onTabChangeHandler(tab) {
        if (tab === currentTab) return;
        if (isMediaAssetCreating) {
            return openNotification(
                'error',
                {
                    message:
                        'Cannot change the tab while uploading. Please open the page in a new browser tab.',
                },
                4
            );
        }
        resetAllOptions();
        setCurrentTab(tab);
    }

    async function fetchAllMediaAssets({ page = 1 } = {}) {
        try {
            if (isLoading) return;
            setIsLoading(true);
            const [sortByValue, sortOrderValue] = sortBy.split('-');
            const userIds = addedByOptions.reduce((acc, curr) => {
                if (selectedAddedBy.includes(curr.value)) {
                    acc.push(curr.id);
                }
                return acc;
            }, []);
            const data = await getAllMediaAssets({
                projectId,
                type: currentTab,
                search: searchValue,
                userIds,
                startDate: startDate || '',
                endDate: endDate || '',
                sortBy: sortByValue,
                sortOrder: sortOrderValue,
                page,
                limit: `1${(Math.abs(page) % 10).toString()}`
            });
            setMediaAssetsData(data);
            setAllMediaAssets(
                page > 1
                    ? (prevAllMediaAssets) =>
                          prevAllMediaAssets.concat(data.assets)
                    : data.assets
            );
            if (multiple && page === 1) {
                setSelectedMediaAssets([]);
            }
            currentPageRef.current = page;
            if (isFirstLoad) {
                setHasMediaAssets(data.assets.length > 0);
                setIsFirstLoad(false);
            }
            setIsLoading(false);
        } catch (e) {
            console.error(e);
            openNotification('error', {
                message: e?.message || 'Something went wrong',
            });
            setIsLoading(false);
        }
    }

    async function fetchAllMediaAssetsUsers() {
        try {
            if (isAddedByLoading) return;
            setIsAddedByLoading(true);
            const { dropDownList } = await getAllMediaAssetsUsers({
                projectId,
                dropDownSearch: addedBy,
                type: currentTab,
                globalSearch: searchValue,
                startDate: startDate || '',
                endDate: endDate || '',
            });
            setAddedByOptions(
                dropDownList.map((item) => ({
                    label: `${item.firstname} ${item.lastname}`,
                    value: `${item.firstname} ${item.lastname}`,
                    id: item.id,
                }))
            );
            if (!isFirstLoad) {
                await fetchAllMediaAssets();
            }
            setIsAddedByLoading(false);
        } catch (e) {
            setIsLoading(false);
            openNotification('error', {
                message: e?.message || 'Something went wrong',
            });
            console.error(e);
        }
    }

    function getMaxAllowedSize() {
        return (
            isInStarterPlan
                ? MAX_ALLOWED_FILE_SIZES_IN_MB.startUpUser
                : MAX_ALLOWED_FILE_SIZES_IN_MB.others
        )[currentTab];
    }

    function validateMediaAsset(file) {
        if (!ALLOWED_FILE_TYPES[currentTab] || !getMaxAllowedSize()) {
            throw new Error(
                'Error while validating media asset, setting not found'
            );
        }
        // check file type
        const fileType = file.name
            .slice(file.name.lastIndexOf('.') + 1)
            .toLowerCase();
        if (!ALLOWED_FILE_TYPES[currentTab].includes(fileType)) {
            throw new Error(
                `Only <span style="color: #D93024;">${ALLOWED_FILE_TYPES[
                    currentTab
                ]
                    .join(', ')
                    .toUpperCase()}</span> files are allowed`
            );
        }
        // check file size
        const maxFileSizeInBytes = getMaxAllowedSize() * 1024 * 1024;
        if (file.size > maxFileSizeInBytes) {
            throw new Error(
                `Maximum ${currentTab} file size <span style="color: #D93024;">${getMaxAllowedSize()} MB</span> is allowed`
            );
        }
        return true;
    }

    async function createMediaAssetHandler() {
        try {
            if (isMediaAssetCreating) return;
    
            const files = uploadInputRef.current.files;
            if (files.length === 0) return;
    
            const validFiles = Array.from(files).filter((file) => validateMediaAsset(file));
    
            if (validFiles.length === 0) return;
    
            setIsMediaAssetCreating(true);
    
            const filetypes = validFiles.map((file) =>
                UPLOAD_FILE_TYPES[file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase()]
            );
    
            const uploadResponse = await uploadMediaAsset({
                projectId,
                files: validFiles,
                type: currentTab,
                filetypes
            });
            
            const filesData = uploadResponse?.map((file) => ({
                name: file.filename,
                token: file.token,
            }));
    
            const res = await createMediaAsset({
                projectId,
                files: filesData,
                type: currentTab,
            });
    
            if (!res.status) throw res;
    
            fetchAllMediaAssetsUsers();
            setIsMediaAssetCreating(false);
            if (!hasMediaAssets) {
                setHasMediaAssets(true);
            }
        } catch (e) {
            setIsMediaAssetCreating(false);
            setUploadError(e.message);
            console.error(e);
        }
    }
    
    

    async function deleteMediaAssetsHandler(mediaAssetIds) {
        try {
            const response = await deleteManyMediaAsset({
                projectId,
                mediaAssetIds,
            });
            if (!response.status) return;
            setSelectedMediaAssets((prevSelectedMediaAssets) => {
                return prevSelectedMediaAssets.filter(
                    (id) => !mediaAssetIds.includes(id)
                );
            });
            fetchAllMediaAssets();
        } catch (e) {
            console.error(e);
        }
    }

    async function deleteMediaAssetHandler(mediaAssetId) {
        return deleteMediaAssetsHandler([mediaAssetId]);
    }

    function onUploadClickHandler() {
        if (!uploadInputRef.current) return;
        uploadInputRef.current.click();
    }

    function loadMoreHandler() {
        if (!mediaAssetsData) return;
        if (
            currentPageRef.current >= mediaAssetsData.pagination.totalPage ||
            isLoading
        ) {
            return;
        }
        currentPageRef.current += 1;
        fetchAllMediaAssets({ page: currentPageRef.current });
    }

    function stopOtherMediaPlayerHandler(
        currentMediaPlayerAssetId,
        currentMediaPlayerStopHandler
    ) {
        if (currentMediaPlayerAssetId) {
            if (
                !playingMediaPlayerRef?.current?.mediaPlayerMediaAssetId ===
                currentMediaPlayerAssetId
            )
                return;
            playingMediaPlayerRef.current?.stopHandler &&
                playingMediaPlayerRef.current.stopHandler();
            playingMediaPlayerRef.current = {
                mediaPlayerMediaAssetId: currentMediaPlayerAssetId,
                stopHandler: currentMediaPlayerStopHandler,
            };
        } else {
            playingMediaPlayerRef.current?.stopHandler &&
                playingMediaPlayerRef.current.stopHandler();
            playingMediaPlayerRef.current = null;
        }
    }

    function closeOtherMediaAssetDetailsHandler(
        currentMediaAssetId,
        currentMediaAssetCloseHandler
    ) {
        if (currentMediaAssetId) {
            if (
                !mediaAssetDetailsRef?.current?.mediaAssetId ===
                currentMediaAssetId
            )
                return;
            mediaAssetDetailsRef.current?.closeHandler &&
                mediaAssetDetailsRef.current.closeHandler();
            mediaAssetDetailsRef.current = {
                mediaAssetId: currentMediaAssetId,
                closeHandler: currentMediaAssetCloseHandler,
            };
        } else {
            mediaAssetDetailsRef.current?.closeHandler &&
                mediaAssetDetailsRef.current.closeHandler();
            mediaAssetDetailsRef.current = null;
        }
    }

    useEffect(() => {
        window.addEventListener('click', hideAllMenus);
        return () => {
            window.removeEventListener('click', hideAllMenus);
        };
    }, [showAddedBy, showSortBy]);

    useEffect(() => {
        if (currentTab === 'recorded-meetings') {
            return;
        }
        fetchAllMediaAssetsUsers();
    }, [currentTab, addedBy]);

    useEffect(() => {
        hideAllMenus();
        if (currentTab === 'recorded-meetings') {
            return;
        }
        fetchAllMediaAssets();
    }, [currentTab, searchValue, selectedAddedBy, startDate, endDate, sortBy]);

    useEffect(() => {
        if (modalType === 'image' && multiple) {
            handleSelectFile(
                allMediaAssets.filter((mediaAsset) =>
                    selectedMediaAssets.includes(mediaAsset.id)
                )
            );
        }
    }, [selectedMediaAssets, allMediaAssets, multiple]);

    const totalMediaAssets = mediaAssetsData?.pagination?.total || 0;

    const MediaAssetsOptions = () => {
        const selectedMediaAssetsId = selectedMediaAssets.join('_');
        const onMediaAssetDelete = () =>
            deleteMediaAssetsHandler(selectedMediaAssets);
        const handleClosePrompt = () => setDeletePrompt(null);
        const handleDeletePrompt = () => setDeletePrompt(selectedMediaAssetsId);
        const isMoreThanOneFileSelected = selectedMediaAssets.length > 1;

        return (
            <>
                <Prompt
                    show={deletePrompt === selectedMediaAssetsId}
                    message={`Are you sure you want to delete ${
                        selectedMediaAssets.length === allMediaAssets.length
                            ? 'All'
                            : isMoreThanOneFileSelected
                            ? 'these'
                            : 'this'
                    } ${currentTab} ${
                        isMoreThanOneFileSelected ? 'files' : 'file'
                    }?`}
                    buttons={['Cancel', 'Delete']}
                    onHide={() => handleClosePrompt()}
                    callback={() => onMediaAssetDelete()}
                />
                <div className="tw-w-full tw-px-4 tw-pt-4 tw-flex tw-flex-row tw-h-13">
                    <div
                        className={`${
                            selectedMediaAssets.length
                                ? 'tw-w-2/5'
                                : 'tw-w-6/12'
                        } tw-h-full`}
                    >
                        <label
                            htmlFor="default-search"
                            className="tw-mb-2 tw-text-sm tw-font-medium tw-text-zinc-400 tw-sr-only"
                        >
                            Search
                        </label>
                        <div className="tw-relative">
                            <div className="tw-flex tw-absolute tw-inset-y-0 tw-left-0 tw-items-center tw-pl-3 tw-pointer-events-none">
                                <SearchIcon />
                            </div>
                            <input
                                type="search"
                                id="default-search"
                                className="tw-block tw-font-normal tw-p-3 tw-pl-10 tw-w-full tw-h-11 tw-text-xs tw-text-black placeholder:tw-text-zinc-400 tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 focus:tw-ring-blue-100 focus:tw-border-2 focus:tw-border-blue-100 "
                                placeholder="Search"
                                defaultValue={searchValue}
                                onChange={(e) =>
                                    debouncedSetSearch(e.target.value)
                                }
                            />
                        </div>
                    </div>

                    {selectedMediaAssets.length > 0 && (
                        <button
                            type="button"
                            className="tw-ml-2 tw-w-auto tw-h-11 flex flex-row tw-text-gray-900 tw-bg-white tw-border tw-border-solid tw-border-gray-300 focus:tw-outline-none hover:tw-bg-gray-100 focus:tw-ring-4 focus:tw-ring-gray-200 tw-font-medium tw-rounded tw-text-xs tw-tracking-tight tw-px-2.5 tw-py-2 tw-mb-0"
                            onClick={() => handleDeletePrompt()}
                        >
                            <DeleteIcon /> Delete
                        </button>
                    )}

                    {multiple && (
                        <button
                            type="button"
                            className={`${
                                allMediaAssets.length &&
                                selectedMediaAssets.length ===
                                    allMediaAssets.length
                                    ? 'tw-bg-blue-50 hover:tw-bg-blue-100'
                                    : 'tw-bg-white hover:tw-bg-gray-100'
                            } tw-ml-2 tw-w-28 tw-h-11 flex flex-row tw-text-gray-900 tw-border tw-border-solid tw-border-gray-300 focus:tw-outline-none focus:tw-ring-4 focus:tw-ring-gray-200 tw-font-medium tw-rounded tw-text-xs tw-tracking-tight tw-px-2.5 tw-py-2 tw-mb-0`}
                            onClick={() => {
                                if (
                                    selectedMediaAssets.length ===
                                    allMediaAssets.length
                                ) {
                                    setSelectedMediaAssets([]);
                                    return;
                                }
                                setSelectedMediaAssets(
                                    allMediaAssets.map(
                                        (mediaAsset) => mediaAsset.id
                                    )
                                );
                            }}
                        >
                            <SelectAllIcon
                                stroke={
                                    allMediaAssets.length &&
                                    selectedMediaAssets.length ===
                                        allMediaAssets.length
                                        ? '#377BF7'
                                        : '#000'
                                }
                            />{' '}
                            &nbsp;
                            {allMediaAssets.length &&
                            selectedMediaAssets.length === allMediaAssets.length
                                ? 'Deselect'
                                : 'Select'}{' '}
                            All
                        </button>
                    )}

                    <Dropdown
                        className={`${
                            isTypeModal ? 'tw-w-64' : 'tw-w-36'
                        } tw-h-11 tw-ml-2`}
                        options={addedByOptions}
                        optionType="checkbox"
                        isOptionsFetching={isAddedByLoading}
                        optionsFetchingText="Searching Users..."
                        icon={<DownChevronIcon />}
                        selectedOptions={selectedAddedBy}
                        setSelectedOptions={setSelectedAddedBy}
                        placeholder="Added by"
                        showDropDown={showAddedBy}
                        setShowDropDown={setShowAddedBy}
                        onSelectBoxClick={() => {
                            setShowSortBy(false);
                        }}
                        hasSearch={true}
                        searchClassName="placeholder:tw-text-black placeholder:tw-font-semibold"
                        search={addedBy}
                        setSearch={setAddedBy}
                        noSearchText="No Users found!"
                    />
                    <RangePicker
                        className={`${
                            startDate && endDate ? 'tw-px-2' : ''
                        } tw-w-1/5 tw-h-11 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-ml-2`}
                        style={{
                            border: '1px solid #d1d5da',
                            borderRadius: '4px',
                            fontsize: '12px',
                        }}
                        defaultValue={[
                            startDate ? moment(startDate) : null,
                            endDate ? moment(endDate) : null,
                        ]}
                        format="DD/MM/YYYY"
                        onChange={(dates) => {
                            if (dates) {
                                let [start, end] = dates;
                                setStartDate(
                                    start ? start.format('YYYY-MM-DD') : ''
                                );
                                setEndDate(end ? end.format('YYYY-MM-DD') : '');
                            } else {
                                setStartDate('');
                                setEndDate('');
                            }
                        }}
                        allowClear={true}
                        placement="bottomRight"
                        suffixIcon={
                            <img
                                className="tw-mr-0"
                                src="/assets/products/xpmanager/iconsv2/calendar.svg"
                                alt=""
                            />
                        }
                        getPopupContainer={(node) => node.parentNode}
                    />
                    <Dropdown
                        className="tw-ml-2 tw-w-auto tw-h-11"
                        optionsTitle="Sort by"
                        options={sortByOptions}
                        optionType="radio"
                        icon={<SortIcon />}
                        showOnlyIcon={true}
                        selectedOption={sortBy}
                        setSelectedOption={setSortBy}
                        showDropDown={showSortBy}
                        setShowDropDown={setShowSortBy}
                        onSelectBoxClick={() => {
                            setShowAddedBy(false);
                        }}
                        hasSearch={false}
                    />
                </div>
            </>
        );
    };

    const UploadFileInput = () => {
        const handleFileChange = (e) => {
            const maxCount = currentTab === 'image' ? 20 : 3;
            const files = Array.from(e.target.files);
            if (files.length > maxCount) {
                openNotification("error", {
                    message: `You can upload a maximum of ${maxCount} files at once.`,
                });
                e.target.value = "";
                return;
            }
            createMediaAssetHandler(e);
        };
    
        return (
            <input
                id="hidden-input"
                ref={uploadInputRef}
                type="file"
                className="tw-w-full tw-h-full tw-absolute tw-hidden"
                accept={`${currentTab}/*`}
                multiple
                onChange={handleFileChange}
            />
        );
    };
    
    

    const LoadMoreButton = () => (
        <div className="tw-w-full tw-flex tw-justify-center tw-items-center tw-pb-4">
            <Button
                className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-white tw-bg-black/100 hover:tw-bg-slate-600/100 hover:tw-border-black hover:tw-border-2 hover:tw-border-solid tw-rounded-md tw-w-36 tw-h-9 tw-text-base tw-font-semibold tw-mb-1"
                onClick={loadMoreHandler}
            >
                Load more
            </Button>
        </div>
    );

    const MediaAssets = ({ mediaName, allowedMediaTypes, maxMediaSize }) => {
        return (
            <div className="tw-bg-white tw-rounded-md">
                {hasMediaAssets && <MediaAssetsOptions />}

                {isLoading &&
                    !hasMediaAssets &&
                    currentPageRef.current === 1 && <GlobalLoading />}

                {!isLoading &&
                    totalMediaAssets === 0 &&
                    !hasMediaAssets &&
                    (isMediaAssetCreating ? (
                        <div className="no-media-upload-file-loader">
                            <GlobalLoading
                                loadingText={`Uploading ${currentTab} file in progress...`}
                            />
                        </div>
                    ) : (
                        <NoMediaAssets
                            mediaName={mediaName}
                            allowedMediaTypes={allowedMediaTypes}
                            maxMediaSize={maxMediaSize}
                        >
                            <div className="tw-mt-6">
                                <UploadFileInput />
                                <Button
                                    className="tw-text-white tw-bg-blue-500/100 hover:tw-bg-blue-400/100 tw-rounded-md tw-w-36 tw-h-9 tw-text-sm tw-font-semibold"
                                    onClick={onUploadClickHandler}
                                >
                                    Upload {mediaName} file
                                </Button>
                            </div>
                        </NoMediaAssets>
                    ))}

                {hasMediaAssets && (
                    <>
                        <div className="tw-w-full tw-h-fit tw-mb-8 tw-mt-2 tw-flex tw-flex-wrap tw-px-8 tw-py-4">
                            <div className="tw-w-60 tw-h-40 tw-mr-5 tw-mb-6">
                                <div className="media-upload-article tw-relative tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-border-dashed tw-border tw-border-solid tw-border-black tw-rounded-lg">
                                    {isMediaAssetCreating ? (
                                        <div className="upload-file-loader">
                                            <GlobalLoading
                                                loadingText={`Uploading ${currentTab} file in progress...`}
                                                width="80px"
                                                height="80px"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <UploadFileInput />
                                            <Button
                                                icon={<AddIcon />}
                                                className="tw-bg-transparent tw-p-0 tw-m-0 tw-border-none"
                                                onClick={onUploadClickHandler}
                                            />
                                            <p className="tw-p-0 tw-m-0 tw-font-semibold tw-text-sm">
                                                Add new {mediaName}
                                            </p>
                                            <div className="info tw-mt-2">
                                                File types
                                                <span className="tw-text-blue-500">
                                                    {allowedMediaTypes.length >
                                                    0
                                                        ? ` - ${allowedMediaTypes
                                                              .join(', ')
                                                              .toUpperCase()}`
                                                        : ''}
                                                </span>
                                            </div>
                                            <div className="info tw-mt-0.5">
                                                Maximum upload file size :{' '}
                                                <span className="tw-text-blue-500">
                                                    {maxMediaSize}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            {hasMediaAssets && totalMediaAssets === 0 && (
                                <div className="ant-empty ant-empty-normal tw-my-0 tw-py-8">
                                    <div className="ant-empty-image">
                                        <NoDataIcon />
                                    </div>
                                    <div className="ant-empty-description">
                                        No Data
                                    </div>
                                </div>
                            )}

                            {allMediaAssets.map((mediaAsset) => (
                                <MediaAssetCard
                                    key={mediaAsset.id}
                                    mediaAsset={mediaAsset}
                                    showOptions={true}
                                    isMediaAssetSelected={selectedMediaAssets.includes(
                                        mediaAsset.id
                                    )}
                                    deleteMediaAssetHandler={
                                        deleteMediaAssetHandler
                                    }
                                    setSelectedMediaAssets={
                                        setSelectedMediaAssets
                                    }
                                    handleSelectFile={handleSelectFile}
                                    stopOtherMediaPlayerHandler={
                                        stopOtherMediaPlayerHandler
                                    }
                                    closeOtherMediaAssetDetailsHandler={
                                        closeOtherMediaAssetDetailsHandler
                                    }
                                    multiple={multiple}
                                    selectedMediaAssets={selectedMediaAssets}
                                    modalType={modalType}
                                />
                            ))}

                            {isLoading && (
                                <GlobalLoading
                                    loadingText={`Loading ${currentTab} files`}
                                />
                            )}
                        </div>
                    </>
                )}

                {!isLoading &&
                    mediaAssetsData &&
                    currentPageRef.current <
                        mediaAssetsData?.pagination?.totalPage && (
                        <LoadMoreButton />
                    )}
            </div>
        );
    };

    const getTabComponent = (tab) => {
        // Disabling this for now
        // if (!tabsMenu[tab]?.enable) {
        //     return (
        //         <div className="tw-w-full tw-bg-white tw-rounded-md tw-pb-20">
        //             <LockedApp menu={tabsMenu[tab]} />
        //         </div>
        //     );
        // }

        switch (tab) {
            case 'video':
                return (
                    <MediaAssets
                        mediaName="video"
                        allowedMediaTypes={ALLOWED_FILE_TYPES.video}
                        maxMediaSize={convertFileSizeFromMBToMBorGB(
                            getMaxAllowedSize()
                        )}
                    />
                );
            case 'image':
                return (
                    <MediaAssets
                        mediaName="image"
                        allowedMediaTypes={ALLOWED_FILE_TYPES.image}
                        maxMediaSize={convertFileSizeFromMBToMBorGB(
                            getMaxAllowedSize()
                        )}
                    />
                );
            case 'audio':
                return (
                    <MediaAssets
                        mediaName="audio"
                        allowedMediaTypes={ALLOWED_FILE_TYPES.audio}
                        maxMediaSize={convertFileSizeFromMBToMBorGB(
                            getMaxAllowedSize()
                        )}
                    />
                );
            case 'recorded-meetings':
                return (
                    <div
                        className={`${LockedOverlay.getParentClass({
                            showBorder: false,
                            showShadow: false,
                            isInStarterPlan,
                        })} tw-w-full tw-flex tw-flex-row`}
                    >
                        <LockedOverlay isInStarterPlan={isInStarterPlan}>
                            <MeetRecordingListing currentTab={currentTab} />
                        </LockedOverlay>
                    </div>
                );
            default:
                return null;
        }
    };

    const tabs = [
        {
            key: 'video',
            tab: 'Video',
            component: getTabComponent('video'),
        },
        {
            key: 'image',
            tab: 'Image',
            component: getTabComponent('image'),
        },
        {
            key: 'audio',
            tab: 'Audio',
            component: getTabComponent('audio'),
        },
        {
            key: 'recorded-meetings',
            tab: 'Recorded Meetings',
            component: getTabComponent('recorded-meetings'),
        },
    ];

    const uploadErrorView = uploadError && (
        <div
            className={`${
                isTypeModal ? 'tw-w-full tw-h-full' : 'tw-w-screen tw-h-screen'
            } tw-absolute tw-top-0 tw-left-0 tw-z-50 tw-bg-backdrop-dark/40 tw-backdrop-opacity-50 tw-flex tw-items-center tw-justify-center`}
        >
            <div className="tw-bg-white tw-rounded-md sm:tw-w-2/5 tw-w-full tw-h-fit tw-p-5 tw-flex tw-flex-col">
                <div className="sm:tw-text-xl tw-flex tw-justify-center tw-items-center sm:tw-block tw-text-base tw-text-[#CE2715] tw-font-semibold tw-font-montserrat upload-error-title tw-mb-2 sm:tw-mb-0">
                    <div className="tw-hidden sm:tw-inline">
                        <UploadErrorIcon /> &nbsp;Upload error
                    </div>
                    <div className="tw-w-full tw-flex tw-justify-center tw-flex-grow sm:tw-hidden">
                        <UploadErrorIcon /> &nbsp;Upload error
                    </div>
                    <div className="tw-inline sm:tw-hidden" onClick={() => setUploadError(null)}>
                        <CrossIcon />
                    </div>
                </div>
                <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between sm:tw-items-end tw-items-center">
                    <p
                        className="sm:tw-w-4/5 tw-w-full tw-text-sm tw-text-[#475467] sm:tw-text-black sm:tw-font-semibold tw-font-normal tw-p-0 tw-m-0 sm:tw-pt-2 sm:tw-pr-2 tw-p-0 tw-text-center sm:tw-text-justify"
                        dangerouslySetInnerHTML={{ __html: uploadError }}
                    />
                    <button
                        className="tw-rounded-md tw-bg-blue-500 tw-mt-4 sm:tw-mt-0 tw-w-full sm:tw-w-fit tw-border-none tw-text-white tw-text-base sm:tw-text-xl tw-font-semibold tw-px-5 tw-py-1"
                        onClick={() => setUploadError(null)}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>
    );

    if (isTypeModal) {
        return (
            <div
                ref={mediaAssetsRef}
                className="media-assets-tabs"
            >
                {uploadErrorView}

                {getTabComponent(currentTab)}
            </div>
        );
    }

    return (
        <div
            ref={mediaAssetsRef}
            className={`media-assets-tabs ${
                isTypeModal ? '' : 'tw-overflow-y-scroll'
            }`}
        >
            <CommonBanner
                isInStarterPlan={isInStarterPlan}
                title="Store and manage all your event content at one place"
                subtitle="One stop for all  the event content. Upload media assets like Pre-recorded sessions, Product Walkthroughs, Banners etc. and use them anywhere"
                subtitleShort="One stop for all  the event content. Upload media assets like Pre-recorded sessions etc."
                showUpgradeButton
                upgradeText="Upgrade to pro for unlimited space"
                icon={<MediaAssetsViewIcon />}
                showMiniMaxIcon
            />

            {uploadErrorView}

            <Tabs
                className="tw-mt-8"
                defaultActiveKey={currentTab}
                activeKey={currentTab}
                onChange={onTabChangeHandler}
            >
                {tabs.map(({ key, tab, component }) => (
                    <TabPane
                        key={key}
                        tab={
                            <div
                                className={`tab-title tw-text-base tw-font-semibold ${
                                    currentTab === key
                                        ? 'tw-text-black'
                                        : 'tw-text-slate-300/100'
                                }`}
                            >
                                {tab}
                            </div>
                        }
                    >
                        {component}
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
}

export default MediaAssets;