import * as ls from 'local-storage';
import { store } from 'redux/store';
import { apiInterceptor } from 'utils/apiInterceptor';

let authBearer = null;

const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            const auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export async function ImportSpeaker(inputs, projectId) {
    setAuthBearer();
    const user = store?.getState()?.user;

    if (user) {
        const formData = new FormData();

        for (const property in inputs) {
            formData.append(property, inputs[property]);
        }
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/import?projectId=${projectId}`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                },
                body: formData,
            }
        );

        return await response.json();
    }
    throw new Error('User is not logged in');
}

export async function downloadSampleSpeakers(projectId) {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/demo-csv?projectId=${projectId}`,
        {
            headers: {
                Authorization: authBearer,
            },
        }
    );

    return await response.text();
}
